<template>
  <modal-error-placing-order title="Não foi possível concluir o pagamento">
    <div slot="content">
      <span v-if="cardPaymentError.error">
        {{
          cardPaymentError.message ||
          "Ocorreu um erro ao processar o pagamento."
        }}
      </span>
      <span v-else>Contate a central do seu cartão.</span>
    </div>
    <div slot="action">
      <button
        v-if="!isUsingSavedCard"
        class="btn btn-primary"
        @click="openAddCardPage"
      >
        Revisar dados do cartão
      </button>
      <button
        v-else-if="isUsingSavedCard && !isPaymentValid"
        class="btn btn-primary"
        @click="openValidateStoredCard"
      >
        Revisar código de segurança
      </button>
      <button
        v-else
        class="btn btn-primary"
        @click="cardPaymentError.error = false"
      >
        Tentar novamente
      </button>
    </div>
  </modal-error-placing-order>
</template>

<script>
import ModalErrorPlacingOrder from "./ModalErrorPlacingOrder";
import CartMixin from "@/app/checkout/cart/CartMixin";

export default {
  name: "ModalPaymentError",
  components: {
    ModalErrorPlacingOrder,
  },
  mixins: [CartMixin],
  computed: {
    isUsingSavedCard() {
      return !!this.paymentData.card.card_id;
    },
  },
  mounted() {
    if (this.isUsingSavedCard) {
      this.$store.dispatch("paymentData", {
        card: { ...this.paymentData.card, ...{ cardCvv: null } },
      });
    }
  },
  methods: {
    openAddCardPage() {
      this.$router.push({
        name: "checkoutAddCard",
        query: { ...this.$route.query },
      });

      this.cardPaymentError.error = false;
    },
    openValidateStoredCard() {
      this.$router.push({
        name: "checkoutValidateStoredCard",
        query: { ...this.$route.query },
      });

      this.cardPaymentError.error = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
