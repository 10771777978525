<template>
  <div
    class="alert-box"
    :class="{
      [type]: true,
      'form-shake-error': type == 'error',
      border: showBorder,
    }"
  >
    <span>{{ message }}</span>
    <slot name="action"></slot>
  </div>
</template>

<script>
export default {
  name: "Alertbox",
  props: {
    type: {
      required: false,
      default: "info",
    },
    message: {
      required: true,
    },
    showBorder: {
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-box {
  display: flex;
  padding: 10px 20px;
  color: #3e3e3e;
  font-weight: 600;
  font-size: 14px;
  flex: 1 0 auto;
  border-radius: 3px;
  margin: 10px 5px;
  background-color: #eeeeee;

  &.border {
    border-left: 5px solid;
  }

  &.error {
    color: #690000;
    border-color: #da0000;
    // background: #ffc9c9;
  }

  &.warn {
    color: #af7010;
    border-color: #ca7f1b;
  }

  &.info {
    color: #004269;
    border-color: #00b7da;
    // background: #c9fff8;
  }

  span {
    flex: 1;
  }
}
</style>
