// import MinhaContaCadastro from "./cadastro/components/Cadastro.vue";
// import ConfirmaCadastro from "./cadastro/components/confirmar-cadastro/ConfirmarCadastro.vue";
// import Login from "./login/components/Login.vue";
// import MinhaConta from "./minha-conta/components/Main.vue";
// import HomeMinhaConta from "./minha-conta/components/minha-conta/HomeMinhaConta.vue";

// import Cadastro from "./minha-conta/components/usuario/CadastroUsuario.vue";
// import Notas from "./minha-conta/components/notas/Notas.vue";
// import RecuperarSenha from "./recuperar-senha/RecuperarSenha.vue";

// import MinhaConta from "./minha-conta-new/MinhaConta";
// import Pedidos from "./minha-conta-new/pedidos/Pedidos";
// import PedidoDetails from "./minha-conta-new/pedidos/pedido-details/PedidoDetails";

// import Pedidos from "./minha-conta/components/pedidos/MainPedidos.vue";
// import PedidoList from "./minha-conta-new/pedidos/PedidoList.vue";
// import CarrinhoSalvoList from "./minha-conta-new/carrinhos-salvos/CarrinhoSalvoList.vue";
// import TituloList from "./minha-conta-new/titulos/TituloList.vue";

// import CarrinhoDetails from "./minha-conta-new/carrinhos-salvos/carrinho-details/CarrinhoDetails.vue";

function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `${view}`).catch(
      (error) => {
        location.reload();
      }
    );
}

export default [
  {
    path: "/minha-conta",
    component: loadView("./minha-conta-new/MinhaConta"),
    redirect: "/minha-conta/overview",
    meta: {
      requiresAuthentication: true,
    },
    children: [
      {
        path: "overview",
        name: "MinhaConta",
        component: loadView(
          "./minha-conta/components/minha-conta/HomeMinhaConta.vue"
        ),
        meta: {
          requiresAuthentication: true,
        },
      },
      {
        name: "MinhaContaPedidos",
        path: "pedidos",
        meta: {
          description: "Pedidos",
          requiresAuthentication: true,
        },
        component: loadView("./minha-conta-new/pedidos/PedidoList.vue"),
        beforeEnter(to, from, next) {
          if (process.env.VUE_APP_STORE_PEDIDOS_URL) {
            location.href = process.env.VUE_APP_STORE_PEDIDOS_URL;
            return;
          }
          next();
        },
      },
      {
        name: "MinhaContaPedidoDetails",
        path: "pedidos/:orderId",
        meta: {
          description: "Detalhes do pedido ",
          requiresAuthentication: true,
        },
        component: loadView(
          "./minha-conta-new/pedidos/pedido-details/PedidoDetails"
        ),
      },
      {
        name: "MinhaContaCarrinhoSalvos",
        path: "carrinhos-salvos",
        meta: {
          description: "Carrinhos salvos",
          requiresAuthentication: true,
        },
        component: loadView(
          "./minha-conta-new/carrinhos-salvos/CarrinhoSalvoList.vue"
        ),
      },
      {
        name: "MinhaContaImportarCarrinho",
        path: "carrinhos-salvos/importar-carrinho",
        meta: {
          description: "Importar Carrinho em CSV",
          requiresAuthentication: true,
        },
        component: loadView(
          "./minha-conta-new/carrinhos-salvos/importar-carrinho/ImportarCarrinho.vue"
        ),
      },
      {
        name: "MinhaContaCarrinhoDetails",
        path: "carrinhos-salvos/:cartId",
        meta: {
          description: "Carrinho salvo",
          requiresAuthentication: true,
        },
        component: loadView(
          "./minha-conta-new/carrinhos-salvos/carrinho-details/CarrinhoDetails.vue"
        ),
      },

      {
        name: "MinhaContaTitulos",
        path: "titulos",
        component: loadView("./minha-conta-new/titulos/TituloList.vue"),
        meta: {
          requiresAuthentication: true,
        },
      },
      {
        name: "MinhaContaCadastro",
        path: "cadastro",
        component: loadView(
          "./minha-conta/components/usuario/CadastroUsuario.vue"
        ),
        meta: {
          requiresAuthentication: true,
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    beforeEnter(to, from) {
      window.location.href = `${process.env.VUE_APP_CLIENTE}?redirect=${window.location.origin}${from.fullPath}`;
    },
  },

  {
    path: "/cadastro",
    name: "cadastro",
    beforeEnter(to, from) {
      window.location.href = `${process.env.VUE_APP_CLIENTE}cadastro?redirect=${window.location.origin}${from.fullPath}`;
    },
  },
  {
    path: "/definir-senha",
    name: "definirSenha",
    beforeEnter(to, from) {
      window.location.href = `${process.env.VUE_APP_CLIENTE}definir-senha${window.location.search}`;
    },
  },
  // {
  //   path: "/confirmar-cadastro",
  //   name: "userConfirmar",
  //   component: ConfirmaCadastro,
  // },
  // {
  //   path: "/alterar-senha",
  //   name: "RecuperarSenha",
  //   components: { default: RecuperarSenha },
  // },
  // {
  //   path: "/minha-conta",
  //   name: "MinhaConta",
  //   components: { default: MinhaConta, rvSecundaria: HomeMinhaConta },
  // },
  // {
  //   path: "/minha-conta/pedidos",
  //   name: "MinhaContaPedidos",
  //   components: { default: MinhaConta, rvSecundaria: Pedidos },
  // },
  // {
  //   path: "/minha-conta/cadastro",
  //   name: "MinhaContaCadastro",
  //   components: { default: MinhaConta, rvSecundaria: Cadastro },
  // },
  // {
  //   path: "/minha-conta/notas",
  //   name: "notasUser",
  //   components: { default: MinhaConta, rvSecundaria: Notas },
  // },
  // {
  //   path: "/minha-conta/titulos",
  //   name: "MinhaContaTitulos",
  //   components: { default: MinhaConta, rvSecundaria: Titulos },
  // },
];
