<template>
  <div id="atendimento">
    <section id="top-section">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="session-header text-uppercase text-center">
              Fale conosco
            </h2>
            <p class="text-center">
              Utilize os campos abaixo para digitar suas dúvidas.
              <br />Você também pode consultar os
              <strong>Assuntos mais procurados</strong> ou entrar em contato
              pela nossa <strong>Central de Relacionamento</strong>.
              <br />
              <strong>Call Center:</strong
              ><a href="tel://0800-285-0005">0800-285-0005</a> -
              <a href="tel://0800-590-3000">0800-590-3000</a> -
              <a href="tel://(33) 3329-0014">(33) 3329-0014</a>
              - <a href="tel://(33) 3329-0314">(33) 3329-0314</a> <br />
              <strong>SAC:</strong>
              <a href="tel://0800-283-0000">0800-283-0000</a> -
              <a href="tel://(33) 3329-0046">(33) 3329-0046</a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="atendimento-section">
      <div class="container">
        <div class="row">
          <div class="col-md-6 atendimento-left">
            <h3 class="session-header green">Fale conosco</h3>
            <form @submit.prevent="onSubmit">
              <div class="form-group">
                <input
                  id="nome"
                  v-model="nome"
                  v-validate="'required'"
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  maxlength="60"
                  placeholder="Nome"
                />
              </div>
              <div class="form-group">
                <input
                  id="email"
                  v-model="email"
                  v-validate="'required|email'"
                  name="email"
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  maxlength="60"
                  placeholder="Email"
                />
                <!-- <span v-show="errors.has('email')" style="color: #d9534f;font-size: 11px;">Insira um e-mail válido</span> -->
              </div>
              <div class="form-group">
                <input
                  id="documento"
                  v-model="documento"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  maxlength="60"
                  placeholder="Qual o seu CPF ou CNPJ?"
                />
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6 input-margin-bottom">
                    <input
                      id="telefone"
                      v-model="telefone"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                      type="text"
                      class="form-control"
                      autocomplete="off"
                      placeholder="Telefone"
                    />
                  </div>
                  <div class="col-sm-6">
                    <input
                      id="celular"
                      v-model="celular"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                      type="text"
                      class="form-control"
                      autocomplete="off"
                      placeholder="Celular"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-6 input-margin-bottom">
                    <input
                      id="assunto"
                      v-model="assunto"
                      type="text"
                      class="form-control"
                      autocomplete="off"
                      maxlength="60"
                      placeholder="Como podemos te ajudar?"
                    />
                  </div>
                  <div class="col-sm-6">
                    <select
                      v-model="selected_departamento"
                      class="form-control"
                    >
                      <option :value="0">Selecione...</option>
                      <option
                        v-for="(item, index) in departamentos"
                        :key="index"
                        :value="item"
                      >
                        {{ item.nome }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <textarea
                  v-model="mensagem"
                  autocomplete="off"
                  maxlength="300"
                  class="form-control"
                  rows="3"
                ></textarea>
              </div>
              <div
                v-show="exibir_msg_retorno"
                :class="[
                  'alert ',
                  {
                    'alert-success': retorno_error ? false : true,
                    'alert-danger': retorno_error ? true : false,
                  },
                ]"
                role="alert"
              >
                {{ msg_retorno }}
              </div>
              <div class="recaptcha-mobile">
                <vue-recaptcha
                  ref="recaptcha"
                  :sitekey="recaptchaKey"
                  @verify="onVerify"
                ></vue-recaptcha>
              </div>
              <button
                type="submit"
                class="btn btn-primary btn-submit"
                :disabled="btnSubmitEnvio"
                @click.prevent="sendFaleConosco()"
              >
                Enviar
              </button>
            </form>
          </div>
          <div class="col-md-6 atendimento-right">
            <!-- Buscar -->
            <div class="bloco-buscar">
              <h4 class="session-header green">O que você procura?</h4>
              <p>Digite abaixo sua dúvida, nós vamos te ajudar!</p>
              <div class="input-group">
                <input
                  v-model="faqbusca"
                  type="text"
                  class="form-control input-search-atendimento"
                  placeholder="Ex: Qual prazo de Entrega?"
                  @keyup.enter="procurarPergunta()"
                />
                <span class="input-group-btn">
                  <a>
                    <button
                      class="btn btn-search-atendimento"
                      type="button"
                      @click.prevent="procurarPergunta()"
                    >
                      <i class="fa fa-search fa-1x" aria-hidden="true"></i>
                    </button>
                  </a>
                </span>
              </div>
            </div>
            <!-- END Buscar -->
            <br />
            <!-- Assuntos mais procurados -->
            <h4 class="session-header green">Assuntos mais procurados</h4>
            <ul>
              <li
                v-for="(item, index) in assuntos_mais_procurados"
                :key="index"
              >
                <router-link
                  tag="a"
                  :to="{
                    name: 'Atendimento',
                    query: { grupo: item.cod_faq_grupo },
                  }"
                >
                  {{ item.descricao }}
                </router-link>
              </li>
            </ul>
            <!-- END Assuntos mais procurados -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script src="https://www.google.com/recaptcha/api.js" async defer></script>
<script>
import { mask } from "vue-the-mask";

import VueRecaptcha from "vue-recaptcha";

export default {
  name: "Atendimento",
  directives: { mask },
  components: { VueRecaptcha },
  metaInfo: {
    title: "Atendimento",
    // Meta tags
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Confira nossos canais exclusivos de atendimento feitos para você. Atendimento por telefone, e-mail e dúvidas frequentes.",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "fale conosco,atendimento,faq,dúvidas,entrega,dpc,DPC distribuidor,Distribuidor atacadista,dpcnet,distribuidor,atacadista,distribuidora",
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: "Atendimento | DPC Distribuidor Atacadista",
      },
      {
        vmid: "og:description",
        name: "og:description",
        content:
          "Confira nossos canais exclusivos de atendimento feitos para você. Atendimento por telefone, e-mail e dúvidas frequentes.",
      },
    ],
  },
  data() {
    return {
      recaptchaKey: this.$store.getters.siteConfig.app_recaptcha_key_v2,
      departamentos: [],
      nome: "",
      email: "",
      documento: "",
      telefone: "",
      assunto: "",
      celular: "",
      selected_departamento: "0",
      email_departamento: "",
      mensagem: "",
      msg_retorno: "",
      retorno_error: Boolean,
      exibir_msg_retorno: false,
      assuntos_mais_procurados: [],
      faqbusca: "",
      formVerificado: false,
      btnSubmitEnvio: false,
    };
  },
  methods: {
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    onSubmit() {
      this.$refs.invisibleRecaptcha.execute();
    },
    //VerificaRecaptcha Google
    onVerify() {
      this.formVerificado = true;
    },
    //BUSCA OS DEPARTAMENTOS.
    buscaDepartamentos() {
      dpcAxios
        .connection({ withCredentials: false })
        .post(process.env.VUE_APP_ENDERECO + api.env.DEPARTAMENTO_FALE_CONOSCO)
        .then((response) => {
          if (response.data.length > 0) {
            this.departamentos = response.data;
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    sendFaleConosco() {
      this.validaCamposFormulario();

      if (this.retorno_error == true) {
        return false;
      }
      this.btnSubmitEnvio = true;

      //PEGA O DD DO TELETONE DIGITADO
      var dd_telefone = this.telefone.length
        ? this.telefone
            .substring(0, this.telefone.indexOf(")"))
            .replace(/[^0-9]/g, "")
        : "";
      //PEGA O NUMERO DO TELEFONE
      var telefone = this.telefone.length
        ? this.telefone
            .substring(this.telefone.indexOf(")"), this.telefone.length)
            .replace(/[^0-9]/g, "")
        : "";
      //PEGA O DD DO CELULAR DIGITADO
      var dd_celular = this.celular.length
        ? this.celular
            .substring(0, this.celular.indexOf(")"))
            .replace(/[^0-9]/g, "")
        : "";
      //PEGA O NUMERO DO CELULAR
      var celular = this.celular.length
        ? this.celular
            .substring(this.celular.indexOf(")"), this.celular.length)
            .replace(/[^0-9]/g, "")
        : "";

      //MONTA UM JSON COM OS DADOS DIGITADOS
      var dados = {
        cod_departamento: this.selected_departamento.cod_departamento,
        email_departamento: this.selected_departamento.email,
        nome: this.nome,
        email: this.email,
        documento: documento.value,
        dd_telefone: dd_telefone,
        telefone: telefone,
        dd_celular: dd_celular,
        celular: celular,
        assunto: this.assunto,
        mensagem: this.mensagem,
      };

      dpcAxios
        .connection({ withCredentials: false })
        .post(process.env.VUE_APP_ENDERECO + api.env.FALE_CONOSCO, dados)
        .then((response) => {
          //SE TIVER SALVO, VAI EXISTIR COD REGISTRO
          if (
            response.data.cod_fale_conosco != undefined &&
            response.data.cod_fale_conosco > 0
          ) {
            this.msg_retorno = "Mensagem enviada com sucesso!";
            this.retorno_error = false;
            this.limpaInformacoes();
          } else {
            this.msg_retorno =
              "Desculpe-nos, ocorreu um erro ao enviar mensagem!";
            this.retorno_error = true;
          }
          this.resetRecaptcha();
          //EXIBIR A MSG DE RETORNO
          this.exibir_msg_retorno = true;
          this.btnSubmitEnvio = false;
        })
        .catch((e) => {
          //CASO CORRA ALGUM ERRO AO EXECUTAR API
          this.msg_retorno =
            "Desculpe-nos, ocorreu um erro ao enviar mensagem!";
          this.retorno_error = true;
          //EXIBIR A MSG DE RETORNO
          this.exibir_msg_retorno = true;
          this.btnSubmitEnvio = false;
          console.error(e);
        });

      setTimeout(
        function () {
          //OCULTA A MSG
          this.exibir_msg_retorno = false;
          //LIMPA MSG
          this.msg_retorno = "";
          //LIMPA RETORNO_ERROR
          this.retorno_error = Boolean;
        }.bind(this),
        7000
      );

      return false;
    },
    limpaInformacoes() {
      this.nome =
        this.email =
        this.documento =
        this.telefone =
        this.assunto =
        this.celular =
        this.mensagem =
          "";
      this.selected_departamento = "0";
      this.formVerificado = false;
      this.resetRecaptcha();
    },
    //FUNCAO PARA BUSCAR OS GRUPOS DE PERGUNTAS
    buscaGrupoPerguntas() {
      dpcAxios
        .connection({ withCredentials: false })
        .get(process.env.VUE_APP_ENDERECO + api.env.GRUPO_PERGUNTA)
        .then((response) => {
          if (response.data.length > 0) {
            let grupo_pergunta = [];
            $.each(response.data, function (index, value) {
              grupo_pergunta.push({
                cod_faq_grupo: value.cod_faq_grupo,
                descricao: value.descricao,
              });
            });

            this.assuntos_mais_procurados = grupo_pergunta;

            //SALVO NA VARIAVEL DO VUEX OS GRUPOS ENCONTRADOS
            this.$store.dispatch("setGrupoPerguntas", grupo_pergunta);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    //FUNCAO PARA REDIRECIONAR PARA A TELA DE DUVIDAS FREQUENTES
    procurarPergunta() {
      var faqbusca = this.faqbusca;
      if (faqbusca.length) {
        //FAZENDO O ROTEAMENTE (QUE NORMALMENTE É FEITO NO ROUTER-LINK)
        this.$router.push({
          name: "Atendimento",
          query: { faqbusca: faqbusca },
        });
      }
    },
    //FUNCAO PARA VALIDAR OS CAMPOS DO FORMULARIO
    validaCamposFormulario() {
      if (this.nome == "" || this.nome.length == 0) {
        this.msg_retorno = "Prezado, campo Nome é obrigatório!";
        this.retorno_error = true;
        this.exibir_msg_retorno = true;
      } else if (
        this.email == "" ||
        this.email.length == 0 ||
        this.errors.has("email")
      ) {
        this.msg_retorno = "Prezado, digite um e-mail válido no campo Email!";
        this.retorno_error = true;
        this.exibir_msg_retorno = true;
      } else if (this.documento == "" || this.documento.length == 0) {
        this.msg_retorno = "Prezado, digite o CPF ou CNPJ!";
        this.retorno_error = true;
        this.exibir_msg_retorno = true;
      } else if (
        (this.telefone == "" || this.telefone.length == 0) &&
        (this.celular == "" || this.celular.length == 0)
      ) {
        this.msg_retorno = "Prezado, informe pelo menos um Número de contato!";
        this.retorno_error = true;
        this.exibir_msg_retorno = true;
      } else if (this.assunto == "" || this.assunto.length == 0) {
        this.msg_retorno = "Prezado, campo Assunto é obrigatório!";
        this.retorno_error = true;
        this.exibir_msg_retorno = true;
      } else if (this.assunto == "" || this.assunto.length == 0) {
        this.msg_retorno = "Prezado, campo Assunto é obrigatório!";
        this.retorno_error = true;
        this.exibir_msg_retorno = true;
      } else if (
        this.selected_departamento == "" ||
        this.selected_departamento.length == 0
      ) {
        this.msg_retorno = "Prezado, selecione um Departamento!";
        this.retorno_error = true;
        this.exibir_msg_retorno = true;
      } else if (this.mensagem == "" || this.mensagem.length == 0) {
        this.msg_retorno = "Prezado, ensira uma Mensagem para ser enviada!";
        this.retorno_error = true;
        this.exibir_msg_retorno = true;
      } else if (this.formVerificado == false || this.formVerificado == "") {
        this.msg_retorno =
          'Prezado, é necessário assinalar o campo "Não sou um robô!';
        this.retorno_error = true;
        this.exibir_msg_retorno = true;
      }

      if (this.exibir_msg_retorno) {
        setTimeout(
          function () {
            //OCULTA A MSG
            this.exibir_msg_retorno = false;
            //LIMPA MSG
            this.msg_retorno = "";
            //LIMPA RETORNO_ERROR
            this.retorno_error = Boolean;
          }.bind(this),
          7000
        );
      }
    },
  },
  created() {
    this.buscaDepartamentos();

    //PEGO O GRUPO DE PERGUNTAS
    var grupo_pergunta = this.$store.getters.grupoPerguntas
      ? this.$store.getters.grupoPerguntas
      : [];

    //SE NAO EXISTIR GRUPO DE PERGUNTAS
    if (grupo_pergunta.length == 0) {
      this.buscaGrupoPerguntas();
    } else {
      this.assuntos_mais_procurados = grupo_pergunta;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.btn-submit {
  margin-top: 10px;
}

#atendimento-section {
  padding-bottom: 30px;

  textarea {
    resize: none;
  }
}

.session-header {
  color: $gray;
  margin-bottom: 15px;
  font-weight: 600;
  font-family: "Montserrat";

  &.green {
    color: var(--brand-primary, $brand-primary) !important;
  }
}

.bloco-buscar {
  background: #fafafa;
  border: 01px solid #eee;
  border-left: 04px solid var(--brand-primary, $brand-primary);
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 25px;

  h4 {
    margin-bottom: 5px;
    color: var(--brand-primary, $brand-primary);
  }
}

input,
textarea,
select {
  border: 01px solid #ddd;
  box-shadow: none;
  -webkit-box-shadow: none;

  &:focus,
  &:active {
    border: 01px solid var(--brand-primary, $brand-primary);
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}

.atendimento-left {
  border-right: 01px solid #eee;
  padding-right: 35px;
  padding-top: 10px;
  padding-bottom: 15px;

  h2 {
    margin-top: 0;
  }
}

.atendimento-right {
  padding-top: 25px;
  padding-bottom: 15px;
  padding-left: 35px;
}

.btn-search-atendimento {
  background: var(--brand-primary, $brand-primary);
  border: 1px solid var(--brand-primary, $brand-primary);
  color: #fff;
}

#top-section {
  padding-top: 20px;
  padding-bottom: 35px;
  //background: #fafafa;
}

ul {
  margin: 0;
  padding: 0;
  list-style: circle;
  margin-left: 16px;
}

li {
  line-height: 25px;
}

@media (max-width: 991px) {
  .atendimento-left {
    border-right: 0px solid #eee;
    padding-right: 15px;
  }

  .atendimento-right {
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  .input-margin-bottom {
    margin-bottom: 15px;
  }

  .recaptcha-mobile {
    transform: scale(0.85);
    -webkit-transform: scale(0.85);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}
</style>
