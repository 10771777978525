// import Main from "./components/Main";

export default [
  {
    path: "/politica-de-privacidade-fuselo",
    name: "politicaMainFuselo",
    component: () =>
      import(
        /* webpackChunkName: "view-politica-de-privacidade-fuselo" */ `./components/Main.vue`
      ).catch((error) => {
        location.reload();
      }),
  },
];
