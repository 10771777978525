<template>
  <header id="header">
    <nav class="navbar navbar-inverse navbar-fixed-top">
      <ToolbarSeller
        v-if="usuarioInfo && usuarioInfo.vendedor"
        id="toolbar-seller"
        :usuario="usuarioInfo"
      />
      <BarTop />

      <section id="header-top">
        <div class="container header-top-container">
          <LeftTop></LeftTop>
          <MiddleTop></MiddleTop>
          <RightTop></RightTop>
        </div>
      </section>
      <HeaderMenu></HeaderMenu>
      <div
        v-if="isAuthenticated && screenWidth <= 991"
        class="razao-select"
        @click="showModalRazao = true"
      >
        <RazaoCardInfo
          ref="RazaoCardInfoMobile"
          @listagemRedes="listagemRedes = $event"
          @selectUnidade="selectUnidade = $event"
        />
      </div>
      <modal-razao
        v-if="showModalRazao"
        :listagem-redes="listagemRedes"
        :select-unidade="selectUnidade"
        @selectUnidade="handleSelectUnidade"
        @close="showModalRazao = !showModalRazao"
      />

      <CookieConsent></CookieConsent>
    </nav>
  </header>
</template>

<script>
import HeaderMenu from "@/components/root/header/HeaderMenu";
import LeftTop from "@/components/root/header/top/LeftTop";
import MiddleTop from "@/components/root/header/top/MiddleTop";
import RightTop from "@/components/root/header/top/RightTop";
import BarTop from "@/components/root/header/top/BarTop";
import OverScreen from "@/components/root/overscreen/OverScreen";
import MiniBanner from "@/components/banner/MiniBanner.vue";
import CookieConsent from "@/components/root/header/top/CookieConsent.vue";
import ToolbarSeller from "@/components/toolbar-seller/ToolbarSeller.vue";
import RazaoCardInfo from "@/components/razao-card-info/RazaoCardInfo.vue";
import ModalRazao from "@/components/root/modal/ModalRazao.vue";

export default {
  name: "HeaderTop",
  components: {
    HeaderMenu,
    LeftTop,
    MiddleTop,
    RightTop,
    BarTop,
    OverScreen,
    MiniBanner,
    CookieConsent,
    ToolbarSeller,
    RazaoCardInfo,
    ModalRazao,
  },
  data() {
    return {
      selectUnidade: Number(this.$store.getters.clienteAtual),
      listagemRedes: [],
      showModalRazao: false,
      screenWidth: window.screen.width,
    };
  },
  computed: {
    usuarioInfo() {
      return this.$store.getters.getUsuarioInfo;
    },
    isAuthenticated() {
      return this.$store.getters.getIsAuthenticated;
    },
  },
  created() {
    window.addEventListener("resize", this.windowWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.windowWidth);
  },
  methods: {
    windowWidth() {
      this.screenWidth = window.screen.width;
    },
    handleSelectUnidade(redeId) {
      this.$refs.RazaoCardInfoMobile.selectUnidade = redeId;
      this.selectUnidade = redeId;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

#header-top {
  background: var(--brand-primary, $brand-primary);
  z-index: inherit;
  position: relative;
  color: var(--brand-primary-contrast, "#fff");
}

.navbar-fixed-top {
  border-width: 0 0 0px;
}
.header-top-container {
  display: flex;
  justify-content: space-between;
  height: 65px;
  padding: 0 25px;
  align-items: center;
  width: 100%;
  max-width: unset !important;
}
.razao-select {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}
.razao-scroll-on {
  -webkit-transform: translateY(-43px);
  -moz-transform: translateY(-43px);
  -ms-transform: translateY(-43px);
  -o-transform: translateY(-43px);
  transform: translateY(-43px);
}

@media (max-width: 991px) {
  .header-top-container {
    border: none;
    height: unset;
    flex-wrap: wrap;
  }
  .razao-select {
    background-color: var(--brand-primary, #2a6a4b);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.navbar {
  transition: 0.2s;
  border: none !important;
}

@media (max-width: 525px) {
  .navbar {
    &.scroll-hidden {
      margin-top: -200px;
    }
  }
  .header-top-container {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
