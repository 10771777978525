<template>
  <div id="popover">
    <div
      id="content-popover"
      :class="{ active: active, 'ajuste-comp': ajusteComp }"
      :style="propsItemProduto"
    >
      <slot></slot>
    </div>
    <div
      v-if="blackmodal"
      id="blackmodal-pop"
      :class="{ 'active-overlay': active }"
      @click="ocultarOverlay()"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Popover",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    blackmodal: {
      type: Boolean,
      default: false,
    },
    ajusteComp: {
      type: Boolean,
      default: false,
    },
    propsItemProduto: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    ocultarOverlay() {
      this.$emit("closeOverlay");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/css/sass/bootstrap/variables";

#popover {
  margin: 0.375em 0;
}

.ajuste-comp {
  width: 100%;
  // overflow-y: auto;
  // max-height: 70vh;
}

@media (max-width: 556px) {
  #popover {
    // display: flex;
    justify-content: center;

    #content-popover {
      // width: calc(100% - 20px);
      // padding: 5px 10px;
      padding: 5px 10px;
      margin: 0 auto;
    }
  }
}
#content-popover {
  position: absolute;
  background: #fff;
  top: 100%;
  //right: 0;
  left: 0px;
  z-index: 9999999;
  display: none;
  margin-top: 1.25em;
  border-radius: 6px;
  padding: 20px;
  opacity: 1;
  //margin-right: 15px;
  //margin-left: 0px;
  -webkit-box-shadow: 0 0px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.2);

  &:before {
    width: 0;
    height: 0;
    content: "";
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
    top: -9px;
    margin-right: -15px;
    position: absolute;
    right: 92%;
    z-index: 9999999;
  }

  &.active {
    display: block;
  }
}

#blackmodal-pop {
  display: none;
  opacity: 0.6;

  &.active-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.63);
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    display: block;
  }
}
</style>
