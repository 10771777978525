<template>
  <content-box title="Informe o código de segurança">
    <div slot="body" class="cl-body">
      <review-payment-resume></review-payment-resume>

      <div>
        <!-- <div>
          <card-mock
            :cardNumber="cardInfo.number"
            :cardHolder="cardInfo.name"
            :cardExpiration="`${cardInfo.expMonth}/${cardInfo.expYear}`"
            :cardBrand="cardInfo.brand"
            :flipCard="true"
            :cardCvc="cardInfo.cvc"
            class="ncr-card-mock"
          ></card-mock>

          <div>
            <span class="nc-card-safe"
              ><i class="fa fa-lock"></i> Todas as transações são seguras</span
            >
          </div>
        </div> -->
        <div>
          <form
            style="max-width: 300px; padding: 15px"
            @submit.prevent="validateAndGoToReview"
          >
            <div class="form-group">
              <label for="cardCvv">Qual é o código de segurança?</label>
              <div style="position: relative">
                <input
                  id="cardCvv"
                  v-model="cardInfo.cardCvv"
                  v-mask="'####'"
                  type="text"
                  class="form-control"
                  maxlength="4"
                  placeholder="123"
                  :class="{ 'form-shake-error error': errorMessage }"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div slot="footer" class="vsc-footer">
      <alert-box
        v-if="errorMessage"
        type="error"
        :message="errorMessage"
      ></alert-box>
      <div class="vf-navigation">
        <button type="submit" class="btn btn-primary" @click="goToReview">
          <span>Continuar</span>&#32;
          <i class="fa fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </content-box>
</template>

<script>
import AlertBox from "@/app/checkout/components/AlertBox";

import ReviewPaymentResume from "../ReviewPaymentResume";
import ContentBox from "@/app/checkout/components/ContentBox";
import CardMock from "./CardMock";

export default {
  name: "ValidateStoredCard",
  components: {
    ReviewPaymentResume,
    ContentBox,
    CardMock,
    AlertBox,
  },
  data() {
    return {
      errorMessage: null,
    };
  },
  computed: {
    cardInfo() {
      const { card } = this.$store.getters.paymentData;

      return card;
    },
    isCardValidated() {
      return this.cardInfo.cardCvv && this.cardInfo.cardCvv.length >= 3;
    },
  },
  watch: {
    cardInfo: {
      handler(newValue) {
        if (newValue.cardCvv) {
          this.errorMessage = null;
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.cardInfo) {
      this.$router.push({ name: "checkout", query: { ...this.$route.query } });
    } else if (this.isCardValidated) {
      this.goToReview();
    }
  },
  deactivated() {
    this.$destroy();
  },
  methods: {
    goToReview() {
      if (this.isCardValidated) {
        this.$router.push({
          name: "checkoutReview",
          query: { ...this.$route.query },
        });
      } else {
        this.errorMessage = "Por favor, informe um código válido.";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";
.vf-navigation {
  display: flex;
  justify-content: flex-end;
  .btn {
    margin: 0 4px;
    padding: 10px 40px;
  }
}

input,
textarea,
select {
  border: 01px solid #ddd;
  box-shadow: none;
  -webkit-box-shadow: none;

  &:focus,
  &:active {
    border: 01px solid var(--brand-primary, $brand-primary);
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  &.error {
    border: 1px solid #da0000 !important;
  }
}
</style>
