<template>
  <div class="discountContent" :data-campanha="campanha.id">
    <div class="discount">
      <h4>Descontos</h4>
      <ComboStage :data="campanha.grupos_bonus" :campanha="campanha.id" />
    </div>
    <div v-if="timelineData.vlrRestanteCredito != null" class="limiteMensal">
      <h4>Limite mensal restante</h4>
      <div class="specsProgress">
        <div class="dialog">
          <span>
            {{ `R$${mascaraValor(timelineData.vlrRestanteCredito)}` }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ComboStage from "@/components/combo-campanhas/ComboStage.vue";
import { mascaraValor } from "@/app/global.js";

export default {
  name: "CardDiscount",
  components: {
    ComboStage,
  },
  props: {
    campanha: {},
  },
  data() {
    return {
      timelineData: {},
    };
  },
  computed: {
    campanhaStatus() {
      return this.$store.getters.campanhaStatus;
    },
  },

  watch: {
    campanhaStatus(newValue) {
      this.getDataTimeline();
    },
  },
  methods: {
    mascaraValor,
    getDataTimeline() {
      const campanhaId = this.campanha.id.toString();
      const campanhaStatus = this.campanhaStatus[campanhaId];

      this.timelineData = campanhaStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.discountContent {
  background: white;
  max-width: 500px;
  padding: 0px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .discount {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
  }

  h4 {
    color: #303932;
    margin: 10px 0px 0px 0px;
    font-weight: 600;
  }

  .limiteMensal {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;

    h4 {
      font-size: 19px;
    }
    .specsProgress {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2px 0 10px 0px;
      a {
        font-weight: bold;
        color: #303932;
        display: flex;
        align-items: center;
        gap: 3px;
        cursor: pointer;

        .header-icon {
          transform: rotate(0deg);
          transition-duration: 0.3s;
        }
        .rotate {
          transform: rotate(180deg);
          transition-duration: 0.3s;
        }
      }
      .dialog {
        background: var(--brand-primary, $brand-primary);
        padding: 5px;
        border-radius: 3px;
        text-align: center;
        span {
          color: white;
          font-weight: 600;
        }
      }
    }
  }
}

@media (max-width: 468px) {
  .dialog {
    span {
      font-size: 12px;
    }
  }
  h4 {
    font-size: 15px;
  }
}
</style>
