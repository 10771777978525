<template>
  <div class="w-full bg-seller">
    <div class="container">
      <div class="flex flex-row justify-between items-center pt-3 pb-3">
        <div class="flex flex-row justify-center items-center bg-seller__name">
          <i class="mdi mdi-account-eye text-3xl mr-2"></i>
          <h5 class="p-0 m-0">Vendedor: {{ usuario.vendedor_data.nome }}</h5>
        </div>
        <div class="flex flex-row justify-center items-center">
          <ul class="flex flex-row justify-center items-center p-0 m-0">
            <li
              class="list-none font-bold text-xl flex flex-row justify-center items-center"
            >
              <router-link
                class="mr-8 flex justify-center items-center bg-seller__name"
                to="/vendedor/carrinhos"
              >
                Carrinhos
              </router-link>
              <router-link class="bg-seller__name" to="/vendedor/pedidos">
                Pedidos</router-link
              >
            </li>
          </ul>
          <button
            class="pr-6 pl-6 ml-8 flex justify-center items-center btn-primary-outline rounded-default text-xl border-solid bg-seller__name"
            @click="logout"
          >
            Sair
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToolbarSeller",
  components: {},
  props: {
    usuario: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},

  methods: {
    logout() {
      Account.logout();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/global.css";

.bg-seller {
  background-color: #ffdb65;

  &__name {
    color: #373737 !important;
    border-color: #373737 !important;
  }
}
</style>
