import { render, staticRenderFns } from "./AlertCampanhaPromotion.vue?vue&type=template&id=b06c9638&scoped=true"
import script from "./AlertCampanhaPromotion.vue?vue&type=script&lang=js"
export * from "./AlertCampanhaPromotion.vue?vue&type=script&lang=js"
import style0 from "./AlertCampanhaPromotion.vue?vue&type=style&index=0&id=b06c9638&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b06c9638",
  null
  
)

export default component.exports