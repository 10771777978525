<template>
  <div class="order-overview">
    <content-box title="Resumo do pedido">
      <div slot="body">
        <div class="checkout_resume_content">
          <div class="info-pedido">
            <div class="checkout__resume_item">
              <div class="title_resume">Pedido:</div>
              <div class="truncate">#{{ dadosPedido.pedidoId }}</div>
            </div>

            <template v-if="isAtFinalStage">
              <div class="checkout__resume_item truncate">
                <div class="title_resume">Razão:</div>
                <div
                  class="truncate"
                  :title="dadosPedido.informacoes.nomerazao"
                >
                  {{ dadosPedido.informacoes.nomerazao }}
                </div>
              </div>
              <div class="checkout__resume_item truncate">
                <div class="title_resume">Tabela:</div>
                <div class="truncate" :title="dadosPedido.informacoes.tabvnd">
                  {{ dadosPedido.informacoes.tabvnd }}
                </div>
              </div>
              <!-- <div class="checkout__resume_item truncate">
                <div class="title_resume">Pagamento:</div>
                <div
                  class="truncate"
                  :title="dadosPedido.informacoes.formaPagamento"
                >
                  {{ dadosPedido.informacoes.formaPagamento }}
                </div>
              </div>
              <div class="checkout__resume_item line truncate">
                <div class="title_resume">Condição:</div>
                <div
                  class="truncate"
                  :title="dadosPedido.informacoes.condicaoPagamento"
                >
                  {{ dadosPedido.informacoes.condicaoPagamento }}
                </div>
              </div> -->

              <hr style="margin: 12px 0px" />
              <div class="checkout__resume_item_col2 truncate cri-group">
                <div
                  class="cg-header"
                  @click="showInputNfOrderNumber = !showInputNfOrderNumber"
                >
                  <div class="title_resume">
                    <span>Opções adicionais</span>
                  </div>
                  <div>
                    <i
                      class="fa"
                      :class="{
                        'fa-angle-up': showInputNfOrderNumber,
                        'fa-angle-down': !showInputNfOrderNumber,
                      }"
                    ></i>
                  </div>
                </div>

                <div v-if="showInputNfOrderNumber" class="cg-content">
                  <form @submit.prevent>
                    <label class="title_resume" for="invoiceOrderId"
                      >Número xPed</label
                    ><span>
                      <i
                        title="Não é obrigatório informar"
                        class="fa fa-question-circle"
                      ></i>
                    </span>
                    <div class="cc-group">
                      <input
                        id="invoiceOrderId"
                        v-model="nfOrderId"
                        v-mask="'####################'"
                        type="text"
                        class="form-control"
                        maxlength="20"
                        autocomplete="off"
                      />
                      <!-- <button type="submit" class="btn btn-sm btn-primary">
                        <i class="fa fa-check"></i>
                        <span>Salvar</span>
                      </button> -->
                    </div>
                  </form>
                </div>
              </div>
            </template>

            <hr style="margin: 12px 0px" />

            <div class="checkout__resume_item_col2 truncate">
              <div class="title_resume">Produtos</div>
              <div class="truncate">R$ {{ mascaraValor(totalBruto) }}</div>
            </div>
            <div class="checkout__resume_item_col2 truncate">
              <div class="title_resume">ICMS/ST</div>
              <div class="truncate">R$ {{ mascaraValor(totalSt) }}</div>
            </div>
            <div
              v-if="totalStDesonerado > 0"
              class="checkout__resume_item_col2 truncate"
            >
              <div class="title_resume">ICMS Desonerado (-)</div>
              <div class="truncate">
                - R$ {{ mascaraValor(totalStDesonerado) }}
              </div>
            </div>

            <div class="checkout__resume_item_col2 truncate">
              <div class="title_resume">Desconto (-)</div>
              <div class="truncate">- R$ {{ mascaraValor(totalDesconto) }}</div>
            </div>

            <div class="checkout__resume_item_col2 truncate">
              <div class="title_resume">Entrega</div>
              <div class="truncate text-success">Grátis</div>
            </div>

            <div
              v-if="
                paymentData &&
                paymentData.card &&
                paymentMethod === PAYMENT_METHODS.CREDIT_CARD
              "
              class="checkout__resume_item_col2 cri-label"
            >
              <div class="title_resume">Você irá pagar</div>
              <div>
                {{ paymentData.card.cardParcels }}x R$
                {{ mascaraValor(totalLiquido / paymentData.card.cardParcels) }}
              </div>
            </div>

            <div class="checkout__resume_item_col2">
              <div class="title_resume">Total</div>
              <div>R$ {{ mascaraValor(totalLiquido) }}</div>
            </div>

            <template v-if="isAtFinalStage && isPaymentValid">
              <br />
              <alert-box
                v-if="placeOrderErr.message"
                :type="placeOrderErr.type"
                :message="placeOrderErr.message"
              ></alert-box>
              <br />

              <!-- <small
                v-if="!isOrderParamsAvailable"
                style="cursor: pointer; padding: 10px 15px; font-size: 14px"
                class="cart-error"
                @click="toggleModalParams()"
              >
                <i class="mdi mdi-alert-circle-outline fa fa-1x"></i> Alguns
                parâmetros do pedido não estão mais disponíveis. Toque para
                corrigir.
              </small> -->

              <div
                v-if="!isOrderParamsAvailable"
                style="cursor: pointer"
                @click="toggleModalParams()"
              >
                <alert-box
                  type="warn"
                  message="Alguns
                parâmetros do pedido não estão mais disponíveis. Toque para
                corrigir."
                ></alert-box>
              </div>

              <button
                class="btn oo-btn-placeorder"
                :class="{
                  'btn-danger': placeOrderBtnErr === 'error',
                  'btn-warning': placeOrderBtnErr == 'warn',
                  'btn-primary': !placeOrderBtnErr,
                }"
                :disabled="prcessingOrder || disablePlaceOrderBtn"
                @click="placeOrder"
              >
                <i v-if="prcessingOrder" class="fa fa-spin fa-spinner"></i>
                <i v-else class="fa fa-shopping-basket"></i>
                {{ placeOrderBtnText }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </content-box>
  </div>
</template>

<script>
import ContentBox from "@/app/checkout/components/ContentBox";
import CartMixin from "@/app/checkout/cart/CartMixin";
import bus from "@/utils/events/bus";
import AlertBox from "@/app/checkout/components/AlertBox";

export default {
  name: "OrderOverview",
  components: {
    ContentBox,
    AlertBox,
  },
  mixins: [CartMixin],
  props: {},
  data() {
    return {
      prcessingOrder: false,
      showInputNfOrderNumber: false,
      nfOrderId: null,
      placeOrderErr: {
        message: null,
        type: null,
      },
      placeOrderBtnErr: null,
      disablePlaceOrderBtn: false,
      minOrderValue: 0,
    };
  },
  computed: {
    placeOrderBtnText() {
      return this.prcessingOrder ? "Processando..." : "Finalizar Pedido";
    },
    isAtFinalStage() {
      return this.$route.name == "checkoutReview";
    },
  },

  watch: {
    totalLiquido(newValue) {
      if (this.minOrderValue <= newValue) {
        if (this.placeOrderErr.errCode === 1014) {
          this.clearErrors();
          this.disablePlaceOrderBtn = false;
        }
      } else {
        this.getPedidoRegra();
      }
    },
    isAtFinalStage(newValue) {
      this.clearErrors();
    },
    placeOrderErr: {
      handler(newValue) {
        if (newValue && newValue.type) {
          this.placeOrderBtnErr = newValue.type;

          setTimeout(() => {
            this.placeOrderBtnErr = null;
          }, 3000);
        }
      },
      deep: true,
    },
  },
  mounted() {
    // console.log(this.dadosPedido);
    this.getPedidoRegra();
    bus.$emit("shouldUpdateCartItems");
  },
  methods: {
    toggleModalParams() {
      bus.$emit("openModalParametros", true);
    },
    clearErrors() {
      this.placeOrderErr = {
        message: null,
        type: null,
      };
    },
    async getPedidoRegra() {
      const orderId = this.dadosPedido.pedidoId;

      try {
        const url = `${process.env.VUE_APP_ECOMMERCE}${api.env.NOVO_PEDIDO_EC}regra/${orderId}`;
        const { data } = await dpcAxios.connection().get(url);

        if (!data.error) {
          const { regra } = data;
          this.minOrderValue = regra.vlrpedidomin;
          if (this.minOrderValue > this.totalLiquido) {
            // console.log(this.totalLiquido);
            this.handlePlaceOrderErrors({
              errCode: 1014,
              message: regra.mensagem,
            });
          }

          // this.regraPedidoMinimo = {
          //   valor: regra.vlrpedidomin,
          //   desconsiderast: regra.desconsiderast == 1,
          //   mensagem: regra.mensagem,
          // };
        } else {
          throw { message: data.message };
        }
      } catch (e) {
        console.error(e);
      }

      // abrir modal de parametros caso algum dado esteja indisponível. Futuramente levar para outra seção do código, mas antes precisa melhorar a exibição do modal.
      // console.log(this.dadosPedido);
      if (!this.isOrderParamsAvailable) {
        this.toggleModalParams();
      }
    },
    //  async buscaRegraAtual() {
    //   const id_pedido =
    //   if (!id_pedido) return;
    //   const response = await dpcAxios
    //     .connection()
    //     .get(
    //       process.env.VUE_APP_ECOMMERCE +
    //         api.env.NOVO_PEDIDO_EC +
    //         `regra/${id_pedido}`
    //     );
    //   const { regra, error } = response.data;
    //   if (error == 0) {
    //     if (regra) {
    //       this.valorRegra = regra.vlrpedidomin;
    //       this.mensagemRegra = `Para ser finalizado, o pedido atual deve possuir valor superior a R$ ${this.mascaraValor(
    //         regra.vlrpedidomin
    //       )}`;
    //     }
    //   }
    // },
    async placeOrder() {
      if (this.disablePlaceOrderBtn) return;

      this.clearErrors();

      this.prcessingOrder = true;

      let params = {
        pedido_id: this.dadosPedido.pedidoId,
        nfOrderId: this.nfOrderId,
      };

      //   console.log(this.paymentData);

      try {
        const url = `${process.env.VUE_APP_ECOMMERCE}${api.env.FINALIZAR_PEDIDO_EC}`;
        const { data } = await dpcAxios
          .connection()
          .post(url, { ...params, ...this.paymentData });

        if (data && !data.error) {
          if (data.situacao == 1) {
            const items = this.cartProducts.map((item, index) => ({
              item_id: item.integracao_id,
              item_name: item.descricao,
              discount: item.vlrdesconto + item.vlrstdesonerado,
              price: item.valor + item.vlrst,
              currency: "BRL",
              quantity: item.qtd,
              decim: item.decim,
              decim_num_casas: item.decim_num_casas,
              index,
            }));

            gtag("event", "purchase", {
              transaction_id: this.dadosPedido.pedidoId,
              affiliation: "DPC Distribuidor Atacadista",
              shipping: 0,
              value: this.totalLiquido,
              currency: "BRL",
              tax: this.totalSt,
              items,
            });

            // console.debug({
            //   transaction_id: this.dadosPedido.pedidoId,
            //   affiliation: "DPC Distribuidor Atacadista",
            //   shipping: 0,
            //   value: this.totalLiquido,
            //   currency: "BRL",
            //   tax: this.totalSt,
            //   items,
            // });

            this.$emit("orderPlacedData", data);

            this.$nextTick(() => {
              localStorage.removeItem("pedidoaberto");
              this.$store.dispatch("addItemCarrinhoAtual", "shouldCleanCart");
            });
          }
        } else {
          throw { errDetails: data };
        }
      } catch (e) {
        console.error(e);
        this.handlePlaceOrderErrors(e.errDetails);
      }

      this.prcessingOrder = false;
    },
    handlePlaceOrderErrors(e) {
      this.placeOrderErr.message =
        (e && e.message) || "Ocorreu um erro ao finalizar seu pedido.";
      this.placeOrderErr.type = "error";
      this.placeOrderErr.errCode = e.errCode;
      if (!e) return;
      //   console.log(e);
      switch (e.errCode) {
        case 1014:
          this.placeOrderErr.type = "warn";
          this.disablePlaceOrderBtn = true;
          break;
        case 1017: // Unknown error while paying
          if (this.paymentMethod == this.PAYMENT_METHODS.CREDIT_CARD) {
            this.cardPaymentError.error = e.paymentErr || 0;
            this.cardPaymentError.message = e.message;
          }
          break;
        case 1012: // Cliente inválido.
          this.disablePlaceOrderBtn = true;
          break;

        case 1015: // Itens invalidos / sem estqoue / inativos.
          this.placeOrderErr.type = "warn";
          // futuramente vamos fazer isso em uma página separada, e exibir um modal etc, pra nao ficar baguncado assim.
          if (e.unavailableItems) {
            e.unavailableItems.forEach((info) => {
              let item = this.cartProducts.find(
                (x) => x.cod_produto == info.produtoId
              );

              // console.log(this.carrinhoAtual);

              if (item) {
                item.qtdestoque = info.maxAvailable;

                if (info.maxAvailable == 0) {
                  item.status = 0;
                } else {
                  item.qtd = info.maxAvailable;
                }

                item.lastUpdate = 1;

                this.$store.dispatch("addItemCarrinhoAtual", item);

                // TODO: REMOVER ISSO DAQUI URGENTEMENTE. COLOCA NA CTION DO VUEX PELO AMOR DE DEUS, MUITO CÓDIGO DUPLICADO!!!
                dpcAxios
                  .connection()
                  .post(
                    process.env.VUE_APP_ECOMMERCE + api.env.ADICIONA_PRODUTO_EC,
                    {
                      pedido_id: this.dadosPedido.pedidoId,
                      itens: [
                        {
                          produto_id: item.cod_produto,
                          qtd: item.qtd,
                          vlrproduto: item.valor,
                          vlrdesconto: item.vlrdesconto,
                          vlrst: item.vlrst,
                          status: item.status,
                          decim: item.decim,
                          decim_num_casas: item.decim_num_casas
                        },
                      ],
                    }
                  );
              }
            });
          }
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-overview {
  // Sticky removido até solucionar o problema nas telas pequenas :/. Testar em 854 x 505 (exemplo real do Hotjar)
  // position: sticky;
  // top: 130px;
}

.checkout_resume_content {
  font-family: "Open Sans";

  .title_resume {
    color: #737373;
    margin-right: 5px;
    font-size: 14px;
  }

  .cri-group {
    padding: 0;
    margin: 0;
    flex-direction: column;

    .cg-header {
      cursor: pointer;
      display: flex;
      flex: 1;
      width: 100%;
      place-content: space-between;

      .title_resume {
        font-size: 13px;
        color: #4a4a4a;
      }
    }

    .cg-content {
      width: 100%;
      padding-top: 10px;

      .cc-group {
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          height: 30px;
          margin-right: 5px;
          flex: 1;
        }

        button {
          padding: 2px 10px;
          height: 30px;
          flex: 1 1 30%;
          max-width: 100px;
        }
      }
    }

    i {
      font-size: 16px;
      font-weight: bold;
      padding: 0 2.5px;
    }
  }

  .checkout__resume_item {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #4a4a4a;
    margin-bottom: 7.5px;
  }

  .checkout__resume_item_col2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #4a4a4a;
    margin-bottom: 7.5px;

    &.cri-label {
      font-size: 15px;
      color: #3a3a3a;
      padding: 6px 0px 12px 0;
      border-bottom: 1px solid #e5e5e5;
      margin: 0 0px 12px 0px;
      flex-flow: wrap;

      .title_resume {
        font-size: inherit;
      }
    }
  }

  .oo-btn-placeorder {
    width: 100%;
    padding: 10px 15px;
  }
}
</style>
