import { render, staticRenderFns } from "./ComboAtivacaoCard.vue?vue&type=template&id=4c842347&scoped=true"
import script from "./ComboAtivacaoCard.vue?vue&type=script&lang=js"
export * from "./ComboAtivacaoCard.vue?vue&type=script&lang=js"
import style0 from "./ComboAtivacaoCard.vue?vue&type=style&index=0&id=4c842347&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c842347",
  null
  
)

export default component.exports