import { default as Pagamento } from "./payment/components/Main.vue";
// import { default as Carrinho } from "./cart/components/Main.vue";

import { default as Checkout } from "./Checkout.vue";
import { default as CheckoutPayment } from "./payment/Card/PaymentList.vue";
import { default as CheckoutAddCard } from "./payment/Card/AddCard.vue";
import { default as CheckoutReview } from "./Review.vue";
import { default as ValidateStoredCard } from "./payment/Card/ValidateStoredCard.vue";

export default [
  {
    path: "/pagamento",
    name: "pagamentoRota",
    component: Pagamento,
    redirect: "/checkout",
  },
  {
    path: "/carrinho",
    name: "carrinhoRota",
    // component: Carrinho,
    redirect: "/checkout",
  },
  {
    path: "/checkout",
    name: "checkout",
    component: Checkout,
    children: [
      {
        name: "checkoutPayment",
        path: "payment",
        component: CheckoutPayment,
      },
      {
        name: "checkoutValidateStoredCard",
        path: "validateStoredCard",
        component: ValidateStoredCard,
      },
      {
        name: "checkoutAddCard",
        path: "addCard",
        component: CheckoutAddCard,
      },
      {
        name: "checkoutReview",
        path: "review",
        component: CheckoutReview,
      },
    ],
  },
];
