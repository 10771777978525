<template>
  <div>
    <div class="searchbox">
      <input
        v-model="busca"
        type="text"
        class="form-control input-search searchbox__input"
        :class="{ 'searchbox__input--sm': size == 'sm' }"
        :placeholder="placeholder"
        autocomplete="off"
        @keyup.enter="submitSearch()"
      />

      <button
        class="btn btn-primary searchbox__button"
        aria-label="Pesquisar"
        type="button"
        @click="submitSearch()"
      >
        <i class="fa fa-search" aria-hidden="true"></i>
      </button>
    </div>
    <div v-if="searchedItemLabel.length > 0">
      <small>
        Exibindo resultados para: <strong>"{{ searchedItemLabel }}"</strong>
      </small>
      <a href="javascript:void(0)" @click="submitSearch(true)">
        <small>Limpar</small>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBox",
  props: {
    placeholder: {
      type: String,
      default: "Digite para pesquisar...",
      required: false,
    },
    size: {
      type: String,
      default: "",
      required: false,
    },
    searchTerm: {
      required: false,
      default: "",
      type: String | Number,
    },
  },
  data() {
    return {
      busca: "",
      searchedItemLabel: "",
    };
  },
  mounted() {
    if (this.searchTerm) {
      this.busca = this.searchTerm;
    }
  },
  methods: {
    submitSearch(clear = false) {
      if (clear) {
        this.busca = "";
      }
      this.searchedItemLabel = this.busca;
      this.$emit("search", this.busca);
    },
  },
};
</script>

<style lang="scss" scoped>
.searchbox {
  display: flex;

  &__input {
    box-shadow: none;
    border-color: transparent;
    border-radius: 6px 0px 0px 6px;
    height: 40px;
    border-color: #e5e5e5;
    border-right: none;

    &--sm {
      height: 37px;
      font-size: 14px;
    }
  }

  &__button {
    border-radius: 0 6px 6px 0;
  }
}
</style>
