import store from "@/vuex";

export default [
  {
    path: "/vendedor",
    name: "Vendedor",
    component: () =>
      import(
        /* webpackChunkName: "view-vendedor" */ `./components/Main.vue`
      ).catch((error) => {
        location.reload();
      }),
    beforeEnter(to, from, next) {
      if (!store.getters.getUsuarioInfo?.vendedor) {
        return location.replace("/404");
      }
      next();
    },
    children: [
      {
        name: "VendedorPedidos",
        path: "pedidos",
        meta: {
          description: "Pedidos",
          requiresAuthentication: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "view-pedidos" */ `./components/Orders.vue`
          ).catch((error) => {
            location.reload();
          }),
      },
      {
        name: "Carrinho",
        path: "carrinhos",
        meta: {
          description: "Carrinho",
          requiresAuthentication: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "view-carrinho" */ `./components/Cart.vue`
          ).catch((error) => {
            location.reload();
          }),
      },
      {
        name: "VendedorPedidoDetalhes",
        path: "pedidos/detalhes/:orderId/:customerId",
        meta: {
          description: "Detalhes do pedido ",
          requiresAuthentication: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "view-pedidos-detalhes" */ `./components/SellerOrderDetails.vue`
          ).catch((error) => {
            location.reload();
          }),
      },
    ],
  },
];
