var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.removeitem)?[(_vm.quantidade > 0 && !_vm.loading)?_c('div',{class:{
        'item-qty-simple': _vm.simple,
        'item-qty': !_vm.simple,
        square: _vm.squareType,
        round: _vm.roundType,
      }},[(_vm.showButtons)?_c('button',{staticClass:"button-minus",attrs:{"disabled":_vm.quantidade < 1 || _vm.loadingProduto},on:{"click":function($event){return _vm.changeItemQty('minus', _vm.item)}}},[_c('i',{staticClass:"fa",class:{
            'fa-spinner fa-spin': _vm.loadingProduto,
            'fa-minus': !_vm.loadingProduto,
          },staticStyle:{"font-size":"12px !important"},style:({ opacity: _vm.quantidade == 0 ? '.5' : null })})]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.quantidade),expression:"quantidade",modifiers:{"lazy":true}}],staticClass:"qty",attrs:{"type":"number","disabled":_vm.loadingProduto,"min":"0"},domProps:{"value":(_vm.quantidade)},on:{"change":[function($event){_vm.quantidade=$event.target.value},function($event){return _vm.changeItemQty(null, _vm.item)}],"!keydown":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==9)return null;$event.preventDefault();$event.stopPropagation();},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==16)return null;$event.preventDefault();$event.stopPropagation();}],"!keyPress":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==9)return null;$event.preventDefault();$event.stopPropagation();},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==16)return null;$event.preventDefault();$event.stopPropagation();}],"keyup":_vm.qtdFocus,"click":function($event){return _vm.clickInput(_vm.item)},"focus":function($event){return $event.target.select()},"keypress":function($event){return _vm.isNumber($event)}}}),(_vm.showButtons)?_c('button',{class:_vm.squareType
            ? 'squareButton button-plus '
            : 'roundButton button-plus ',attrs:{"disabled":_vm.loadingProduto},on:{"click":function($event){return _vm.changeItemQty('plus', _vm.item)}}},[_c('i',{class:{
            'fa fa-spinner fa-spin': _vm.loadingProduto,
            'fa fa-plus': !_vm.loadingProduto,
          },staticStyle:{"font-size":"12px !important"}})]):_vm._e()]):[_c('button',{staticClass:"btn-addItem",class:{ 'square-buy': _vm.squareType },attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.changeItemQty('plus', _vm.item)}}},[_c('i',{class:{
            'fa fa-spinner fa-spin': _vm.loading,
            'fa fa-plus': !_vm.loading,
          }}),(!_vm.loading)?_c('span',[_vm._v(" Adicionar")]):_c('span',[_vm._v(" Carregando")])])]]:_c('div',{class:{ 'item-qty-simple': _vm.simple, 'item-qty': !_vm.simple }},[_c('button',{staticClass:"button-minus",attrs:{"disabled":_vm.loadingProduto || _vm.loading},on:{"click":function($event){return _vm.changeItemQty('removeItem', _vm.item)}}},[_c('i',{staticClass:"fa fa-times text-danger"})])]),(_vm.canRemoveAll && !_vm.removeitem)?_c('span',{staticClass:"item-remove",attrs:{"title":"Remover produto do carrinho"},on:{"click":function($event){return _vm.changeItemQty('removeItem', _vm.item)}}},[_vm._v("remover")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }