// import Main from "./components/Main.vue";

export default [
  {
    path: "/trabalhe-conosco",
    name: "trabalhe-conoscoMain",
    component: () =>
      import(
        /* webpackChunkName: "view-trabalhe-conosco" */ `./components/Main.vue`
      ).catch((error) => {
        location.reload();
      }),
    beforeEnter: (to, from, next) => {
      if (process.env.VUE_APP_STORE_SHOW_TABALHE_CONOSCO == "true") {
        next();
      } else {
        next({ path: "/404" });
      }
    },
  },
];
