import * as dpcAxios from "@/dpcAxios";
import Bus from "@/utils/events/bus";
import router from "@/router";
import state from "./state";

export const setIsAuthenticated = (context, payload) => {
  context.commit("setIsAuthenticated", payload);

  if (state.isDpcMobileWebView) {
    // Post a message to the WebView when authentication state change.
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "auth_state",
          isAuthenticated: payload,
        })
      );
  }
};

export const setInformacaoGlobal = (context, payload) => {
  context.commit("setInformacaoGlobal", payload);
};

export const setSiteConfig = (context, payload) => {
  const searchScript = document.createElement("script");
  searchScript.type = "application/ld+json";
  searchScript.innerHTML = `
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      "name": "${payload.app_name}",
      "url": "${process.env.VUE_APP_URL}",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "${process.env.VUE_APP_URL}/catalogo?busca={search_term_string}",
        "query-input": "required name=search_term_string"
      }
    }
  `;
  document.head.appendChild(searchScript);

  const searchLink = document.createElement("link");
  searchLink.rel = "search";
  searchLink.type = "application/opensearchdescription+xml";
  searchLink.href = "opensearch.xml";
  searchLink.title = payload.app_name;
  document.head.appendChild(searchLink);

  if (payload?.app_color_brand_primary) {
    document.documentElement.style.setProperty(
      "--brand-primary",
      payload.app_color_brand_primary
    );
    document.documentElement.style.setProperty(
      "--brand-secondary",
      payload.app_color_brand_primary
    );
  }

  // if (payload?.app_color_brand_primary_contrast) {
  document.documentElement.style.setProperty(
    "--brand-primary-contrast",
    payload?.app_color_brand_primary_contrast || "#fff"
  );
  // }

  if (payload?.app_color_brand_secondary) {
    document.documentElement.style.setProperty(
      "--brand-accent",
      payload.app_color_brand_secondary
    );
  }

  if (payload.app_hotjar_id) {
    const hotjarScript = document.createElement("script");
    hotjarScript.innerHTML = `
  (function (h, o, t, j, a, r) {
  h.hj =
  h.hj ||
  function () {
    (h.hj.q = h.hj.q || []).push(arguments);
  };
  h._hjSettings = { hjid: "${payload.app_hotjar_id}", hjsv: 6 };
  a = o.getElementsByTagName("head")[0];
  r = o.createElement("script");
  r.async = 1;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  `;

    document.head.appendChild(hotjarScript);
  }

  if (payload.app_recaptcha_key_v2) {
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit";
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }

  if (payload.app_jivochat_token) {
    const script = document.createElement("script");
    script.src = `//code-eu1.jivosite.com/widget/${payload.app_jivochat_token}}`;
    script.async = true;
    document.head.appendChild(script);
  }

  if (payload.app_google_analy_id) {
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${payload.app_google_analy_id}}`;
    script.async = true;
    document.head.appendChild(script);

    gtag("config", payload.app_google_analy_id);
    gtag("create", payload.app_google_analy_id, "auto");
  }

  if (payload.app_facebook_pixel_id) {
    fbq("init", payload.app_facebook_pixel_id);
    fbq("track", "PageView");

    //! this wont work. Dont even make sense to add there :)
    const noscript = document.createElement("noscript");
    noscript.innerHTML = `
      <img
        height="1"
        width="1"
        style="display: none"
        src="https://www.facebook.com/tr?id=${payload.app_facebook_pixel_id}&ev=PageView&noscript=1"
      />
    `;
    document.body.appendChild(noscript);
  }

  if (payload.app_mailbiz) {
    const script = document.createElement("script");
    script.innerHTML = `
    (function (m, a, i, l, b, z, j, s) {
      m["MailbizIntegration"] = {
        id: b,
        ready: 0,
      };
      z = a.createElement(i);
      j = a.getElementsByTagName(i)[0];
      z.async = 1;
      z.src = l;
      j.parentNode.insertBefore(z, j);
    })(
      window,
      document,
      "script",
      "https://d3eq1zq78ux3cv.cloudfront.net/static/scripts/integration.min.js",
      "${payload.app_mailbiz}"
    );
  `;
    document.body.appendChild(script);
  }

  if (payload.app_favicon_url) {
    const favicon = document.createElement("link");
    favicon.rel = "shortcut icon";
    favicon.type = "image/png";
    favicon.href = payload.app_favicon_url;
    document.head.appendChild(favicon);
  }

  if (payload.head_custom_elements) {
    const headCustom = `<!-- Customer Custom Head Elements -->
      ${payload.head_custom_elements}
    <!-- End Customer Custom Head Elements -->
  `;

    $("head").append(headCustom);
  }

  context.commit("setSiteConfig", payload);
};

export const setToken = (context, payload) => {
  context.commit("setToken", payload);
};

export const logoff = (context, payload) => {
  context.commit("logoff", payload);
};

export const setUser = (context, payload) => {
  context.commit("setUser", payload);
};

function debounce(func, timeout = 600) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

// TODO: melhorar isso, ficou bem esquisito. Manter tudo simples, sempre.
/**
 * Método checkOrderDiscounts
 * Responsável por verificar se o item adicionado ganhou algum desconto
 * e salvar as informações das campanhas no store
 */

async function checkOrderDiscounts(context) {
  // if (router.history.current.name != "campanhaMain") return

  if (!state.campanhasDisponiveis.length) return;

  const { campanhasDisponiveis } = state;
  context.commit("setCampanhaStatus", campanhasDisponiveis);
}

export async function getCampanhasDisponiveis(context, payload) {
  const { dadosPedido } = context.getters;

  if (!dadosPedido.razao) return;

  const preco = {
    pedido_id: dadosPedido.pedidoId,
    cliente_id: dadosPedido.razao,
    tabvnd_id: dadosPedido.tabvnd,
  };

  const tabvnd_id = dadosPedido.tabvnd;

  const hascasadinha = {
    empresa: {
      id: dadosPedido.unidade,
    },
    cliente: {
      id: dadosPedido.razao,
    },
  };

  const hasprodbloq = {
    cliente: {
      id: dadosPedido.razao,
    },
  };

  const columns = { hascasadinha, hasprodbloq };

  const { limit, offset, searchTerm, bonusType } = payload;

  const params = {};

  params.cliente_id = dadosPedido.razao;
  params.paramsProduct = {
    preco,
    tabvnd_id,
    columns,
  };

  params.limit = limit;
  params.offset = offset;
  params.searchTerm = searchTerm;
  params.bonusType = bonusType ?? [0, 1, 2];

  if (payload.clear) {
    context.commit("setCampanhasDisponiveis", []);
  }

  try {
    const url = `${process.env.VUE_APP_ECOMMERCE}${api.env.BUSCA_CAMPANHAS}`;
    const url1 = `${process.env.VUE_APP_ECOMMERCE}${api.env.VERIFICA_DESCONTOS_PEDIDO}/${dadosPedido.pedidoId}`;
    const { data } = await dpcAxios.connection().post(url, params);
    // const { data: data1 } = await dpcAxios.connection().post(url1, params);

    if (!data.error) {
      if (data.length <= 0 && payload.clear) {
        if (!searchTerm) {
          context.commit("setCampanhasDisponiveis", "show_not_found");
        } else {
          context.commit("setCampanhasDisponiveis", "search_not_found");
        }
      } else {
        if (payload.clear) {
          context.commit("setCampanhasDisponiveis", data);
        } else {
          context.commit(
            "setCampanhasDisponiveis",
            state.campanhasDisponiveis.concat(data)
          );
        }
      }

      if (data.length == 0) {
        context.commit("setHasMoreCampaignsToShow", false);
      } else {
        context.commit("setHasMoreCampaignsToShow", true);
      }
      discounts(context);
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err.message);
    Bus.$emit("show-notification", ["error", "Desculpe.", `${err.message}`]);
  }
}

export async function searchProductsBonus(context, payload) {
  const { dadosPedido } = context.getters;

  if (!dadosPedido.razao) return;

  const preco = {
    pedido_id: dadosPedido.pedidoId,
    cliente_id: dadosPedido.razao,
    tabvnd_id: dadosPedido.tabvnd,
  };

  const tabvnd_id = dadosPedido.tabvnd;

  const hascasadinha = {
    empresa: {
      id: dadosPedido.unidade,
    },
    cliente: {
      id: dadosPedido.razao,
    },
  };

  const hasprodbloq = {
    cliente: {
      id: dadosPedido.razao,
    },
  };

  const columns = { hascasadinha, hasprodbloq };
  const params = {};
  params.cliente_id = dadosPedido.razao;
  params.paramsProduct = {
    integracao_id: payload,
    preco,
    tabvnd_id,
    columns,
  };

  try {
    const url = `${process.env.VUE_APP_ECOMMERCE}${api.env.BUSCA_PRODUTOS_BONUS}`;
    const { data } = await dpcAxios.connection().post(url, params);
    context.commit("searchProductsBonus", data);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    // Bus.$emit("show-notification", [
    //   "error",
    //   "Desculpe.",
    //   `ERRO NA BUSCA DE DESCONTO`,
    // ]);
  }
}

const discounts = debounce((context) => checkOrderDiscounts(context));

export const setUsuarioInfo = (context, payload) => {
  context.commit("setUsuarioInfo", payload);
};

export const setUserShouldResetPassword = (context, payload) => {
  context.commit("setUserShouldResetPassword", payload);
};

export const setDadosPedido = (context, payload) => {
  context.commit("setDadosPedido", payload);
  if (state.isDpcMobileWebView) {
    // Post a message to the WebView to open the URL in the stack navigation
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "set_dadospedido",
          data: payload,
        })
      );
  }
};

export const addItemCarrinhoAtual = (context, payload) => {
  context.commit("addItemCarrinhoAtual", payload);
  discounts(context);
};

export const setCampanhaStatus = (context) => {
  discounts(context);
};

export const paymentData = (context, payload) => {
  context.commit("paymentData", payload);
};

export const removeStoredCard = (context, payload) => {
  context.commit("removeStoredCard", payload);
};

export const totalProduct = (context, payload) => {
  context.commit("totalProduct", payload);
};

export const faturamentoData = (context, payload) => {
  context.commit("faturamentoData", payload);
};

export const saveCards = (context, payload) => {
  context.commit("saveCards", payload);
};

export const savedCardSelected = (context, payload) => {
  context.commit("savedCardSelected", payload);
};

export const cvvCardSave = (context, payload) => {
  context.commit("cvvCardSave", payload);
};
///
export const globalAppLoading = (context, payload) => {
  context.commit("globalAppLoading", payload);
};

export const cardPaymentError = (context, payload) => {
  context.commit("cardPaymentError", payload);
};

export const setClienteAtual = (context, payload) => {
  context.commit("setClienteAtual", payload);
  localStorage.setItem("clienteAtual", payload);
};

export const searchDiscountProducts = (context, payload) => {
  context.commit("searchDiscountProducts", payload);
};

export const setShowLoadingModal = ({ commit }, payload) => {
  let id = payload[1];
  let value = payload[0];

  if (!id) id = 1;

  if (value) {
    commit("addLoadingModalId", id);
  } else {
    commit("removeLoadingModalId", id);
  }

  let isLoading;
  if (!value && !state.loadingModalIds.length) {
    commit("setShowLoadingModal", false);
    isLoading = false;
  } else {
    commit("setShowLoadingModal", true);
    isLoading = true;
  }

  if (state.isDpcMobileWebView) {
    // Post a message to the WebView to open the URL in the stack navigation
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "set_loading",
          loading: isLoading,
        })
      );
  }
};
