<template>
  <modal-error-placing-order title="Informações de pagamento incompletas">
    <div slot="content">
      Por favor, insira novamente as informações de pagamento.
    </div>
    <div slot="action">
      <router-link
        :to="{ name: 'checkoutPayment', query: { ...$route.query } }"
        tag="button"
        class="btn btn-primary"
      >
        Inserir informações de pagamento</router-link
      >
    </div>
  </modal-error-placing-order>
</template>

<script>
import ModalErrorPlacingOrder from "./ModalErrorPlacingOrder";

export default {
  name: "ModalPaymentNotSet",
  components: {
    ModalErrorPlacingOrder,
  },
};
</script>

<style lang="scss" scoped></style>
