<template>
  <!-- MODAL FILTROS -->
  <modal
    :show-modal="true"
    class="modal-razao"
    titulo="Selecionar razão"
    :show-footer="false"
    @close="close()"
  >
    <!-- MODAL FILTRO CONTENT -->
    <div slot="body">
      <div class="modal-container">
        <search-box
          class="modal-razao-list__search"
          placeholder="Digite para pesquisar"
          :search-term="searchTerm"
          @search="searchTerm = $event"
        >
        </search-box>

        <div class="modal-razao-list">
          <div
            v-for="rede in listagemRedesFiltered"
            class="modal-razao-list-item"
            :class="{
              'modal-razao-list-item__active': selectUnidade == rede.id,
            }"
            @click="handleRazaoSelected(rede.id)"
          >
            {{ mascaraCpfCnpj(rede.cpfcnpj) }} - {{ rede.nomerazao }} -
            {{ rede.cidade }} -
            {{ rede.uf }}
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <button class="btn btn-primary" @click="saveDadosPedidos()">
        Salvar Parâmetros
      </button>
    </div>
  </modal>
</template>

<script>
import { CoolSelect } from "vue-cool-select";
import modal from "@/components/root/modal/Modal";
import blackmodal from "@/components/root/black-modal/blackModal";
import { mascaraCpfCnpj } from "@/app/global";
import bus from "@/utils/events/bus";
import SearchBox from "@/components/searchbox/SearchBox.vue";

export default {
  name: "ModalParametros",
  components: { modal, blackmodal, CoolSelect, SearchBox },
  props: {
    listagemRedes: {
      type: Array,
      required: true,
    },
    selectUnidade: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      searchTerm: null,
    };
  },
  computed: {
    listagemRedesFiltered() {
      let listagem = this.listagemRedes.sort((a, b) => {
        if (a.id === this.selectUnidade && b.id !== this.selectUnidade) {
          return -1;
        }
        if (a.id !== this.selectUnidade && b.id === this.selectUnidade) {
          return 1;
        }
        if (a.id === this.selectUnidade && b.id === this.selectUnidade) {
          return a.nomerazao.localeCompare(b.nomerazao);
        }

        return a.nomerazao.localeCompare(b.nomerazao);
      });

      if (!this.searchTerm) return listagem;

      const searchTermNormalized = this.searchTerm
        .normalize("NFD")
        .toLocaleLowerCase();

      return listagem.filter((x) => {
        const nomerazaoNormalized = x.nomerazao
          .normalize("NFD")
          .toLocaleLowerCase();
        const cpfcnpjNormalized = x.cpfcnpj
          .normalize("NFD")
          .toLocaleLowerCase();

        const cidadeNormalized = x.cidade.normalize("NFD").toLocaleLowerCase();

        return (
          nomerazaoNormalized.includes(searchTermNormalized) ||
          cpfcnpjNormalized.includes(searchTermNormalized) ||
          cidadeNormalized.includes(searchTermNormalized)
        );
      });
    },
  },
  watch: {},
  created() {},
  methods: {
    mascaraCpfCnpj,
    handleRazaoSelected(redeId) {
      this.$emit("selectUnidade", redeId);
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.modal-razao {
  &-list__search {
    margin-bottom: 10px;
  }

  &-list {
    // overflow: auto;
    // max-height: calc(100vh - 510px);
  }

  &-list-item {
    padding: 12px;
    border: 1px solid gainsboro;
    border-radius: 6px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: box-shadow 0.3s, border-color 0.3s, color 0.3s;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &:focus,
    &__active {
      color: var(--brand-primary, $brand-primary);
      border-color: var(--brand-primary, $brand-primary);
    }
  }
}
// .modal-container {
//   display: flex;
//   flex-direction: column;
//   margin-top: 10px;
//   margin-bottom: 12.5px;

//   label {
//     margin: 5px 0;
//   }

//   select {
//     text-transform: uppercase;
//     box-shadow: none;
//     border-radius: 6px;

//     &:focus {
//       border-color: $brand-primary;
//     }
//   }
// }
</style>

<style lang="scss">
.black-modal {
  z-index: 1100 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
  }
}

.modal-razao {
  .modal-container.container {
    width: 100vw;
    max-width: 900px;
  }
  // position: absolute;
  // z-index: 1050;

  .error-message {
    text-align: center;
  }
}
</style>
