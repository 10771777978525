<template>
  <div class="order-complete">
    <h2 class="oc-title text-success">
      O SEU PEDIDO FOI REALIZADO COM SUCESSO!
    </h2>
    <h4 class="oc-desc">
      O número é:
      {{ dadosPedido.pedidoId ? `#${dadosPedido.pedidoId}` : "" }}
    </h4>

    <span
      >Para acompanhar a situação do seu pedido, acesse
      <router-link tag="a" :to="{ name: 'MinhaConta' }">Minha Conta</router-link
      >.</span
    >

    <template v-if="paymentMethod === PAYMENT_METHODS.AVISTA">
      <order-complete-boleto
        :pedido-id="dadosPedido.pedidoId"
      ></order-complete-boleto>
    </template>

    <br /><br />
    <!-- <div class="oc-footer">
      <router-link
        :to="{ name: 'MinhaContaPedidos' }"
        tag="button"
        class="btn btn-primary"
      >
        Meus pedidos
      </router-link>
    </div> -->
  </div>
</template>

<script>
import CartMixin from "@/app/checkout/cart/CartMixin";

import OrderCompleteBoleto from "./OrderCompleteBoleto";

export default {
  name: "OrderComplete",
  components: {
    OrderCompleteBoleto,
  },
  mixins: [CartMixin],
  props: {
    orderPlacedData: {
      required: true,
    },
  },
  mounted() {},
  beforeDestroy() {
    this.$store.dispatch("setDadosPedido", {});
  },
};
</script>

<style lang="scss" scoped>
.order-complete {
  display: flex;
  flex-direction: column;
  flex: 1;
  // align-items: center;
  // padding-top: 50px;

  .oc-icon {
    font-size: 60px;
  }

  .oc-title {
    // font-family: sans-serif;
    font-weight: bold;
    color: #1c944c;
  }

  .oc-desc {
    color: #585858;
    font-weight: bold;
  }

  .oc-footer {
  }
}
</style>
