<template>
  <div class="page-not-found">
    <img
      v-if="logoUrl"
      :src="logoUrl"
      class="img-logo-header img-responsive"
      :alt="appShortName"
      width="250"
    />
    <img
      v-else
      src="~@/assets/img/no-logo.svg"
      class="img-logo-header img-responsive"
      :alt="appShortName"
      width="250"
    />

    <br />
    <h2>Loja em manutenção</h2>
    <p style="text-align: center">
      Estamos em manutenção para melhorar sua experiência de compra. <br />
      Pedimos desculpas por qualquer inconveniência. Voltaremos em breve.
    </p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  metaInfo: {
    title: "Manutenção",
    titleTemplate: "Manutenção",
    meta: [],
  },
  components: {},
  data() {
    return {
      logoUrl: this.$store.getters.siteConfig.app_logo,
      appShortName: this.$store.getters.siteConfig.app_name_short,
      timer: null,
    };
  },
  mounted() {
    window.addEventListener("focus", this.startTimer);
    window.addEventListener("blur", this.stopTimer);
    this.startTimer();
  },
  beforeDestroy() {
    window.removeEventListener("focus", this.startTimer);
    window.removeEventListener("blur", this.stopTimer);
    this.stopTimer();
  },
  methods: {
    async fetchData() {
      try {
        const url = `${process.env.VUE_APP_APIB2B}/v1/maintenance/status`;
        const { data } = await dpcAxios.connection().get(url);
        if (!data.maintenance) {
          window.location.replace(this.$route.query.redirect);
        }
      } catch (e) {
        console.error(e);
      }
    },
    startTimer() {
      this.fetchData();

      this.timer = setInterval(() => {
        this.fetchData();
      }, 60000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.page-not-found {
  display: flex;
  padding: 30px 0;
  align-items: center;
  flex-direction: column;
  background: var(--brand-primary, $brand-primary) !important;
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
  margin-top: 0 !important;
  justify-content: center;
  min-height: 0 !important;
  color: white;
  z-index: 5000;

  // mobile
  @media (max-width: 800px) {
    padding: 0 15px;
    text-align: center;
  }

  img {
    padding: 15px;
  }
  .pnf-404 {
    font-size: 80px;
  }

  .pnf-btn {
    margin: 10px 0;
  }
}
</style>
