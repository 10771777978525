<template>
  <div
    v-if="
      !usuarioInfo.vendedor && showCardHelp && !carrinhoNaoPodeSerCompartilhado
    "
    class="flex flex-col justify-center items-center pr-8 pl-8 pt-4 pb-4 w-full"
  >
    <Transition name="slide-fade" mode="out-in" appear>
      <div
        v-if="showCardHelp"
        key="card-help"
        class="flex flex-row justify-between items-center w-full"
      >
        <div>
          <p class="p-0 m-0 text-gray font-bold">
            Precisa de ajuda com o pedido?
          </p>
          <p class="p-0 m-0 text-gray font-bold text-lg">
            Transfira o carrinho para um vendedor.
          </p>
        </div>
        <button
          class="btn btn-sm btn-primary"
          style="width: 20%; justify-content: center; padding: 5px 0px"
          @click="showSellersModal = true"
        >
          Transferir
        </button>
      </div>
    </Transition>
    <modal-sellers
      v-if="showSellersModal"
      :sellers="sellers"
      :show-allow-finish-order="permiteAlterarPermissaoFinalizarPedido"
      :is-loading="isLoading"
      @send-order-to-seller="sendCartToSeller($event)"
      @close="showSellersModal = false"
      @search-seller="searchSeller($event)"
    />
  </div>
</template>

<script>
import bus from "@/utils/events/bus";
import ModalSellers from "@/app/seller/components/ModalSellers.vue";

export default {
  name: "SellerHelpCard",
  components: {
    ModalSellers,
  },
  data() {
    return {
      showCardHelp: false,
      sellers: [],
      showSellersModal: false,
      isLoading: false,
    };
  },
  computed: {
    clienteId() {
      return this.$store.getters.clienteAtual;
    },
    dadosPedido() {
      return this.$store.getters.dadosPedido;
    },
    usuarioInfo() {
      return this.$store.getters.getUsuarioInfo;
    },
    userId() {
      const user = JSON.parse(localStorage.getItem("loggedUser") || "{}");
      return user.usuario_id || "";
    },
    permiteAlterarPermissaoFinalizarPedido() {
      return this.$store.getters.dadosPedido
        .permiteAlterarPermissaoFinalizarPedido;
    },
    carrinhoNaoPodeSerCompartilhado() {
      return this.$store.getters.dadosPedido.carrinhoNaoPodeSerCompartilhado;
    },
  },
  created() {
    if (!this.usuarioInfo.vendedor) {
      this.searchSeller("");
    }
  },
  methods: {
    toggleCardHelp() {
      this.showCardHelp = !this.showCardHelp;
    },
    async searchSeller(searchTerm) {
      try {
        const url = `${process.env.VUE_APP_ECOMMERCE}${api.env.BUSCA_VENDEDOR_CLIENTE}/${this.clienteId}/${searchTerm}`;
        const { data } = await dpcAxios.connection().get(url);
        if (data && data.error === 0) {
          this.sellers = data.vendedores.map((seller) => {
            seller.loading = false;
            return seller;
          });

          if (this.sellers.length > 0) {
            this.showCardHelp = true;
          }
        }
      } catch (error) {
        console.error(error);
        this.showCardHelp = false;
      }
    },

    async sendCartToSeller(dataSeller) {
      this.isLoading = true;
      try {
        const url = `${process.env.VUE_APP_ECOMMERCE}${api.env.ENVIAR_CARRINHO_VENDEDOR}/${this.dadosPedido.pedidoId}/enviar/${dataSeller.usuario_id}`;
        const { data } = await dpcAxios.connection().post(url, {
          permite_finalizar: dataSeller.permite_finalizar,
        });
        if (data.error === 0) {
          bus.$emit("show-notification", ["success", "Sucesso", data.mensagem]);
          this.showCardHelp = false;
          this.$store.dispatch("addItemCarrinhoAtual", "shouldCleanCart");
          this.$store.dispatch("setDadosPedido", {});
        } else if (data.error == 1 && data.code == 1) {
          bus.$emit("show-notification", [
            "error",
            "Função indisponível",
            data.mensagem,
          ]);
          this.showCardHelp = false;
        } else {
          bus.$emit("show-notification", ["error", "Erro", data.mensagem]);
          this.searchSeller("");
        }
        this.showSellersModal = false;
        this.isLoading = false;
      } catch (error) {
        bus.$emit("show-notification", ["error", "Erro", error]);
        this.showSellersModal = false;
        this.isLoading = false;
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/global.css";
@import "~@/assets/css/sass/bootstrap/variables";
.text-gray {
  color: $gray-dark;
}
.text-dpc {
  font-size: 15px;
  color: #545454;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}
.card-seller-list {
  max-height: 400px;
  overflow-y: auto;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
