<template>
  <div v-if="informacoes.length != ''" id="institucional">
    <section id="slider-destaque-empresa">
      <div class="container-fluid">
        <div class="row">
          <div>
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <h1 class="session-header">
                    <small>Sobre a</small>
                    <br />
                    <strong>DPC Distribuidor</strong>
                  </h1>
                </div>
              </div>
            </div>
            <img
              loading="lazy"
              :src="loadImage('empresa/point-bg.png', '0')"
              class="img-responsive"
              alt
            />
          </div>
        </div>
      </div>
    </section>
    <section id="sobre-empresa">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h3 class="session-header">Sobre a DPC</h3>
            <div class="text-justify sobre-dpc">
              {{ informacoes[0].sobre }}
            </div>
          </div>
          <div v-if="false" class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div>
              <section class="panel panel-view jumbotron-theme">
                <div class="container">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                      <div class="panel-body text-center">
                        <div class="video-placeholder-lg">
                          <div class="youtubeVideo">
                            <iframe
                              src="https://www.youtube.com/embed/ECw_rM7A5eM?rel=0&amp;controls=0&amp;showinfo=0"
                              frameborder="0"
                              allowfullscreen
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="
        informacoes[0].missao != null ||
        informacoes[0].visao != null ||
        informacoes[0].valores != null
      "
      id="missao-visao-valores"
    >
      <div class="container">
        <div class="row conteudo">
          <div v-if="informacoes[0].missao != null" class="col-md-4">
            <div class="item-content">
              <h3 class="session-header green-info">
                <i class="fa fa-shield" aria-hidden="true"></i> Missão
              </h3>
              {{ informacoes[0].missao }}
            </div>
          </div>
          <div v-if="informacoes[0].visao != null" class="col-md-4">
            <div class="item-content green-info">
              <h3 class="session-header green">
                <i class="fa fa-binoculars" aria-hidden="true"></i> Visão
              </h3>
              {{ informacoes[0].visao }}
            </div>
          </div>
          <div v-if="informacoes[0].valores != null" class="col-md-4">
            <div class="item-content">
              <h3 class="session-header green-info">
                <i class="fa fa-trophy" aria-hidden="true"></i> Valores
              </h3>
              {{ informacoes[0].valores }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <!-- <hr />
         <div class="row dpc-numeros-row">
          <div class="col-sm-6">
            <h2 class="session-header">DPC em números</h2>
            {{ informacoes[0].dpc_numeros_desc }}
          </div>
          <div class="col-sm-6">
            <div class="col-sm-6 col-md-6 grid-numeros">
              <div class="dpc-numeros-item">
                <img
                  loading="lazy"
                  :src="loadImage('empresa/company.png', '0')"
                  class="item-dpc-numeros"
                  alt=""
                />
                <h1 class="big-letter">
                  {{ informacoes[0].qtd_centros_distr }}
                </h1>
                <h4 class="small-letter">Centros de distribuição</h4>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 grid-numeros">
              <div class="dpc-numeros-item">
                <img
                  loading="lazy"
                  :src="loadImage('empresa/brazil.png', '0')"
                  class="item-dpc-numeros"
                  alt=""
                />
                <h1 class="big-letter">
                  +{{ informacoes[0].qtd_estados }}
                  <h3 class="big-letter">estados</h3>
                </h1>
                <h4 class="small-letter">atendidos</h4>
              </div>
            </div>
          </div>
        </div> -->
        <hr />
      </div>
    </section>
    <section>
      <div class="container">
        <div id="section-logistica" class="row">
          <div class="col-md-6">
            <SliderFull :sliders="slider_logistica"></SliderFull>
          </div>
          <div class="col-md-6 text-logistica">
            <h1 class="session-header">Logística</h1>
            {{ informacoes[0].logistica }}
          </div>
        </div>
      </div>
    </section>
    <section>
      <div
        v-show="informacoes[0].area_atuacao && informacoes[0].parcerias"
        class="container-fluid container-fluid-gray"
      >
        <div class="container">
          <div id="atuacao-parceiras" class="row">
            <div v-show="informacoes[0].area_atuacao" class="col-md-6">
              <h2 class="session-header">
                <i class="fa fa-briefcase" aria-hidden="true"></i> Área de
                atuação
              </h2>
              {{ informacoes[0].area_atuacao }}
            </div>
            <div v-show="informacoes[0].parcerias" class="col-md-6">
              <h2 class="session-header">
                <i class="fa fa-users" aria-hidden="true"></i> Parcerias
              </h2>
              {{ informacoes[0].parcerias }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SliderFull from "@/components/slider-full/SliderFull";

export default {
  name: "Institucional",
  components: { SliderFull },
  metaInfo: {
    title: "A empresa",
    // Meta tags
    meta: [
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "institucinal,sobre a empresa,quem somos,dpc,DPC distribuidor,Distribuidor atacadista,dpcnet,distribuidor,atacadista,distribuidora,atacado,cosméticos,cosmético,cosmetico,cosmeticos,minas gerais,mg,distribuidora",
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: "A empresa | DPC Distribuidor Atacadista",
      },
    ],
  },
  data() {
    return {
      json_slider_logistica: "",
      slider_logistica: [],
      informacoes: [
        {
          sobre: "",
          missao: "",
          visao: "",
          valores: "",
          logistica: "",
          area_atuacao: "",
          parcerias: "",
        },
      ],
    };
  },
  created() {
    this.slider_logistica = [
      JSON.parse(
        '{"row": [{"link":"./", "tipo_src":1, "src":"https://www.dpcnet.com.br/old/imagens_logistica/emp01"}, {"link":"./", "tipo_src":1, "src":"https://www.dpcnet.com.br/old/imagens_logistica/emp02"}, {"link":"./", "tipo_src":1, "src":"https://www.dpcnet.com.br/old/imagens_logistica/emp03"}, {"link":"./", "tipo_src":1, "src":"https://www.dpcnet.com.br/old/imagens_logistica/emp04"} ]}'
      ),
    ];
    this.buscaInformacoes();
    this.buscaBanners();
  },
  methods: {
    buscaInformacoes() {
      const that = this;
      dpcAxios
        .connection({ withCredentials: false })
        .get(process.env.VUE_APP_ENDERECO + api.env.INSTITUCIONAL)
        .then((response) => {
          this.informacoes = response.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    buscaBanners() {
      const that = this;
      dpcAxios
        .connection({ withCredentials: false })
        .get(
          `${
            process.env.VUE_APP_ENDERECO + api.env.VIEW_IMAGEM_BANNER
          }/institucional/BannersDestaque`
        )
        .then((response) => {
          that.json_slider_logistica = response.data;
          that.slider_logistica = that.buildBanner(that.json_slider_logistica);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    buildBanner(json) {
      if (typeof json === "undefined") return;
      if (json == undefined) return;
      if (json == "") return;

      const compBann = [];
      let banners = [];
      const tmpScreenAtual = this.screen_atual;

      $.each(json, (i, o) => {
        $.each(o, (i2, o2) => {
          banners = { row: [] };

          // PERCORRENDO OS TAMANHOS DE IMAGENS CADASTRADOS PARA ESTE BANNER.
          $.each(o2.banners, (i3, o3) => {
            let imgNomeD;
            let imgNomeT;
            let imgNomeM;
            let imgTamD;
            let imgTamT;
            let imgTamM;
            $.each(o3.imagens, (i4, o4) => {
              switch (o4.tipo) {
                case "D":
                  imgNomeD = o4.src;
                  imgTamD = o4.tamanho;
                  break;
                case "T":
                  imgNomeT = o4.src;
                  imgTamT = o4.tamanho;
                  break;
                case "M":
                  imgNomeM = o4.src;
                  imgTamM = o4.tamanho;
                  break;
                default:
                  break;
              }
            });

            // CASO NÃO TENHA UM TAMANHO DEFINIDO PARA TABLET PEGAMOS O TAMANHO DO DESKTOP.
            if (typeof imgTamT === "undefined") {
              imgTamT = imgTamD;
              imgNomeT = imgNomeD;
            }

            // CASO NÃO TENHA UM TAMANHO DEFINIDO PARA MOBILE PEGAMOS O TAMANHO DO DESKTOP.
            if (typeof imgTamM === "undefined") {
              imgTamM = imgTamD;
              imgNomeM = imgNomeD;
            }

            let imgCurrent = "";

            switch (tmpScreenAtual) {
              case "M":
                imgCurrent = imgNomeM;
                break;
              case "T":
                imgCurrent = imgNomeT;
                break;
              default:
                imgCurrent = imgNomeD;
                break;
            }

            // ADICIONANDO O BANNER AO ARRAY.
            banners.row.push({
              titulo: o3.titulo,
              tipo_src: 1,
              src: imgCurrent,
              col_xs: imgTamM,
              col_sm: imgTamT,
              col_md: imgTamD,
              h_xs: imgTamM == 0,
              h_sm: imgTamT == 0,
              h_md: imgTamD == 0,
              h_lg: imgTamD == 0,
            });
          });

          compBann.push(banners);
        });
      });

      return compBann;
    },

    // ESTE EVENTO É DISPARADO QUANDO À UMA MUDANÇA NO TAMANHO DA TELA.
    resize(event) {
      const width = $(window).width();
      if (width <= this.screen_xs) {
        this.screen_atual = "M";
      } else if (width <= this.screen_sm) {
        this.screen_atual = "T";
      } else {
        this.screen_atual = "D";
      }
    },
    loadImage(imgName, tipo) {
      if (tipo == 0) {
        return require(`@/assets/img/${imgName}`);
      }
      return imgName;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

/* VIDEO */

.panel {
  margin-bottom: 21px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-view {
  background: transparent;
  margin: 0;
  margin-left: -15px !important;
  overflow: hidden;
}

.panel.jumbotron-theme {
  margin-bottom: 0;
}

.panel.jumbotron-theme .panel-body {
  white-space: nowrap;
  overflow: auto;
}

.panel-body {
  padding: 0;
}

.video-placeholder-lg {
  width: 100%;
}

.youtubeVideo {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.youtubeVideo iframe,
.youtubeVideo object,
.youtubeVideo embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sobre-dpc {
  margin-bottom: 28px;
}

.session-header {
  color: $gray;
  margin-bottom: 15px;
  font-weight: 600;
  font-family: "Montserrat";
  //margin-top: 10px;

  &.green {
    color: var(--brand-primary, $brand-primary) !important;
  }

  &.green-info {
    color: $brand-info !important;
  }
}

.container-fluid-gray {
  background: #f9f9f9;
  margin-bottom: 20px;
  margin-top: 20px;
}

.img-grupo-lacerda {
  margin: auto;
  margin-bottom: 0px;
  width: 200px;
  padding: 10px;
  display: inline;
  margin-top: 40px;
}

#section-logistica,
#atuacao-parceiras {
  padding-top: 35px;
  padding-bottom: 35px;
}

#slider-destaque-empresa {
  margin-top: -45px !important; //usado para esconder o menu de categorias.
  //background-image: url("~@/assets/img/empresa/section-top-bg.png");
  background-position: right;
  background-repeat: no-repeat;
  min-height: 400px;
  padding-top: 100px;
  max-width: 1650px;
  margin: auto;

  h1 {
    color: #fff !important;

    small {
      color: #fff !important;
      font-size: 1em;
    }
  }
}

#sobre-empresa,
.grupo-lacerda {
  padding-top: 40px;
  padding-bottom: 45px;
}

#missao-visao-valores {
  text-align: left;
  padding-bottom: 35px;

  .conteudo {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .item-content {
    background: var(--brand-primary, $brand-primary);
    border: 01px solid transparent;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 10px;
    padding-bottom: 18px;
    color: #fff;
    //box-shadow: 0 0 27px rgba(170, 170, 170, 0.05);

    p {
      //min-height: 150px;
    }

    &.green-info {
      background: $brand-info !important;
      color: #666 !important;
    }
  }
}

.dpc-numeros-row {
  padding-top: 40px;
  padding-bottom: 45px;
}

.big-letter {
  color: var(--brand-primary, $brand-primary);
  margin-bottom: 0px;
  font-weight: 600;
  font-family: "Montserrat";
  margin-top: 0;
}

.small-letter {
  color: $gray;
  margin-bottom: 0px;
  font-weight: 400;
  font-family: "Montserrat";
  margin-top: 0;
}

.item-dpc-numeros {
  //float: left;
  width: 90px;
  padding-top: 10px;
  padding-bottom: 12px;
  padding-right: 13px;
}

.dpc-numeros-item {
  min-height: 232px;
  border: 01px solid #eee;
  padding-top: 15px;
  /* padding-bottom: 28px; */
  margin-bottom: 0px;
  //padding-left: 18px;
  border-radius: 2px;
  text-align: center;
}

.text-logistica {
  padding-top: 30px;
}

.grid-numeros {
  padding-left: 0;
  padding-right: 0px;
}

.item-content {
  p {
    //min-height: 100px;
  }
}
</style>
