<template>
  <div class="order-complete-boleto">
    <template v-if="!isBoletoReady">
      <div v-if="showProgressBar" class="ocb-waiting">
        <span>
          Estamos validando os itens do seu pedido e gerando o boleto para
          pagamento. <b>Pode demorar alguns minutos.</b> <br />Seu boleto também
          ficará disponível para download na área do cliente e será enviado para
          seu e-mail.
          <b>Não se preocupe, o seu pedido já foi recebido!</b>
        </span>

        <div class="progress">
          <div
            ref="boletoProgress"
            class="progress-bar bg-success progress-bar-striped progress-bar-animated"
            role="progressbar"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
      <div v-else class="ocb-waiting">
        <span
          >Seu boleto está sendo processado e logo que estiver disponível
          enviaremos para seu e-mail cadastrado.</span
        >
      </div>
    </template>

    <template v-if="isBoletoReady">
      <div class="ocb-code-area">
        <div v-if="hasQrCode" class="oca-qrcode">
          <h4 class="ocb-title">Pagar com PIX</h4>

          <div class="qrcode">
            <img
              v-if="qrCode.qrcodeImage"
              width="200"
              :src="`data:image/jpeg;base64,${qrCode.qrcodeImage}`"
              alt="QR Code PIX"
            />
            <div
              v-else
              style="width: 200px; height: 200px; background-color: #efefef"
            ></div>
          </div>

          <button
            class="btn btn-primary btn-sm"
            :disabled="!isBoletoReady"
            @click="copyQrContentToClipboard()"
          >
            <i v-if="!isBoletoReady" class="fa fa-spinner fa-spin"></i> COPIAR
            CÓDIGO PIX
          </button>
        </div>

        <div class="oca-barcode">
          <h4 class="ocb-title">Dados do boleto bancário</h4>
          <div
            v-if="barcodeImage"
            class="barcode__svg"
            v-html="barcodeImage"
          ></div>

          <input
            v-model="barcode"
            v-mask="'#####.##### #####.###### #####.###### # ##############'"
            class="ocb-barcode"
            readonly
            @click="$event.target.select()"
          />

          <span class="ocb-copy-code" @click="copyToClipboard()"
            >Copiar linha digitável</span
          >
          <br /><br />
          <small
            v-if="hintDesc"
            style="padding-bottom: 5px; color: #000000; font-weight: 600"
            >{{ hintDesc }}</small
          >
          <button
            class="btn btn-primary"
            :disabled="!isBoletoReady || loadingDownload"
            @click="downloadBoletoPdf()"
          >
            <i
              v-if="!isBoletoReady || loadingDownload"
              class="fa fa-spinner fa-spin"
            ></i>
            <span>Imprimir boleto</span>
          </button>
        </div>
      </div>

      <hr />
      <small v-if="hasQrCode"
        >Para realizar o pagamento via Pix, faça a leitura do QRCode no
        aplicativo do seu banco.</small
      >
    </template>
  </div>
</template>

<script>
import download from "downloadjs";
import Boleto from "boleto.js";
import bus from "@/utils/events/bus";

export default {
  name: "OrderCompleteBoleto",
  props: {
    pedidoId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      barcodeImage: null,
      barcode: null,
      currentProgress: 0,
      timeout: null,
      loadingDownload: false,
      qrCode: {},
      barProgressTimeout: null,
      showProgressBar: true,
      hintDesc: null,
    };
  },
  computed: {
    isBoletoReady() {
      return !!this.barcode;
    },
    hasQrCode() {
      return this.qrCode && Object.keys(this.qrCode).length > 0;
    },
  },
  mounted() {
    this.moveProgress();
    this.checkIfBoletoIsReady();
    this.barProgressTimeout = setTimeout(() => {
      this.barProgressTimeLimit();
    }, 240000);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    async downloadBoletoPdf() {
      this.loadingDownload = true;
      try {
        const url = `${process.env.VUE_APP_ECOMMERCE}ec/pedido/${this.pedidoId}/boleto/download`;
        const { data } = await dpcAxios.connection().get(url);

        if (!data.error) {
          const linkSource = `data:application/pdf;base64,${data.pdf}`;
          download(
            linkSource,
            `Boleto-${this.pedidoId}` + ".pdf",
            "application/pdf"
          );
        } else {
          throw data.message;
        }
      } catch (e) {
        console.error(e);
        bus.$emit("show-notification", [
          "error",
          "Desculpe!",
          "Ocorreu um erro ao fazer o download do seu boleto. Por favor, tente novamente.",
        ]);
      }

      this.loadingDownload = false;
    },
    async checkIfBoletoIsReady() {
      clearTimeout(this.timeout);
      try {
        const url = `${process.env.VUE_APP_ECOMMERCE}ec/pedido/${this.pedidoId}/boleto`;

        const { data } = await dpcAxios.connection().get(url);
        if (!data.error) {
          if (data.barcode) {
            this.moveProgress(100, 10);
            this.barcode = data.barcode;

            try {
              this.barcodeImage = new Boleto(this.barcode).toSVG();
            } catch (e) {
              console.log(e);
            }
          }

          if (data.pix) {
            this.qrCode = data.pix;
          }

          this.hintDesc = data.hint_desc;
        } else {
          throw data.message;
        }
      } catch (e) {
        console.error(e);
      }

      if (!this.barcode) {
        this.timeout = setTimeout(() => {
          this.checkIfBoletoIsReady();
        }, 10000);
      }
    },
    barProgressTimeLimit() {
      clearTimeout(this.timeout);
      clearTimeout(this.barProgressTimeout);
      this.showProgressBar = null;
    },
    copyToClipboard() {
      var input = document.createElement("input");
      input.setAttribute("value", this.barcode);
      document.body.appendChild(input);
      input.select();
      var result = document.execCommand("copy");
      document.body.removeChild(input);

      bus.$emit("show-notification", [
        "success",
        "",
        "Código copiado para a area de transferencia",
      ]);
    },
    copyQrContentToClipboard() {
      var input = document.createElement("input");
      input.setAttribute("value", this.qrCode.qrcode);
      document.body.appendChild(input);
      input.select();
      var result = document.execCommand("copy");
      document.body.removeChild(input);

      bus.$emit("show-notification", [
        "success",
        "",
        "Código copiado para a area de transferencia",
      ]);
    },
    moveProgress(value = 65, speed = 550) {
      const elem = this.$refs.boletoProgress;
      if (!elem) return;
      const id = setInterval(frame, speed);

      const self = this;
      function frame() {
        if (self.currentProgress >= value) {
          clearInterval(id);
        } else {
          self.currentProgress++;
          elem.style.width = `${self.currentProgress}%`;
          speed /= 2;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.order-complete-boleto {
  color: #3a3a3a;
  //   padding: 20px 0;
  width: 100%;
  // max-width: 610px;
  display: flex;
  flex-direction: column;
  // flex: 1;
  flex: 0;
  justify-content: center;
  padding: 20px 0;

  .progress {
    border-radius: 5px;
    margin: 15px 0;
  }

  .ocb-title {
    font-weight: 700;
    // padding-bottom: 7.5px;
  }
  .ocb-barcode {
    font-weight: bold;
    font-size: 15px;
    padding-bottom: 5px;
    width: 100%;
    border: none;
    background: transparent;
  }

  .ocb-code-area {
    padding: 10px 0;
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .oca-qrcode {
      display: flex;
      flex-direction: column;
      flex: 0 1 auto;
      padding-bottom: 15px;
      margin: 0 30px;

      .qrcode {
        padding: 10px 0px;
        // align-self: center;
      }
    }

    .oca-barcode {
      flex: 1 1 420px;
      padding: 20px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #eeeeee;
      border-radius: 5px;
      border: 1px solid #e5e5e5;
      max-width: 790px;
    }
  }

  .ocb-waiting {
    align-self: center;
    font-size: 15px;
    font-family: sans-serif;
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #eeeeee;
    border: 1px solid #e5e5e5;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .ocb-copy-code {
    cursor: pointer;
    color: var(--brand-primary, $brand-primary);
    font-weight: bold;
    font-size: 14px;

    &:hover {
      color: var(--brand-accent, $brand-accent);
    }
  }

  .ocb-footer {
    margin: 25px 0;

    button {
      width: 100%;
    }
  }
}
</style>
