import Vue from "vue";

import VueRouter from "vue-router";
import routes from "./routes";
import beforeEach from "./beforeEach";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  linkActiveClass: "active",
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    // if (to.name != from.name) {
    if (savedPosition) {
      return savedPosition;
    } 
      return { x: 0, y: 0 };
    
    // }
  },
});

router.beforeEach(beforeEach);

// const router = new VueRouter({
//     hashbang: false,
//     routes,
//     history: true,
//     mode: 'history',
//     linkActiveClass: 'active',
//     transitionOnLoad: true,
//     root: process.env.BASE_URL,
//     base: process.env.BASE_URL,
//     stringifyQuery: false
//  });

export default router;
