<template>
  <div class="box-sub-categorias">
    <div class="row">
      <div class="col-md-12">
        <h4>
          <strong>
            <a @click="categoriaClick(categoria.integracao_id)">{{
              categoria.descricao
            }}</a>
          </strong>
        </h4>
        <hr style="margin-top: 15px; margin-bottom: 15px" />
        <ul class="sub-categorias">
          <li
            v-for="sub in subCategorias"
            :key="sub.integracao_id"
            @click="subCategoriaClick(sub.integracao_id, tipoSubCategoria)"
          >
            <span>{{ sub.descricao }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BoxSubCategorias",
  props: {
    categoria: {
      type: Object,
      default: [],
    },
    subCategorias: {
      type: Array,
    },
    tipoSubCategoria: {},
  },
  methods: {
    categoriaClick(cod_categoria) {
      this.$router.push({
        name: "catalogoRota",
        query: { categoria: cod_categoria },
      });
      this.$emit("closeAllCategories");
    },
    subCategoriaClick(cod_sub_categoria, tipo) {
      if (tipo == "CAT") {
        this.$router.push({
          name: "catalogoRota",
          query: { categoria: cod_sub_categoria },
        });
        this.$emit("closeAllCategories");
      } else {
        this.$router.push({
          name: "catalogoRota",
          query: {
            categoria: this.categoria.integracao_id,
            sub: cod_sub_categoria,
          },
        });
        this.$emit("closeAllCategories");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.box-sub-categorias {
  padding: 15px;

  img {
    width: 100%;
  }
}

ul {
  margin-top: 10px;
  list-style-type: none;
  column-count: 3;
  column-fill: auto;
  padding-bottom: 18px;
}

.sub-categorias {
  padding-left: 0px;
  //overflow-y: scroll;
  overflow-x: hidden;
  height: 360px;

  li {
    padding: 5px 0px;
    width: 160px;
  }

  a,
  span {
    color: #666;
    cursor: pointer;

    &:hover {
      color: var(--brand-primary, $brand-primary); //text-decoration: underline;
    }
  }
}

@media (max-width: 991px) {
  ul {
    column-count: 1;
    column-fill: auto;
  }

  .box-sub-categorias {
    hr {
      margin-bottom: 0 !important;
    }

    h4 {
      padding-left: 15px;
    }
  }

  ul.sub-categorias {
    margin-top: 0;
    margin-bottom: 10px;
    height: auto;

    li {
      padding: 15px 10px 15px 15px;
      border-bottom: 1px solid #eee;
      width: 100%;
    }
  }

  .banner-destaque {
    padding: 0;

    img {
      width: 100%;
      margin-top: 15px;
    }
  }
}
</style>
