import { routes as home } from "./home";
import { routes as catalogo } from "./catalogo";
import { routes as produto } from "./produto";
import { routes as institucional } from "./institucional";
import { routes as politica } from "./politica-de-privacidade";
import { routes as politicaFuselo } from "./politica-de-privacidade-fuselo";
import { routes as politicaIson } from "./politica-de-privacidade-ison";
import { routes as politicaAres } from "./politica-de-privacidade-ares";
import { routes as politicaExpedicao } from "./politica-de-privacidade-expedicao";
import { routes as atendimento } from "./atendimentoNew";
import { routes as trabalheConosco } from "./trabalhe-conosco-novo";
import { routes as paginaCliente } from "./pagina-cliente";
import { routes as pedidoEletronico } from "./pedido-eletronico";
import { routes as checkout } from "./checkout";
import { routes as notFound } from "./404";
import { routes as maintenance } from "./maintenance";
import { routes as campanha } from "./campanhas";
import { routes as seller } from "./seller";

const redirecionamentos = [
  {
    path: "/produto",
    redirect: (to) => {
      try {
        const array = to.query.codigo.split("-");
        return { path: `/produto/${array[0]}`, params: { codigo: array[0] } };
      } catch (e) {}
    },
  },
  {
    path: "/old/produto/:produto",
    redirect: (to) => {
      const array = to.params.produto.split("-");
      return { path: "/produto", query: { codigo: array[0] } };
    },
  },

  {
    path: "*",
    redirect: (to) => ({ path: "404", params: {} }),
  },
];

export default [
  ...home,
  ...catalogo,
  ...checkout,
  ...produto,
  ...institucional,
  ...politica,
  ...politicaFuselo,
  ...politicaIson,
  ...politicaAres,
  ...politicaExpedicao,
  ...atendimento,
  ...trabalheConosco,
  ...redirecionamentos,
  ...paginaCliente,
  ...pedidoEletronico,
  ...notFound,
  ...campanha,
  ...seller,
  ...maintenance,
];
