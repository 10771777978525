<template>
  <div>
    <modal
      :show-modal="true"
      class="modal-error-placing-order"
      :show-header="false"
      :show-footer="false"
    >
      <div slot="body">
        <span v-if="title" class="mpns-title">{{ title }}</span>

        <p class="mpns-message">
          <slot name="content"></slot>
        </p>

        <slot name="action"></slot>
      </div>
    </modal>
  </div>
</template>

<script>
import modal from "@/components/root/modal/Modal";
import bus from "@/utils/events/bus";

export default {
  name: "ModalErrorPlacingOrder",
  components: { modal },
  props: {
    title: {
      required: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.modal-error-placing-order {
  .mpns-title {
    font-weight: bold;
    color: #1b1b1b;
    font-size: 22px;
  }

  .mpns-message {
    padding: 20px 0;
  }

  .modal-container {
    max-width: 800px;
    .modal-body {
      padding: 30px;
    }
  }
}
</style>

