<template>
  <div class="accordion-grp-atv">
    <div>
      <div class="accordion-grp-atv-rules">
        <!-- <span class="description">
          Você ganha desconto nestes produtos se completar os combos acima
        </span> -->
        <h4 v-if="campanha.active && campanha.grupos_bonus[0].tpdesconto === 2">
          Completando os combos acima, e comprando os produtos abaixo, você
          ganha o valor dos itens abaixo como desconto nos produtos do combo.
        </h4>
        <h4 v-else-if="campanha.active && campanha.grupos_bonus[0].tpdesconto === 3">
          Completando os combos acima, você ganhará os seguintes itens como bonificação.
        </h4>
        <h4 v-else-if="campanha.active">
          Completando os combos acima, você ganha desconto nos seguintes itens.
        </h4>
        <!-- <span>Você pode ganhar até 16% de desconto!</span> -->
      </div>
      <div v-if="!loadingProdutos" class="produtos-bonus-slider">
        <SliderCampanha :produtos="produtosBonusContent"></SliderCampanha>
        <router-link
          class="btn btn-primary"
          tag="button"
          :to="'/catalogo/?campanhaId=' + campanha.id"
        >
          Ver todos os Produtos
        </router-link>
      </div>
      <!-- <div v-else>
        <center><img src="@/assets/img/loading/loading.gif" alt="" /></center>
      </div> -->
    </div>
  </div>
</template>

<script>
import SliderCampanha from "@/components/combo-campanhas/SliderCampanha";
import bus from "@/utils/events/bus";

export default {
  name: "ProdutosBonusCard",
  components: { SliderCampanha },
  props: {
    campanha: {},
  },
  data() {
    return {
      loadingProdutos: true,
      produtosBonusContent: [],
    };
  },
  watch: {
    loadingProdutos(newValue) {
      this.$store.dispatch("setShowLoadingModal", [
        newValue,
        "ProdutosBonuscard",
      ]);
    },
  },
  created() {
    this.getProdutosBonificao();
  },
  methods: {
    getParamsRequestProduto() {},
    async requestProdutos(arrayIdProdutos) {
      // MOVER ISSO PRA CIMA
      const { dadosPedido } = this.$store.getters;
      const preco = {
        pedido_id: dadosPedido.pedidoId,
        cliente_id: dadosPedido.razao,
        tabvnd_id: dadosPedido.tabvnd,
      };

      const tabvnd_id = dadosPedido.tabvnd;
      const hascasadinha = {
        empresa: {
          id: dadosPedido.unidade,
        },
        cliente: {
          id: dadosPedido.razao,
        },
      };
      const hasprodbloq = {
        cliente: {
          id: dadosPedido.razao,
        },
      };
      const columns = { hascasadinha, hasprodbloq };
      try {
        const response = await dpcAxios
          .connection()
          .post(process.env.VUE_APP_ECOMMERCE + api.env.VIEW_PRODUTO, {
            integracao_id: arrayIdProdutos,
            preco,
            tabvnd_id,
            isCatalog: false,
            columns,
          });
        const retorno = response.data.produtos.map((produto) =>
          this.trataProduto(produto)
        );
        return retorno;
      } catch (err) {
        console.log(err);
      }
    },
    trataProduto(produto) {
      const produtoTratado = {};
      produtoTratado.descricao = produto.descricao;
      produtoTratado.descricaodetalhada = produto.descricaodetalhada;
      produtoTratado.dun14 = produto.dun14;
      produtoTratado.ean = produto.ean;
      produtoTratado.embqtddun14 = produto.embqtddun14;
      produtoTratado.embtpdun14 = produto.embtpdun14;
      produtoTratado.embqtdean = produto.embqtdean;
      produtoTratado.fornecedor_id = produto.fornecedor_id;
      produtoTratado.hascasadinha = produto.hascasadinha;
      produtoTratado.hasprodbloq = produto.hasprodbloq;
      produtoTratado.hascondbloq = produto.hascondbloq;
      produtoTratado.cod_produto = produto.id;
      produtoTratado.src = produto.src;
      produtoTratado.possuiestoque = produto.possuiestoque;
      produtoTratado.integracao_id = produto.integracao_id;
      produtoTratado.preco_all_tabs = produto.preco_all_tabs;
      produtoTratado.vlracresc = produto.vlracresc;
      produtoTratado.vlrdesconto = produto.vlrdesc;
      produtoTratado.vlrstdesonerado = produto.vlrstdesonerado;
      produtoTratado.valor = produto.vlrproduto;
      produtoTratado.vlrst = produto.vlrst;
      produtoTratado.vlrbruto =
        produto.vlrproduto + produto.vlrst - produto.vlrdesc;
      produtoTratado.embeantext = produto.embeantext;
      produtoTratado.decim = produto.decim;
        produtoTratado.decim_num_casas = produto.decim_num_casas;

      if (this.$store.getters.carrinhoAtual.length) {
        const itemAdicionado = this.$store.getters.carrinhoAtual.find(
          (p) => p.cod_produto === produto.id
        );

        produtoTratado.qtd = itemAdicionado ? itemAdicionado.qtd : 0;
      } else {
        produtoTratado.qtd = 0;
      }
      return produtoTratado;
    },
    async getProdutosBonificao() {
      if (this.produtosBonusContent.length > 0) {
        return;
      }

      this.loadingProdutosBonus = true;

      const arrayProdutos = [];
      this.campanha.grupos_bonus.forEach((produtos, index) => {
        produtos.produtos_bonus.forEach((element) => {
          if (element.integracao_id) {
            if (arrayProdutos.length <= 100) {
              arrayProdutos.push(element.integracao_id);
            }
          }
        });
      });

      const produtosBonusContentAux = await this.requestProdutos(arrayProdutos);

      if (
        produtosBonusContentAux.length % 2 != 0 &&
        produtosBonusContentAux.length > 1
      ) {
        produtosBonusContentAux.pop();
      }

      this.produtosBonusContent = produtosBonusContentAux;

      this.loadingProdutos = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-grp-atv {
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 15px;
  border-radius: 6px;
  overflow: hidden;
}
.accordion-grp-atv-rules {
  margin-top: 35px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  h4 {
    margin: 0;
    font-weight: bold;
    color: #303932;
  }
  span {
    color: #979797;
  }
}
.produtos-bonus-slider {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;

  button.btn-primary {
    margin: 10px 0;
  }
}
</style>
