<template>
  <div class="information" :class="{error: isError, warn: isWarn}">
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: "Information",
  props: {
    message: {
      required: true,
      type: String,
    },
    messageType: {
      required: true,
      type: String,
    },
  },
  computed: {
    isError() {
      return this.messageType === "error";
    },
    isWarn() {
      return this.messageType === "warn";
    },
  },
};
</script>

<style lang="scss" scoped>
.information {
  font-size: 13px;
  font-weight: 600;
  padding-bottom: 14px;
  -ms-flex-item-align: center;
  align-self: center;
  width: 100%;
  padding: 7.5px;
  margin-bottom: 20px;

  &.error {
    color: #8c0801;
    background: #fdd1d1;
  }

  &.warn {
    color: #694000;
    background: #fde6d1;
  }
}
</style>
