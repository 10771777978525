<template>
  <div class="container-combo-stage">
    <div v-if="bonusData.length == 1" class="comboStage">
      <div class="combo">
        <div
          class="promo"
          :class="
            bonusData[0].isActive
              ? 'active-promo-border'
              : 'inactive-promo-border'
          "
        >
          <div
            class="promo-body"
            :class="bonusData[0].isActive ? 'active-promo-bg' : 'promo-bg'"
          >
            <template v-if="bonusData[0]?.percent">
              <span style="font-size: 15px; text-align: center">
                {{ bonusData[0].displayDiscount }}
              </span>
              <span>OFF</span>
            </template>
            <template v-else>
              <i class="mdi mdi-sale fa-2x"></i>
            </template>
          </div>
        </div>
        <span class="desc">{{ bonusData[0].qtdCombos || 0 }} combo(s)</span>
      </div>
    </div>
    <div v-else class="squareContent">
      <div
        v-for="(stage, index) in bonusData"
        :key="index"
        class="squareStage"
        :class="stage.isActive ? 'active' : ''"
      >
        <template v-if="stage.percent">
          <span class="discount">{{ stage.displayDiscount }}</span>
        </template>
        <template v-else>
          <i class="mdi mdi-sale fa-2x"></i>
        </template>
        <span class="desc">{{ stage.qtdCombos || 0 }} combo(s)</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ComboStage",
  props: {
    data: {
      type: Array,
      default: [],
    },
    campanha: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      bonus: [],
    };
  },
  computed: {
    bonusData() {
      return this.bonus;
    },
    campanhaStatus() {
      return this.$store.getters.campanhaStatus;
    },
  },
  watch: {
    campanhaStatus(val) {
      this.getBonusData();
    },
  },
  created() {
    this.getBonusData();
  },

  methods: {
    getBonusData() {
      const campanhaId = this.campanha.toString();

      let bonusAux = [];

      if (
        this.campanhaStatus[campanhaId] &&
        this.campanhaStatus[campanhaId].bonusAtivos
      ) {
        bonusAux = this.campanhaStatus[campanhaId].bonusAtivos;

        bonusAux.sort((a, b) =>
          a.percent < b.percent ? -1 : a.percent > b.percent ? 1 : 0
        );
      }

      this.bonus = bonusAux;
    },
  },
};
</script>
<style lang="scss" scoped>
.active {
  hr {
    border-color: #e5e5e5 !important;
  }
  .comboStage {
    border-color: #e5e5e5;
    div {
      color: white;
      background: #e5e5e5;
    }
  }
}

.comboStage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}
.combo .desc {
  font-weight: 600;
  font-size: 14px;
  color: #979797;
}
.row-body {
  width: 100px;
  display: flex;
  margin-top: -20px;
}
.row-stage {
  border-bottom: 2px dashed #e5e5e5;
}
.active-row-stage {
  border-bottom: 2px dashed #1ba256;
}
.container-combo-stage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.combo {
  display: flex;
  flex-direction: column;
  align-items: center;
  .promo {
    margin: 5px 0px;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-weight: 900;

    padding: 5px;
    .promo-body {
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .promo-bg {
    background-color: #e5e5e51f;
    color: #e5e5e5;
  }
  .inactive-promo-border {
    border: 2px dashed #e5e5e5;
  }
  .active-promo-border {
    border: 2px dashed #1ba256;
  }
  .active-promo-bg {
    background-color: #1ba2561f;
    color: #1ba256;
  }
}

.squareContent {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  .squareStage {
    background: #e5e5e5;
    color: white;
    padding: 3px 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .discount {
      font-weight: bold;
    }

    .desc {
      font-weight: 500;
    }

    &.active {
      background: #1ba256;
    }
  }
}

@media (max-width: 568px) {
  .squareContent {
    font-size: 14px;
  }
  .row-body {
    display: none;
  }
}
</style>
