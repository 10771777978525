<template>
    <div id="loading-banner">
        <div class="container">
            <div class="skeleton">
                <div class="skeleton-wrapper">
                    <div class="skeleton-wrapper-inner">
                        <div class="skeleton-wrapper-body">
                            <div class="skeleton-slider-banner"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'LoadingBanner',
}

</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

#loading-banner {
    margin-top: 20px;
    margin-bottom: 30px;
}

.skeleton-wrapper {
  background: #fff;
  //border: 1px solid;
  //border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  //margin: 10px 15px;
}

.skeleton-wrapper-inner {
  height: 400px;
  padding: 80px;
  position: relative;
}

.skeleton-wrapper-body div {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderSkeleton;
  -webkit-animation-timing-function: linear;
  background: #f6f7f8;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#f6f7f8),
    color-stop(0.2, #edeef1),
    color-stop(0.4, #f6f7f8),
    to(#f6f7f8)
  );
  background-image: -webkit-linear-gradient(
    left,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: auto;
  height: auto;
  position: relative;
  border-radius: 6px;
}
.skeleton-wrapper-body {
  -webkit-animation-name: skeletonAnimate;
  background-image: -webkit-gradient(
    linear,
    center top,
    center bottom,
    from(deg),
    color-stop(0, red),
    color-stop(0.15, orange),
    color-stop(0.3, yellow),
    color-stop(0.45, green),
    color-stop(0.6, blue),
    color-stop(0.75, indigo),
    color-stop(0.8, violet),
    to(red)
  );
  background-image: -webkit-linear-gradient(
    135deg,
    red 0%,
    orange 15%,
    yellow 30%,
    green 45%,
    blue 60%,
    indigo 75%,
    violet 80%,
    red 100%
  );
  background-repeat: repeat;
  background-size: 50% auto;
}
.skeleton-wrapper-body div {
  position: absolute;
  right: 15px;
  left: 15px;
  top: 15px;
}
div.skeleton-slider-banner {
  top: 0px;
  height: 100%;
  left: 0px;
  right: 0px;
  bottom: -10px;
}
@-webkit-keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 1200px;
  }
}
@-webkit-keyframes skeletonAnimate {
  from {
    background-position: top left;
  }
  to {
    background-position: top right;
  }
}
</style>