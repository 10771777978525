<template>
  <!-- MODAL FILTROS -->
  <modal
    :show-modal="true"
    class="modal-client-info"
    titulo="Enviar Carrinho"
    :show-footer="true"
    @close="close()"
  >
    <!-- MODAL FILTRO CONTENT -->
    <div slot="body">
      <div
        class="modal-client-info-list-item modal-client-info-list-item__active"
      >
        <span>{{ dadosPedido.informacoes.nomerazao }}</span>
        <div v-if="showAllowFinishOrder" class="finish-order__toggle">
          <span>Permite Finalizar</span>
          <toggle-button
            v-model="permiteFinalizar"
            sync
            :width="35"
            :height="18"
            :color="primaryColor"
            :margin="0"
          />
        </div>
      </div>
    </div>
    <div slot="footer">
      <button class="btn btn-primary" @click="sendOrderToCustomer()">
        <i v-if="isLoading" class="fa fa-spinner fa-spin"></i>
        Enviar Carrinho
      </button>
    </div>
  </modal>
</template>

<script>
import modal from "@/components/root/modal/Modal";
import blackmodal from "@/components/root/black-modal/blackModal";
import SearchBox from "@/components/searchbox/SearchBox.vue";

export default {
  name: "ModalClienteInfo",
  components: { modal, blackmodal, SearchBox },
  props: {
    sellers: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showAllowFinishOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: null,
      seletedSeller: null,
      permiteFinalizar: false,
      primaryColor: this.$store.getters.siteConfig.app_color_brand_primary,
    };
  },
  computed: {
    dadosPedido() {
      return this.$store.getters.dadosPedido;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    sendOrderToCustomer() {
      if (this.isLoading) return;
      this.$emit("send-order-to-customer", {
        pedido_id: this.dadosPedido.pedidoId,
        permite_finalizar: this.showAllowFinishOrder
          ? this.permiteFinalizar
          : true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-client-info {
  &-list__search {
    margin-bottom: 10px;
  }

  .finish-order__toggle {
    display: flex;
    align-items: center;
    justify-content: space-center;
    span {
      margin-right: 10px;
    }
    label {
      margin: 0;
    }
  }

  &-list-item {
    padding: 12px;
    border: 1px solid gainsboro;
    border-radius: 6px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: box-shadow 0.3s, border-color 0.3s, color 0.3s;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover,
    &:focus,
    &__active {
      color: #2b6a4b;
      border-color: #2b6a4b;
    }
  }
}
</style>

<style lang="scss">
.black-modal {
  z-index: 1100 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
  }
}

.modal-client-info {
  .modal-container.container {
    width: 100vw;
    max-width: 900px;
  }
  // position: absolute;
  // z-index: 1050;

  .error-message {
    text-align: center;
  }
}
</style>
