<template>
  <section v-show="!isCarrinho">
    <div v-show="hasPedidoAberto" class="toolbar-pedido">
      <div class="container">
        <div class="row">
          <div class="col-md-7">
            <div class="col-md-4 ped-dados-div ped-numero">
              <p>
                <label>Número Pedido</label>
              </p>
              <p></p>
              <h5>{{ dadosPedido.pedidoId }}</h5>
            </div>
            <div class="col-md-2 ped-dados-div ped-tot-bruto">
              <div class="ped-dados-div-group">
                <p>
                  <label>Total Bruto</label>
                </p>
                <p></p>
                <h5>R$ {{ valorBruto }}</h5>
              </div>
            </div>
            <div class="col-md-2 ped-dados-div ped-tot-st">
              <div class="ped-dados-div-group">
                <p>
                  <label>Total ICMS/ST</label>
                </p>
                <p></p>
                <h5>R$ {{ valorSt }}</h5>
              </div>
            </div>
            <div class="col-md-2 ped-dados-div ped-tot-desconto">
              <div class="ped-dados-div-group">
                <p>
                  <label>Total Desconto</label>
                </p>
                <p></p>
                <h5>R$ {{ valorDesconto }}</h5>
              </div>
            </div>
            <div class="col-md-2 ped-dados-div ped-tot-liquido">
              <div class="ped-dados-div-group">
                <p>
                  <label>Total Líquido</label>
                </p>
                <p></p>
                <h5>R$ {{ valorLiquido }}</h5>
              </div>
            </div>
          </div>
          <div class="col-md-2 ped-opt-div ped-btn-veritens">
            <button class="btn btn-link" @click="openModalParametro">
              <i class="fa fa-info"></i> Alterar dados
              <small>(F4)</small>
            </button>
          </div>
          <div class="col-md-2 ped-opt-div ped-btn-veritens">
            <button class="btn btn-link" @click="openModalCarrinho">
              <i class="fa fa-truck"></i> Ver itens
              <small>(F3)</small>
            </button>
          </div>
          <div class="col-md-2 ped-opt-div ped-btn-finalizar">
            <button class="btn btn-primary" @click="finalizarPedidoClick()">
              <i class="fa fa-check"></i> Finalizar pedido
            </button>
          </div>
        </div>
      </div>
    </div>
    <ModalParametros
      v-if="showModalParametro"
      :show-modal="showModalParametro"
      @close="closeModalParametro"
    ></ModalParametros>

    <ModalCarrinho
      v-if="showModalCarrinho && hasPedidoAberto"
      :show-modal="showModalCarrinho"
      @close="closeModalCarrinho"
    ></ModalCarrinho>
  </section>
</template>

<script>
import ModalParametros from "@/components/root/modal/ModalParametrosCompra";
import ModalCarrinho from "@/components/root/modal/ModalCarrinho";
import bus from "@/utils/events/bus";
import { mascaraValor } from "@/app/global";

export default {
  name: "ToolbarPedido",
  components: { ModalParametros, ModalCarrinho },
  props: {},
  data() {
    return {
      showModalParametro: false,
      showModalCarrinho: false,
      loggedIn: false,
      hasPedidoAberto: false,
      totalLiquido: 0,
      totalDesconto: 0,
      totalSt: 0,
      isCarrinho: false,
      dadosPedido: {},

      keyHandlerEsc(e) {
        bus.$emit("keydown", e);
      },
    };
  },
  computed: {
    valorBruto() {
      return mascaraValor(
        this.totalLiquido + this.totalSt - this.totalDesconto
      );
    },
    valorLiquido() {
      return mascaraValor(this.totalLiquido);
    },
    valorSt() {
      return mascaraValor(this.totalSt);
    },
    valorDesconto() {
      return mascaraValor(this.totalDesconto);
    },
  },
  watch: {
    $route(to, from) {
      this.isCarrinho = to.name === "carrinhoRota";
    },
  },
  mounted() {
    const that = this;

    // Verifica quando o usuário seleciona parametros do pedido para iniciar novo pedido
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "setDadosPedido":
          this.hasPedidoAberto = Object.keys(state.dadosPedido).length !== 0;

          this.dadosPedido = state.dadosPedido;
          break;

        case "addItemCarrinhoAtual":
          this.updateCartInfo();
          break;
      }
    });

    // bus.$on("openModalParametros", (e) => {
    //   this.openModalParametro();
    // });

    // Evento pra emitir tecla precionada
    document.addEventListener("keydown", this.keyHandlerEsc);

    bus.$on("keydown", (e) => {
      this.atalhosDeTecla(e);
    });
  },
  created() {
    this.isCarrinho = this.$route.name === "carrinhoRota";
    if (!Account.isAuthenticated()) {
      this.loggedIn = false;
    } else {
      this.loggedIn = true;
    }
  },
  methods: {
    openModalParametro() {
      if (this.loggedIn) {
        this.showModalParametro = true;
      } else {
        return;
      }
    },

    closeModalParametro() {
      this.showModalParametro = false;
    },

    openModalCarrinho() {
      if (this.loggedIn && this.hasPedidoAberto) {
        this.showModalCarrinho = true;
      } else {
        return;
      }
    },

    closeModalCarrinho() {
      this.showModalCarrinho = false;
    },

    finalizarPedidoClick() {
      this.$router.push({
        name: "carrinhoRota",
        query: {},
      });
    },

    updateCartInfo() {
      const cart = this.$store.getters.carrinhoAtual;
      //   if (cart.length) return;

      const valorLiquido = cart.reduce(
        (prevVal, product) => prevVal + product.valor * product.qtd,
        0
      );

      const valorDesconto = cart.reduce(
        (prevVal, product) => prevVal + product.vlrdesconto * product.qtd,
        0
      );

      const valorSt = cart.reduce(
        (prevVal, product) => prevVal + product.vlrst * product.qtd,
        0
      );

      this.totalLiquido = valorLiquido;
      this.totalDesconto = valorDesconto;
      this.totalSt = valorSt;
    },

    async atalhosDeTecla(e) {
      var that = this;

      // NOVO
      if (e.keyCode == 115) {
        e.preventDefault();

        if (!that.showModalParametro) {
          this.openModalParametro();
          return false;
        }

        return false;
      }

      if (e.keyCode == 114) {
        e.preventDefault();

        if (!that.showModalCarrinho) {
          this.openModalCarrinho();
          return false;
        }

        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

.toolbar-pedido {
  z-index: 1025;
  padding: 30px 0px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 1px 6px rgba(0, 0, 0, 0.38);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 10px 10px;
  border: #b3b3b3 1px solid;

  button {
    margin-top: 2px;
  }
}

.container {
  width: 100%;
  max-width: 1500px;
}

.ped-dados-div {
  text-align: center;

  .ped-dados-div-group {
    text-align: right;
  }

  label {
    font-size: 12px;
  }
  p {
    margin: 0px;
  }
  h5 {
    margin: 0px;
  }
}

.ped-opt-div {
  width: auto;
  padding-left: 3px;
  padding-right: 3px;
}
</style>
