<template>
  <div class="headContentDetails">
    <!-- <img v-if="1" class="banner" :src="bannerSrc" /> -->
    <div class="content" :class="true ? 'noBanner' : 'specsContent'">
      <Timeline :campanha="campanha" />
      <CardDiscount :campanha="campanha" />
    </div>
  </div>
</template>

<script>
import Timeline from "../Timeline/Timeline.vue";
import CardDiscount from "../CardDiscount/CardDiscount.vue";

export default {
  name: "CardBannerHeader",
  components: {
    Timeline,
    CardDiscount,
  },
  props: {
    campanha: {},
  },

  data() {
    return {
      bannerSrc: "",
    };
  },
  mounted() {
    this.handleWindowSize();
  },
  created() {
    window.addEventListener("resize", this.handleWindowSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowSize);
  },
  methods: {
    handleWindowSize() {
      const windowWidth = window.innerWidth;
      return;
      if (windowWidth >= 1280) {
        this.bannerSrc =
          "https://cdn.discordapp.com/attachments/880774404177555496/928986606503862323/Acao_Pedido_Premiado_-_Desktop.png";
      } else if (windowWidth < 1280 && windowWidth > 928) {
        this.bannerSrc =
          "https://cdn.discordapp.com/attachments/880774404177555496/928986606503862323/Acao_Pedido_Premiado_-_Desktop.png";
      } else {
        this.bannerSrc =
          "https://cdn.discordapp.com/attachments/880774404177555496/928986606810067004/Acao_Pedido_Premiado_-_Mobile.png";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.headContentDetails {
  .banner {
    width: 100%;
    border-radius: 6px;
    margin-bottom: 15px;
    height: 440px;
  }

  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .content {
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    height: 440px;
    justify-content: center;
    & > div {
      border: solid 1px #e5e5e5;
    }
  }

  .specsContent {
    position: absolute;
  }
  .noBanner {
    position: initial;
  }
}
.mobileStyle {
  margin-bottom: 260px;

  .specsContent {
    transform: translateY(255px);
  }
}

@media (max-width: 928px) {
  .headContentDetails {
    align-items: center !important;
    .specsContent {
      height: 308px;
      position: relative;
      top: -30px;
    }
  }
}

@media (max-width: 600px) {
  .headContentDetails {
    .specsContent {
      .timelineContainer,
      .discountContent {
        width: 100%;
      }
      top: -5px;
    }
    .banner {
      height: initial;
    }
  }
}
@media (max-width: 450px) {
  .headContentDetails {
    .specsContent {
      height: 340px;
      position: relative;
      top: -15px;
    }
  }
}

@media (max-width: 380px) {
  .headContentDetails {
    .specsContent {
      height: 385px;
      position: relative;
      top: -30px;
    }
  }
}

@media (max-width: 340px) {
  .headContentDetails {
    .specsContent {
      top: -10px;
    }
  }
}

@media (max-width: 300px) {
  .headContentDetails {
    .specsContent {
      top: 0px;
    }
  }
}
</style>
