<template>
  <div class="container_boleto">
    <div class="titulo_boleto">
      <h1>DADOS DE FATURAMENTO</h1>
    </div>
    <div class="dados_faturamento-box">
      <div class="razao_social">
        <div>
          <h3>RAZÃO SOCIAL</h3>
          <p>{{ faturamentoData.nome }}</p>
        </div>
        <div>
           <h3>CNPJ</h3>
          <p>{{ faturamentoData.cpfcnpj }}</p>
        </div>
      </div>
      <div>
          <h3>CONDIÇÃO</h3>
          <p>{{ dadosPedido.informacoes.condicaoPagamento }}</p>
        </div>
      <!--    <div>
          <h3>VENCIMENTO</h3>
          <ol>
            <li>10/09/2020</li>
            <li>10/10/2020</li>
            <li>10/11/2020</li>
          </ol>
        </div>  -->
    </div>
  </div>
</template>

<script>
export default {
  name: "SaveCard",
  data() {
    return {
    };
  },
  computed:{
    faturamentoData() {
      return this.$store.getters.faturamentoData;
    },
    dadosPedido() {
      return this.$store.getters.dadosPedido;
    },
  },
  methods:{
  }

};
</script>

<style scoped>
.container_boleto {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.titulo_boleto {
  margin-top: 20px;
  margin-bottom: 20px;
}
.titulo_boleto h1{
  all: unset;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 152.5%;
  /* identical to box height, or 26px */
  letter-spacing: 0.05em;
  color: #303932;
}

.dados_faturamento-box h3{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #303932;
}

.dados_faturamento-box p{
 font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 152.5%;
  /* or 18px */

  display: flex;
  align-items: center;
  letter-spacing: 0.05em;

  color: #979797;

}
@media(max-width:1000px){
  .razao_social {
  display: flex;
  width: 60%;
  justify-content: space-between;
}
}

@media(max-width:700px){
  .razao_social {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
}

</style>
