<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    id="good-table"
    ref="myGoodTable"
    class="good-table"
    :class="{ rtl: rtl }"
  >
    <!-- <div class="loading" v-show="loading">
      <div class="content-loading">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        <span class="sr-only">Loading...</span>
      </div>
    </div>-->

    <div v-if="title || $slots['table-actions']" class="table-header clearfix">
      <h2 class="table-title pull-left">{{ title }}</h2>
      <div class="actions pull-right">
        <slot name="table-actions"></slot>
      </div>
    </div>
    <div
      v-if="
        (paginateOnTop && showPagination) ||
        (globalSearch && externalSearchQuery == null) ||
        enablefilter ||
        enabledownloadfile ||
        enablecols
      "
      :class="['container-top-bar', { 'custom-csv-style': isCSV }]"
    >
      <div
        v-if="(globalSearch && externalSearchQuery == null) || isCSV"
        class="section-busca col-md-6 col-sm-5 col-xs-6 no-padding-left"
        :class="{
          'filter-active': enablefilter,
          'download-active': enabledownloadfile,
          'cols-active': enablecols,
          'csvSearch no-padding-right': isCSV,
        }"
      >
        <!-- <div
          v-if="isCSV"
          title="Defina a quantidade de linhas por página."
          data-toggle-table="tooltip"
          data-html="true"
          data-placement="left"
        >
          <select
            v-model="currentPerPage"
            @change="emitRowsFilter"
            class="form-control"
          >
            <option value="10" selected>10 linhas</option>
            <option value="50">50 linhas</option>
            <option value="100">100 linhas</option>
          </select>
        </div> -->
        <div
          v-if="isCSV"
          title="Filtre os produtos da tabela."
          data-toggle-table="tooltip"
          data-html="true"
          data-placement="left"
        >
          <select v-model="filter" @change="emitFilter" class="form-control">
            <option value="all" selected>Todos</option>
            <option value="found">Encontrados</option>
            <option value="not_found">Não Encontrados</option>
          </select>
        </div>
        <input
          id="InputVueGooDTable"
          ref="InputVueGooDTable"
          v-model="globalSearchTerm"
          type="text"
          class="form-control input-sm"
          :placeholder="globalSearchPlaceholder"
          :disabled="disabledSearch"
          @keyup.enter="searchForRequest()"
        />
        <button
          class="btn btn-default btn-seach"
          :disabled="disabledSearch"
          data-toggle-table="tooltip"
          data-html="true"
          data-placement="left"
          title="Buscar"
          @click="searchForRequest()"
        >
          <!-- <i class="fa fa-search" aria-hidden="true"></i> -->
          <i class="mdi mdi-magnify"></i>
        </button>
      </div>

      <div v-if="$slots.headerIcons" class="headerIconSlot">
        <slot name="headerIcons"></slot>
      </div>
      <div
        class="col col-md-4 col-sm-4 col-xs-4 no-padding-right right"
        :class="{ 'no-padding-left': isCSV }"
      >
        <div class="right">
          <slot name="headerIconsRight"></slot>
        </div>
        <div v-if="enablefilter" class="filter right">
          <button
            class="btn btn-default btn-filtro"
            data-toggle-table="tooltip"
            data-html="true"
            data-placement="left"
            title="Filtro"
            @click="clickFilter()"
          >
            <i class="mdi mdi-filter-outline fa-2x" aria-hidden="true"></i>
          </button>
        </div>

        <div v-if="enabledownloadfile" class="btn-group right btn-download">
          <button
            type="button"
            class="btn btn-default btn-sm dropdown-toggle btn-gerar-arquivo"
            :disabled="!enabledownloadfile || rows.length == 0"
            :class="{ disabled: !enabledownloadfile || rows.length == 0 }"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-toggle-table="tooltip"
            data-html="true"
            data-placement="bottom"
            title="Faça o Download desta tabela."
            @click="showModalDownload = true"
          >
            <i class="fa fa-download" aria-hidden="true"></i>
          </button>
        </div>
        <div v-show="enablecols" class="right">
          <button
            class="btn btn-default btn-colunas"
            :disabled="rows.length <= 0"
            data-toggle-table="tooltip"
            data-html="true"
            data-placement="bottom"
            title="Colunas para exibição"
            @click="showModalColunas = true"
          >
            <i class="fa fa-columns" aria-hidden="true"></i>
          </button>
        </div>

        <div
          v-if="paginateOnTop && showPagination"
          class="right"
          style="display: inline-flex"
        >
          <span class="page-info" style="white-space: nowrap">{{
            paginationInfo.quantidade
          }}</span>
          <button
            class="btn btn-default btn-colunas"
            :disabled="!paginationInfo.prevIsPossible"
            data-toggle-table="tooltip"
            data-html="true"
            data-placement="bottom"
            title="Página Anterior"
            @click="changePage('prev')"
          >
            <i class="mdi mdi-chevron-left btn-paginate" aria-hidden="true"></i>
          </button>
          <button
            class="btn btn-default btn-colunas"
            :disabled="!paginationInfo.nextIsPossible"
            data-toggle-table="tooltip"
            data-html="true"
            data-placement="bottom"
            title="Próxima Página"
            @click="changePage('next')"
          >
            <i
              class="mdi mdi-chevron-right btn-paginate"
              aria-hidden="true"
            ></i>
          </button>
        </div>
      </div>
    </div>
    <!-- <vue-good-pagination
      :loading="loading"
      :changeTablePage="changeTablePage"
      @infoPagination="infoPagination($event)"
      :disableShowMore="disableShowMore"
      :showPagination="showPagination"
      :backHome="backHome"
      v-if="paginate && paginateOnTop"
      :qtdRowSelected="rowsSelected"
      :showQtdRowSelected="showQtdRowSelected"
      :perPage="currentPerPage"
      :rtl="rtl"
      :total="lenghtRow"
      @page-changed="pageChanged"
      @show-more="showMore"
      @per-page-changed="perPageChanged"
      :nextText="nextText"
      :prevText="prevText"
      :rowsPerPageText="rowsPerPageText"
      :rowsSelectedText="rowsSelectedText"
      :customRowsPerPageDropdown="customRowsPerPageDropdown"
      :ofText="ofText"
      :allText="allText"
      :showAllConlumnsTable="showAllConlumnsTable"
    ></vue-good-pagination>-->

    <div
      v-if="loading"
      id="loadingTable"
      :style="rows.length == 0 ? 'position: relative;' : ''"
    >
      <div class="lds-roller content-loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div
      v-dragscroll="dragscroll"
      :class="{
        responsive: responsive,
        'responsive-no-scroll': !scrollable,
        'scroll-table': scrollable,
      }"
    >
      <table
        ref="table"
        :class="tableStyleClasses"
        tabindex="-1"
        @keydown.40="pageDown"
        @keydown.38="pageUp"
        @keydown.13="pageDown"
        @keydown.exact="teclaPressionadaTable"
      >
        <thead :style="{ display: paginated.length ? '' : 'none' }">
          <tr v-if="globalSearch && externalSearchQuery == null"></tr>
          <tr>
            <th v-if="lineNumbers" class="line-numbers"></th>
            <th v-if="enableCheckbox">
              <span>
                <!-- <label class="checkbox">
									<input type="checkbox" @click="toggleSelectAll()">
                </label>-->
              </span>
            </th>
            <th
              v-for="(column, index) in columns"
              v-if="!column.hidden"
              :key="index"
              :class="getHeaderClasses(column, index)"
              :style="{ width: column.width ? column.width : 'auto' }"
              @click="sort(index)"
            >
              <slot name="table-column" :column="column">
                <div
                  v-if="column.tooltip != '' && column.tooltip != null"
                  style="display: inline"
                  data-toggle-table="tooltip"
                  data-html="true"
                  :data-placement="getTooltipSide(index)"
                  :title="column.tooltip"
                >
                  <span>{{ column.label }}</span>
                </div>
                <span v-else>{{ column.label }}</span>
              </slot>
            </th>
            <slot name="thead-tr"></slot>
          </tr>
          <tr v-if="hasFilterRow">
            <th v-if="lineNumbers"></th>
            <th
              v-for="(column, index) in columns"
              v-if="!column.hidden"
              :key="index"
            >
              <div
                v-if="column.filterable"
                :class="getHeaderClasses(column, index)"
              >
                <input
                  v-if="!column.filterDropdown"
                  type="text"
                  :class="{ 'input-condensed': filterCondensed }"
                  :placeholder="getPlaceholder(column)"
                  :value="columnFilters[column.field]"
                  @input="updateFilters(column, $event.target.value)"
                />

                <!-- options are a list of primitives -->
                <select
                  v-if="
                    column.filterDropdown &&
                    typeof column.filterOptions[0] !== 'object'
                  "
                  class
                  :value="columnFilters[column.field]"
                  @input="updateFilters(column, $event.target.value)"
                >
                  <option key="-1" value>{{ getPlaceholder(column) }}</option>
                  <option
                    v-for="(option, i) in column.filterOptions"
                    :key="i"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>

                <!-- options are a list of objects with text and value -->
                <select
                  v-if="
                    column.filterDropdown &&
                    typeof column.filterOptions[0] === 'object'
                  "
                  class
                  :value="columnFilters[column.field]"
                  @input="updateFilters(column, $event.target.value)"
                >
                  <option key="-1" value>{{ getPlaceholder(column) }}</option>
                  <option
                    v-for="(option, i) in column.filterOptions"
                    :key="i"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(row, index) in paginated"
            :key="index"
            :class="[
              getRowStyleClass(row),
              {
                editing: row.editMode,
                'row-selected': row.rowSelected,
                disabled: row.disabled,
                selected: row.selected && !disableRowSelected,
              },
            ]"
          >
            <th v-if="lineNumbers" class="line-numbers">
              {{ getCurrentIndex(index) }}
            </th>
            <td v-if="enableCheckbox" class="td-checkbox">
              <label class="checkbox">
                <input
                  v-model="row.selected"
                  type="checkbox"
                  @click="itemCheck(row, index, $event)"
                />
              </label>
            </td>
            <slot name="table-row-before" :row="row" :index="index"></slot>

            <td
              v-for="(column, i) in columns"
              v-if="!column.hidden && column.field"
              :key="i"
              :class="[
                getClasses(i, 'td'),
                row.tdClass != undefined && row.tdClass.field == column.field
                  ? row.tdClass.class
                  : '',
              ]"
              @mouseup="verificaClick(row, index, $event)"
            >
              <slot
                name="table-row"
                :row="row"
                :column="column"
                :formatted-row="formattedRow(row)"
                :index="index"
              >
                <span v-if="!column.html" :title="row.title">{{
                  collectFormatted(row, column)
                }}</span>
                <span
                  v-if="column.html"
                  :title="row.title"
                  v-html="collect(row, column.field)"
                ></span>
              </slot>
            </td>

            <slot name="table-row-after" :row="row" :index="index"></slot>
          </tr>
          <!-- <tr
            v-if="showFooterTable && paginated.length > 0"
            class="table-footer"
          >
            <slot name="table-footer">
              <td
                v-for="(column, i) in columns"
                v-if="!column.hidden && column.field"
              >
                <span
                  v-if="column.footer != undefined && column.footer != null"
                  :class="
                    column.footer != undefined &&
                    column.footer.class != undefined
                      ? column.footer.class
                      : ''
                  "
                  >{{ calculateOperator(column) }}</span
                >
              </td>
            </slot>
          </tr> -->
          <tr v-if="paginated.length === 0 && !loading">
            <td :colspan="columns.length">
              <slot name="emptystate">
                <div class="center-align text-disabled">
                  <i
                    class="fa fa-exclamation-circle fa-3x"
                    aria-hidden="true"
                  ></i>
                  <br />Nenhuma informação.
                </div>
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <vue-good-pagination
      v-show="paginate && !paginateOnTop"
      :change-table-page="changePG"
      :loading="loading"
      :disable-show-more="disableShowMore"
      :show-pagination="showPagination"
      :style="{ display: paginated.length ? '' : 'none' }"
      :qtd-row-selected="rowsSelected"
      :show-qtd-row-selected="showQtdRowSelected"
      :back-home="backHome"
      :per-page="currentPerPage"
      :rtl="rtl"
      :paginate-on-top="paginateOnTop"
      :total="lenghtRow"
      :next-text="nextText"
      :prev-text="prevText"
      :rows-per-page-text="rowsPerPageText"
      :rows-selected-text="rowsSelectedText"
      :custom-rows-per-page-dropdown="customRowsPerPageDropdown"
      :of-text="ofText"
      :all-text="allText"
      :show-all-conlumns-table="showAllConlumnsTable"
      @infoPagination="infoPagination($event)"
      @page-changed="pageChanged"
      @show-more="showMore"
      @per-page-changed="perPageChanged"
    ></vue-good-pagination>
    <!-- MODAL COLUNAS -->
    <modal
      v-if="showModalColunas"
      :show-modal="showModalColunas"
      class="modal-colunas-exibicao-table"
      :titulo="'Colunas para exibição'"
      @close="showModalColunas = false"
    >
      <div slot="body">
        <div class="row">
          <div class="col-sm-12">
            <div
              :key="keySelectColunas"
              class="row dual-select margin-bottom-section"
            >
              <div
                class="col-md-5 dual-select-container"
                data-area="unselected"
              >
                <h5>
                  <span>
                    <i class="fa fa-eye-slash" aria-hidden="true"></i> Ocultas
                  </span>
                  <span class="badge badge-pill count">{{
                    countColunasInativas
                  }}</span>
                </h5>
                <!-- <input type="text" placeholder="Buscar" class="form-control"> {{ colunasInvisiveisSelected }} -->
                <select
                  v-model="colunasInvisiveisSelected"
                  multiple="true"
                  class="form-control"
                  style="height: 250px"
                >
                  <option
                    v-for="(item, index) in columns"
                    v-if="item.hidden == true && item.label != ''"
                    :key="index"
                    :value="index"
                    @dblclick="onClickOptionColunas(item, index)"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </div>
              <div
                class="col-md-2 center-block control-buttons"
                style="position: relative; margin-top: 3%"
              >
                <button
                  type="button"
                  class="btn btn-default col-md-8 col-md-offset-2 ats"
                  @click="allColunasVisivel()"
                >
                  <span class="glyphicon glyphicon-fast-forward"></span>
                </button>
                <button
                  type="button"
                  class="btn btn-default col-md-8 col-md-offset-2 uts"
                  :disabled="colunasInvisiveisSelected <= 0"
                  @click="ColunasParaVisivel()"
                >
                  <span class="glyphicon glyphicon-step-forward"></span>
                </button>
                <button
                  type="button"
                  class="btn btn-default col-md-8 col-md-offset-2 stu"
                  :disabled="colunasVisivelSelected <= 0"
                  @click="ColunasParaInvisivel()"
                >
                  <span class="glyphicon glyphicon-step-backward"></span>
                </button>
                <button
                  type="button"
                  class="btn btn-default col-md-8 col-md-offset-2 atu"
                  @click="allColunasInvisivel()"
                >
                  <span class="glyphicon glyphicon-fast-backward"></span>
                </button>
              </div>
              <div class="col-md-5 dual-select-container" data-area="selected">
                <h5>
                  <span>
                    <i class="fa fa-eye" aria-hidden="true"></i> Visíveis
                  </span>
                  <span class="badge badge-pill count">{{
                    countColunasAtivas
                  }}</span>
                </h5>
                <!-- <input type="text" placeholder="Buscar" class="form-control"> {{ colunasVisivelSelected }} -->
                <select
                  v-model="colunasVisivelSelected"
                  multiple="true"
                  class="form-control"
                  style="height: 250px"
                >
                  <option
                    v-for="(item, index) in columns"
                    v-if="
                      (item.hidden == undefined || item.hidden == false) &&
                      item.label != ''
                    "
                    :key="index"
                    :value="index"
                    @dblclick="onClickOptionColunas(item, index)"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer"></div>
    </modal>
    <!-- MODAL DE DOWNLOAD DE ARQUIVOS -->
    <modal
      v-if="showModalDownload"
      id="modal-download-table"
      :show-modal="showModalDownload"
      :titulo="'Donwload da Tabela'"
      @close="showModalDownload = false"
    >
      <div slot="body">
        <div class="row">
          <div class="col-sm-12">
            <label>Nome</label>
            <input
              v-model="nameFile"
              class="form-control input-sm"
              type="text"
            />
          </div>
          <div class="col-sm-12">
            <label>Formato</label>
            <div class="margin-bottom-section">
              <button
                type="button"
                class="btn btn-default btn-sm btn-tipo"
                @click="generateFile('csv')"
              >
                <i class="fa fa-table" aria-hidden="true"></i> .csv
              </button>
              <button
                type="button"
                class="btn btn-default btn-sm btn-tipo"
                @click="generateFile('xlsx')"
              >
                <i class="fa fa-file-excel-o" aria-hidden="true"></i> .xlsx
              </button>
              <button
                type="button"
                class="btn btn-default btn-sm btn-tipo"
                @click="generateFile('txt')"
              >
                <i class="fa fa-file-text-o" aria-hidden="true"></i> .txt
              </button>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer"></div>
    </modal>
  </div>
</template>

<script>
// import each from "lodash/fp/forEach";

import VueGoodPagination from "./Pagination.vue";
import defaultType from "./types/default";
import bus from "@/utils/events/bus";

import * as jsonExport from "@/JsonExport";
import modal from "@/components/root/modal/Modal";

let dataTypes = {};
// let coreDataTypes = require.context("./types", false, /^\.\/([\w-_]+)\.js$/);
// each(coreDataTypes.keys(), (key) => {
//   let compName = key.replace(/^\.\//, "").replace(/\.js/, "");
//   dataTypes[compName] = coreDataTypes(key).default;
// });

export default {
  name: "VueGoodTable",
  components: {
    VueGoodPagination,

    modal,
  },
  props: {
    enableToggleRow: {
      default: true,
      type: Boolean,
    },
    isEditingRow: {
      default: false,
      type: Boolean,
    },
    customRowsPerPageDropdown: {
      default() {
        return [];
      },
      type: Array,
    },
    styleClass: { default: "table table-bordered", type: String },
    title: { default: "", type: String },
    columns: {
      default: null,
      type: Array,
    },
    uncheckAllItens: {
      default: "",
      type: String | Boolean,
    },
    checkAllItens: {
      default: "",
      type: String | Boolean,
    },
    changeTablePage: {
      default: "",
      type: String | Boolean,
    },
    loadingShowMore: { default: false, type: Boolean },
    showPagination: { default: true, type: Boolean },
    rows: {
      default: null,
      type: Array,
    },
    enablefilter: { default: false, type: Boolean },
    isCSV: { default: false, type: Boolean },
    enabledownloadfile: { default: false, type: Boolean },
    enablecols: { default: false, type: Boolean },
    lenghtRow: { default: 0, type: Number | String },
    onClick: { default: null, type: Function },
    perPage: { default: 10, type: Number },
    sortable: { default: false, type: Boolean },
    paginate: { default: true, type: Boolean },
    showQtdRowSelected: { default: false, type: Boolean },
    paginateOnTop: { default: false, type: Boolean },
    lineNumbers: { default: false, type: Boolean },
    defaultSortBy: { default: null, type: Object },
    responsive: { default: true, type: Boolean },
    smallRows: { default: false, type: Boolean },
    scrollable: { default: false, type: Boolean },
    rtl: { default: false, type: Boolean },
    rowStyleClass: { default: null, type: [Function, String] },
    loading: { default: false, type: Boolean },
    disableRowSelected: { default: false, type: Boolean },
    enableCheckbox: { default: false, type: Boolean },

    // search
    filterCondensed: { default: false, type: Boolean },
    globalSearch: { default: false, type: Boolean },
    searchTrigger: { default: null, type: String, required: false },
    externalSearchQuery: { default: null, type: String },
    globalSearchFn: { type: Function, default: null, required: false },
    disabledSearch: { default: false, type: Boolean },
    sortLocal: { default: false, type: Boolean },
    defaultSearch: { default: "", type: String, required: false },
    disableAutoFocoInput: { default: true, type: Boolean },
    // text options
    globalSearchPlaceholder: { default: "Buscar", type: String },

    nextText: { default: "Prox", type: String },
    prevText: { default: "Ant", type: String },
    rowsPerPageText: { default: "Por Pág:", type: String },
    rowsSelectedText: { default: "Selecionado(s)", type: String },
    ofText: { default: "de", type: String },
    allText: { default: "Todas", type: String },

    nameFile: { default: "Dados", type: String },

    /** variaveis para controlar a exibicao de culunas */
    showColumnsTable: {
      default() {
        return [];
      },
      type: Array,
    },
    showAllConlumnsTable: { default: true, type: Boolean },
    openModalColunas: { default: false, type: Boolean },
    openModalDownload: { default: false, type: Boolean },
    showFooterTable: { default: false, type: Boolean },
    typeCalculateFooterTable: { default: "show-rows", type: String },
    itemSelecionadoInicial: { default: 1, type: Number },
  },

  data: () => ({
    changingPage: null,
    paginationInfo: [],
    rowChecked: [],
    lastItemChecked: -1,
    itemSelecionado: -1,
    canScroll: false,
    disableShowMore: false,
    showModalColunas: false,
    showModalDownload: false,
    currentPage: 1,
    currentPerPage: 10,
    sortColumn: -1,
    sortType: "asc",
    globalSearchTerm: "",
    searchTerm: "",
    jsonFields: {},
    columnFilters: {},
    filteredRows: [],
    timer: null,
    forceSearch: false,
    sortChanged: false,
    dataTypes: dataTypes || {},
    buscarDados: false,
    offset: 0,
    limit: 10,
    backHome: false,
    clearArray: false,
    fileName: "",
    typeFile: "",
    dragscroll: false,
    colunas_selecionadas: [],
    showSelectColunas: false,
    clickCount: 0,
    id: null,
    allSelected: false,
    itensMarcados: [],
    CopyColunas: [],
    keySelectColunas: null,
    colunasVisivelSelected: [],
    colunasInvisiveisSelected: [],
    myItemSelecionadoInicial: -1,
    filter: "all",
  }),

  computed: {
    // Muda de página
    changePG: {
      get() {
        var retorno = this.changeTablePage;

        if (retorno == null) {
          retorno = this.changingPage;
        }

        return retorno;
      },
      set(value) {
        this.changingPage = value;
      },
    },
    countColunasInativas() {
      var key = this.keySelectColunas;
      var count = 0;
      for (let index = 0; index < this.columns.length; index++) {
        if (this.columns[index].hidden) {
          count += 1;
        }
      }

      return count;
    },
    countColunasAtivas() {
      var key = this.keySelectColunas;
      var count = 0;
      for (let index = 0; index < this.columns.length; index++) {
        if (!this.columns[index].hidden) {
          count += 1;
        }
      }

      return count;
    },
    tableStyleClasses() {
      let classes = this.styleClass;
      classes += this.responsive ? " responsive" : "";
      classes += this.smallRows ? " small" : "";
      classes += this.scrollable ? "" : " responsive-no-scroll";
      return classes;
    },

    // to create a filter row, we need to
    // make sure that there is atleast 1 column
    // that requires filtering
    hasFilterRow() {
      if (!this.globalSearch) {
        for (var col of this.columns) {
          if (col.filterable) {
            return true;
          }
        }
      }
      return false;
    },

    rowsSelected() {
      var that = this;
      var rows = this.originalRows;
      var qtRows = 0;

      that.itensMarcados = [];

      $.each(rows, (index, value) => {
        if (value.selected != undefined && value.selected) {
          qtRows += 1;
          that.itensMarcados.push(value);
        }
      });

      this.$emit("itensCheck", that.itensMarcados);
      return qtRows;
    },

    paginated() {
      var paginatedRows = this.originalRows;
      var pageStart = 0;
      var pageEnd = 10;

      if (paginatedRows <= 0) {
        this.currentPage = 1;
        this.currentPerPage = this.currentPerPage;
        pageStart = 0;
        pageEnd = this.currentPage * this.currentPerPage;

        this.clearArray = true;
        this.offset = 0;
        this.limit = this.currentPerPage;
        this.backHome = true;
      }
      this.backHome = false;

      if (this.paginate) {
        /** se der error futuramente verificar o teste de search */
        if (
          this.currentPage == 1 &&
          this.searchTerm != null &&
          this.searchTerm != ""
        ) {
          pageStart = 0;
          this.currentPage = 1;
          this.offset = 0;
          this.limit = this.currentPerPage;
          this.backHome = true;
          this.clearArray = true;
        } else {
          pageStart = (this.currentPage - 1) * this.currentPerPage;
        }
        this.backHome = false;
        // in case of filtering we might be on a page that is
        // not relevant anymore
        // also, if setting to all, current page will not be valid

        if (this.currentPerPage === -1) {
          this.currentPage = 1;
          pageStart = 0;
          pageEnd = paginatedRows.length + 1;
        }

        // calculate page end now
        pageEnd = paginatedRows.length + 1;

        // if the setting is set to 'all'
        if (this.currentPerPage !== -1) {
          pageEnd = this.currentPage * this.currentPerPage;
        }

        paginatedRows = paginatedRows.slice(pageStart, pageEnd);
        // HEURISTICA PARA TIRAR O EFEITO DOS DADOS SUMIR AO MUDAR DE PAGINA.
        if (paginatedRows.length <= 0 && this.currentPerPage != -1) {
          paginatedRows = this.originalRows.slice(
            pageStart - this.currentPerPage,
            pageEnd - this.currentPerPage
          );
        }
      }
      return paginatedRows;
    },

    originalRows() {
      const rows = this.rows;

      if (this.sortLocal) {
        // ORDENAÇÃO
        if (
          this.sortColumn !== -1 &&
          this.isSortableColumn(this.sortColumn) &&
          // if search trigger is enter then we only sort
          // when enter is hit
          (this.searchTrigger !== "enter" || this.sortChanged)
        ) {
          this.sortChanged = false;

          this.rows = this.rows.sort((x, y) => {
            if (!this.columns[this.sortColumn]) return 0;

            let xvalue = this.collect(x, this.columns[this.sortColumn].field);
            let yvalue = this.collect(y, this.columns[this.sortColumn].field);

            // if user has provided a custom sort, use that instead of
            // built-in sort
            const sortFn = this.columns[this.sortColumn].sortFn;
            if (sortFn && typeof sortFn === "function") {
              return (
                sortFn(xvalue, yvalue, this.columns[this.sortColumn]) *
                (this.sortType === "desc" ? -1 : 1)
              );
            }

            // built in sort
            var typeDef = this.typedColumns[this.sortColumn].typeDef;
            return (
              typeDef.compare(xvalue, yvalue, this.columns[this.sortColumn]) *
              (this.sortType === "desc" ? -1 : 1)
            );
          });
        }
      }

      // we need to preserve the original index of rows so lets do that
      for (let index = 0; index < rows.length; index++) {
        rows[index].originalIndex = index;
      }

      return rows;
    },

    typedColumns() {
      const columns = Object.assign(this.columns, []);
      for (let column of columns) {
        column.typeDef = this.dataTypes[column.type] || defaultType;
      }
      return columns;
    },

    colunasMarcadas() {
      // const columns = Object.assign(this.columns, []);
      // //Esconde tudo
      // $.each(this.columns, function (index, value) {
      // 	if (value.label != '') {
      // 		value.hidden = true
      // 	} else {
      // 		value.hidden = false
      // 	}
      // });
      // //Se selecionado estiver vazio
      // if (this.colunas_selecionadas.length <= 0) {
      // 	for (var i = 0; i < this.columns.length; i++) {
      // 		this.columns[i].hidden = false
      // 		this.colunas_selecionadas.push(i);
      // 	}
      // } else {
      // 	if (this.colunas_selecionadas.length <= this.columns.length) {
      // 		for (var i = 0; i < this.colunas_selecionadas.length; i++) {
      // 			columns[this.colunas_selecionadas[i]].hidden = false
      // 		}
      // 	}
      // }
      // return columns;
    },
  },
  watch: {
    changeTablePage() {
      this.goTop();
    },
    currentPage() {
      this.lastItemChecked = -1;
      this.itemSelecionado = -1;
    },
    uncheckAllItens() {
      var self = this;

      this.uncheckAll(true);
      $.each(this.paginated, (index, value) => {
        $.each(self.itensMarcados, (index2, value2) => {
          if (
            value2 != undefined &&
            value.originalIndex == value2.originalIndex
          ) {
            self.itensMarcados.splice(index2, 1);
          }
        });
      });
    },

    checkAllItens() {
      var self = this;
      //  console.log(this.paginated);

      $.each(this.paginated, (index, value) => {
        if (!value.disabled) {
          value.selected = true;
          self.itensMarcados.push(value);
        }
      });

      //  console.log("check everyone from the current paage");
    },
    itemSelecionado() {},
    canScroll() {
      // console.log("focus changes");
      // console.log(this.canScroll);
    },
    lenghtRow(a, b) {
      if (a < b) {
        this.currentPage = 1;
      }
    },
    disableRowSelected() {
      if (this.disableRowSelected) {
        this.unselectedRows();
      }
    },
    itensMarcados() {
      var self = this;

      // $.each(self.itensMarcados, function(index, value) {
      //   if (value.disabled) {
      //     self.originalRows[value.originalIndex].selected = false;
      //     self.itensMarcados.splice(index, 1);
      //   }
      // });

      if (this.itensMarcados.length > 0) {
        /**  Unselected all disabled rows */
        this.unselectedRows();
      } else if (this.itensMarcados.length == 0) {
        this.$emit("selectedRow", -1);
      }

      this.emitRowChecked();
    },

    defaultSearch() {
      this.searchTerm = this.defaultSearch;
    },
    columns(col, oldCol) {
      for (let index = 0; index < this.columns.length; index++) {
        if (this.columns[index].hidden == undefined) {
          this.columns[index].hidden = false;
          this.colunas_selecionadas.push(index);
          this.$emit("columnSelected", this.colunas_selecionadas);
        }
      }

      if (col.length != oldCol.length) {
        for (let index = 0; index < this.columns.length; index++) {
          this.columns[index].hidden = false;
          this.colunas_selecionadas.push(index);
          this.$emit("columnSelected", this.colunas_selecionadas);
        }
      }

      // Reseta ordenação quando as colunas são alteradas
      if (this.defaultSortBy) {
        for (let index = 0; index < this.columns.length; index++) {
          let col = this.columns[index];
          if (col.field === this.defaultSortBy.field) {
            this.sortColumn = index;
            this.sortType = this.defaultSortBy.type || "asc";
            this.sortChanged = true;
            break;
          }
        }
      }
      this.generateFieldsReport();

      // Marca Colunas
      if (this.showColumnsTable != null && this.showColumnsTable.length > 0) {
        this.carregaColunasPreDefinidas();
      } else {
        this.montaColunasSelecionadas();
      }

      if (this.colunas_selecionadas.length <= 0) {
        for (let index = 0; index < this.columns.length; index++) {
          this.columns[index].hidden = false;
          this.colunas_selecionadas.push(index);
          this.$emit("columnSelected", this.colunas_selecionadas);
        }
      }
    },
    colunas_selecionadas(evt, oldEvt) {
      if (evt.length != oldEvt.length && oldEvt.length != 0) {
        this.$emit("columnSelected", evt);
      }
    },
    columnFilters: {
      handler() {
        // this.filterRows();
      },
      deep: true,
    },
    rows: {
      handler() {
        // this.filterRows();
      },
      deep: true,
    },
    itemSelecionadoInicial: {
      initial: true,
      handler(newValue, oldValue) {
        if (newValue == -1) {
          return;
        }

        if (
          typeof this.rows != "undefined" &&
          this.itemSelecionadoInicial <= this.rows.length
        ) {
          var that = this;
          const nRow = this.rows.find(
            (i, el) => el == that.itemSelecionadoInicial
          );
          this.verificaClick(nRow, Number(this.itemSelecionadoInicial), null);
        }
      },
    },
    openModalColunas() {
      if (this.openModalColunas) {
        this.showModalColunas = true;
      }
    },
    openModalDownload() {
      if (this.openModalDownload) {
        this.showModalDownload = true;
      }
    },
  },
  created() {
    this.id = `-${Math.random().toString(36).substr(2, 9)}`;
    this.keySelectColunas = Math.random();

    // //Marca Colunas
    // if (this.showColumnsTable != null && this.showColumnsTable.length > 0) {
    // 	this.carregaColunasPreDefinidas();
    // } else {
    // 	//this.montaColunasSelecionadas();
    // }
  },
  deactivated() {
    // bus.$off("teclaPrecionada");
    // bus.$off("keyDownPress");
  },
  activated() {
    var that = this;

    bus.$on("teclaPrecionada", (e) => {
      that.atalhosDeTecla(e);
    });
  },
  mounted() {
    // Detect when scrolled to bottom.
    // const listElm = document.querySelector(".scroll-table");
    // listElm.addEventListener("scroll", e => {
    //   // if (listElm.scrollTop <= 4 && this.notificacoesNaoLidas > 0) {
    //   //   //console.log("marking readed");
    //   //   var self = this;

    //   //   $.each(this.notifications, function(index, value) {
    //   //     if (value.unreaded) {
    //   //       self.notifications[index].unreaded = false;
    //   //       self.markSeen(self.notifications[index].cod_notificacao);
    //   //     }
    //   //   });
    //   // }
    //   if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
    //     this.showMore();
    //   }
    // });

    var that = this;

    $(document).ready(() => {
      $('[data-toggle-table="tooltip"]').tooltip();
      $(document).on("hover", '[data-toggle-table="tooltip"]', () => {
        $(this).tooltip("show");
      });
      $(document).on("focus", '[data-toggle-table="tooltip"]', () => {
        $(this).tooltip("hide");
      });
      $(document).on("click", '[data-toggle-table="tooltip"]', () => {
        $(this).tooltip("hide");
      });
    });

    if (
      that.customRowsPerPageDropdown &&
      that.customRowsPerPageDropdown[0] != that.currentPerPage
    ) {
      that.currentPerPage = that.customRowsPerPageDropdown[0];
    }

    if (this.defaultSearch != "") {
      this.globalSearchTerm = this.defaultSearch;
      this.searchForRequest();
    }

    // this.ajustesDropdownDownloadFile();

    if (that.globalSearch && !that.disableAutoFocoInput) {
      this.$nextTick(() => that.$refs.InputVueGooDTable.focus());
    }
    // that.$refs.InputVueGooDTable.focus();

    this.filteredRows = this.rows;
    if (this.perPage) {
      this.currentPerPage = this.perPage;
    }

    this.generateFieldsReport();

    // take care of default sort on mount
    if (this.defaultSortBy) {
      for (let index = 0; index < this.columns.length; index++) {
        let col = this.columns[index];
        if (col.field === this.defaultSortBy.field) {
          this.sortColumn = index;
          this.sortType = this.defaultSortBy.type || "asc";
          this.sortChanged = true;
          break;
        }
      }
    }
    // Marca Colunas
    if (this.showColumnsTable != null && this.showColumnsTable.length > 0) {
      this.carregaColunasPreDefinidas();
    }
  },
  updated() {
    $(".selectpicker").selectpicker("refresh");
    this.$emit("columns", this.columns);
  },

  methods: {
    /**
     * @method changePage
     * Responsável por mudar página da table
     */
    changePage(e) {
      this.changePG = e;

      this.$nextTick(() => {
        this.changePG = null;
      });
    },
    onClickOptionColunas(item, index) {
      if (item.hidden == false && this.colunas_selecionadas.length - 1 == 0) {
        bus.$emit("show-notification", [
          "warn",
          "Desculpe.",
          "Você não pode desmarcar todas as colunas!",
        ]);

        return false;
      }

      this.columns[index].hidden = !item.hidden;
      this.keySelectColunas = Math.random();
      this.colunasVisivelSelected = [];
      this.colunasInvisiveisSelected = [];
      this.montaColunasSelecionadas();

      this.$emit("columnSelected", this.colunas_selecionadas);
    },
    allColunasVisivel() {
      this.colunas_selecionadas = [];

      for (var index = 0; index < this.columns.length; index++) {
        this.columns[index].hidden = false;
        this.colunas_selecionadas.push(index);
      }
      this.colunasVisivelSelected = [];
      this.colunasInvisiveisSelected = [];
      this.keySelectColunas = Math.random();

      this.$emit("columnSelected", this.colunas_selecionadas);
    },
    allColunasInvisivel() {
      this.colunas_selecionadas = [];

      for (var index = 0; index < this.columns.length; index++) {
        this.columns[index].hidden = true;
      }
      // this.columns[0].hidden = false;
      var marcaUm = true;
      for (var key = 0; key < this.columns.length; key++) {
        if (marcaUm && this.columns[key].label != "") {
          this.columns[key].hidden = false;
          this.colunas_selecionadas.push(key);
          marcaUm = false;
        }
        if (this.columns[key].label == "") {
          this.columns[key].hidden = false;
          this.colunas_selecionadas.push(key);
        }
      }
      this.colunasVisivelSelected = [];
      this.colunasInvisiveisSelected = [];
      this.keySelectColunas = Math.random();

      this.$emit("columnSelected", this.colunas_selecionadas);
    },
    ColunasParaVisivel() {
      if (this.colunasInvisiveisSelected.length > 0) {
        for (
          var index = 0;
          index < this.colunasInvisiveisSelected.length;
          index++
        ) {
          this.columns[this.colunasInvisiveisSelected[index]].hidden = false;
        }
        this.keySelectColunas = Math.random();
        this.colunasInvisiveisSelected = [];
      }

      this.$emit("columnSelected", this.colunas_selecionadas);
    },
    ColunasParaInvisivel() {
      if (this.colunasVisivelSelected.length > 0) {
        for (
          var index = 0;
          index < this.colunasVisivelSelected.length;
          index++
        ) {
          this.columns[this.colunasVisivelSelected[index]].hidden = true;
        }
        this.keySelectColunas = Math.random();
        this.colunasVisivelSelected = [];
      }

      this.$emit("columnSelected", this.colunas_selecionadas);
    },
    montaColunasSelecionadas() {
      this.colunas_selecionadas = [];

      for (let index = 0; index < this.columns.length; index++) {
        if (this.columns[index].hidden == undefined) {
          this.columns[index].hidden = false;
          this.colunas_selecionadas.push(index);
        } else {
          if (!this.columns[index].hidden) {
            this.colunas_selecionadas.push(index);
          }
        }
      }

      this.$emit("columnSelected", this.colunas_selecionadas);
    },
    carregaColunasPreDefinidas() {
      this.colunas_selecionadas = [];

      if (this.showColumnsTable != null && this.showColumnsTable.length > 0) {
        for (let index = 0; index < this.columns.length; index++) {
          if (this.columns[index].label != "") {
            this.columns[index].hidden = true;
          }
        }

        for (let index = 0; index < this.showColumnsTable.length; index++) {
          this.columns[this.showColumnsTable[index]].hidden = false;
          this.colunas_selecionadas.push(this.showColumnsTable[index]);
        }
      }

      this.$emit("columnSelected", this.colunas_selecionadas);
    },
    /*
     * Função: Method resposável por marcar todos os checkbox
     * Chamada por: Evento '@click' da tabela atendimento, referente ao 'input todos checkbox'
     * */
    toggleSelectAll() {
      this.allSelected = !this.allSelected;
    },

    async verificaClick(row, index, e = null) {
      if (e != null && (e.ctrlKey || e.which == 3)) {
        return false;
      }
      var that = this;
      that.clickCount = (await that.clickCount) + 1;
      if (that.clickCount > 0 && that.clickCount === 1) {
        // var singleClickTimer = setTimeout(function() {
        this.$nextTick(() => {
          if (that.clickCount > 0 && that.clickCount === 1) {
            that.clickCount = 0;
            that.originalRows[index.rowSelected] =
              !that.originalRows[index.rowSelected];
            that.selectedRow(row, that.paginated);
          }
        });
        // }, 250);
      } else if (that.clickCount === 2) {
        that.doubleClick(row, index);
        that.clickCount = 0;
      }
    },

    clickFilter() {
      var that = this;
      setTimeout(() => {
        that.$emit("clickFiltroTable");
      }, 200);
    },

    doubleClick(row, index) {
      var that = this;

      setTimeout(() => {
        that.$emit("doubleClickTable", [row, index]);
      }, 200);
    },

    selectedRow(linha, lista) {
      // console.log("selected");
      // console.log(linha);

      /* Item Selecionado */
      if (this.disableRowSelected || this.isEditingRow) {
        return false;
      }

      if (this.itensMarcados.length > 0) {
        if (!linha.selected) {
          this.unselectedRows();
          return false;
        }
      }
      if (linha.rowSelected) {
        linha.rowSelected = false;
        this.$emit("selectedRow", -1);
        // console.log("bora dar -1 pra esse tiozão");
        this.itemSelecionado = -1;
      } else {
        $.each(lista, (index, value) => {
          value.rowSelected = false;
        });
        linha.rowSelected = true;
        this.$emit("selectedRow", linha.originalIndex, 666);
        this.itemSelecionado = linha.originalIndex;
        this.lastItemChecked = linha.originalIndex;
      }
    },

    itemCheck(linha, indexItem, click) {
      // Mexer aqui
      var that = this;
      var lastCheckItem = null;
      var lastIndex = null;

      this.lastItemChecked = linha.originalIndex;
      this.itemSelecionado = linha.originalIndex;
      // console.log(linha.originalIndex);
      // console.log("last item checked above ");

      if (that.enableCheckbox) {
        if (click.shiftKey) {
          $.each(that.paginated, (index, value) => {
            if (value.selected && value.originalIndex != linha.originalIndex) {
              lastCheckItem = value;
              lastIndex = index;
            }
          });
          if (lastIndex != null) {
            if (indexItem > lastIndex) {
              var i = lastIndex + 1;

              for (i; i <= indexItem; i++) {
                that.paginated[i].selected = true;
                that.itensMarcados.push(that.paginated[i]);
              }
              // this.$emit("itensCheck", that.itensMarcados);
            } else {
              var i = indexItem;
              var itensDelete = [];

              for (i; i <= lastIndex; i++) {
                that.paginated[i].selected = false;

                for (var y = 0; y < that.itensMarcados.length; y++) {
                  if (
                    that.itensMarcados[y].originalIndex ==
                    that.paginated[i].originalIndex
                  ) {
                    that.itensMarcados.splice(y, 1);
                  }
                }
              }
              // this.$emit("itensCheck", that.itensMarcados);
            }
          }
        } else {
          if (!linha.selected) {
            that.itensMarcados.push(linha);
            // this.$emit("itensCheck", that.itensMarcados);
          } else {
            for (var y = 0; y < that.itensMarcados.length; y++) {
              if (that.itensMarcados[y].originalIndex == linha.originalIndex) {
                that.itensMarcados.splice(y, 1);
              }
            }

            // this.$emit("itensCheck", that.itensMarcados);
          }
        }
      }
    },

    unselectedRows() {
      $.each(this.paginated, (index, value) => {
        value.rowSelected = false;
      });
      this.itemSelecionado = -1;
      this.$emit("selectedRow", -1);
      // console.log(
      //   "holy smokes, we are inside unselectedrows giving -1 to selected row!"
      // );
    },

    searchForRequest() {
      if (this.isCSV) {
        console.log("XD");
        this.$emit("update-search-query", this.globalSearchTerm);
        return;
      }

      this.backHome = false;
      // SE USUARIO TIVER DIGITADO
      if (this.searchTerm != this.globalSearchTerm) {
        // RESETA PARAMETROS DE EXIBICAO DE DADOS DA TABELA
        this.currentPerPage = this.currentPerPage;
        this.currentPage = 1;

        this.offset = 0;
        this.limit = this.currentPerPage;
        this.buscarDados = true;
        this.clearArray = true;

        // SALVA O TERMO PESQUISADO
        this.searchTerm = this.globalSearchTerm;

        this.backHome = true;

        const pageChangedEvent = this.pageChangedEvent();
        this.$emit("searchForRequest", pageChangedEvent);
      }
      this.backHome = false;
    },

    emitFilter() {
      this.$emit("update-filter", this.filter);
    },

    // Converte horas, minutos e segundos para duas cadas.
    duas_casas(numero) {
      if (numero <= 9) {
        numero = `0${numero}`;
      }
      return numero;
    },
    // Converte segundos para padrão hh:mm:ss
    converteSegundos(s) {
      var hora = this.duas_casas(Math.round(s / 3600));
      var minuto = this.duas_casas(Math.floor((s % 3600) / 60));
      var segundo = this.duas_casas((s % 3600) % 60);

      return `${hora}:${minuto}:${segundo}`;
    },

    pageChangedEvent() {
      this.unselectedRows();

      return {
        currentPage: this.currentPage,
        currentPerPage: this.currentPerPage,
        total:
          this.currentPerPage === -1
            ? this.rows.length
            : Math.floor(this.rows.length / this.currentPerPage),
        buscarDados: this.buscarDados,
        offset: this.offset,
        limit: this.limit,
        searchTerm: this.globalSearchTerm,
        clearArray: this.clearArray,
      };
    },

    showMore(pagination) {
      return;
      if (this.loadingShowMore) {
        return;
      }

      // console.log(this.offset);
      // console.log(this.lenghtRow);
      // console.log(this.currentPerPage);
      // console.log(this.limit);
      // console.log(this.rows.length);

      if (
        this.lenghtRow > this.limit + this.offset &&
        1 * this.lenghtRow > this.rows.length &&
        this.rows.length < this.lenghtRow
      ) {
        // this.currentPerPage = 0;
        this.offset = this.limit + this.offset;

        this.limit = this.currentPerPage;
        // VOLTA O STATUS DA VARIVEL Q CONTROLA A VOLTA AO INICIO DA PAGINACAO AO BUSCAR UM TERMO
        this.backHome = false;

        // VERIFICA SE O TERMO PESQUISADO É O MESMO
        if (this.searchTerm == this.globalSearchTerm) {
          this.clearArray = false;
        }

        this.currentPage = 1;
        this.currentPerPage = this.lenghtRow;

        this.buscarDados = true;
        this.disableShowMore = false;
      } else {
        this.buscarDados = false;
        this.disableShowMore = true;
      }

      const pageChangedEvent = this.pageChangedEvent();
      this.$emit("show-more", pageChangedEvent);
    },
    pageChanged(pagination) {
      /* SE PAGINACAO FOR MAIOR QUE ATUAL (MUDOU DE PAGINA)
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      SE QUANTIDADE DE REGISTROS FOR MAIOR Q OFFSET (AINDA EXISTE REGISTROS PARA BUSCAR)
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      SE NOVA PAGIA * TOTAL DE REGISTROS EXIBIDOS for maior q os registros já encontrados
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */
      if (
        pagination.currentPage > this.currentPage &&
        this.lenghtRow > this.offset &&
        pagination.currentPage * this.currentPerPage > this.rows.length &&
        this.rows.length < this.lenghtRow
      ) {
        this.buscarDados = true;

        this.offset += this.paginated.length;
        this.limit = this.currentPerPage;
      } else {
        this.buscarDados = false;
      }

      // VOLTA O STATUS DA VARIVEL Q CONTROLA A VOLTA AO INICIO DA PAGINACAO AO BUSCAR UM TERMO
      this.backHome = false;

      // VERIFICA SE O TERMO PESQUISADO É O MESMO
      if (this.searchTerm == this.globalSearchTerm) {
        this.clearArray = false;
      }

      this.currentPage = pagination.currentPage;

      const pageChangedEvent = this.pageChangedEvent();
      this.$emit("pageChanged", pageChangedEvent);
    },
    emitRowChecked() {
      var self = this;
      this.rowChecked = [];
      $.each(this.paginated, (index, value) => {
        if (value.selected) {
          self.rowChecked.push(value);
        }
      });
      var itens = {
        currentPage: self.rowChecked.length,
        all: self.itensMarcados.length,
      };
      this.$emit("itensMarcados", itens);
    },
    infoPagination(e) {
      // this.itemSelecionado = -1;
      // this.lastItemChecked = -1;
      this.emitRowChecked();
      this.paginationInfo = e;

      this.$emit("infoPagination", e);
    },
    perPageChanged(pagination) {
      if (this.currentPerPage == pagination.currentPerPage) {
        return false;
      }

      if (this.currentPage > 1) {
        this.currentPage = 1;
      }
      if (pagination.currentPerPage > 0) {
        this.offset = 0;
        this.limit = pagination.currentPerPage; // this.currentPerPage
        this.clearArray = true;
        this.buscarDados = true;
      } else {
        // SE NAO FOR MAIOR Q ZERO, É PQ SELECINOU TODOS OS REGISTROS, COM ISSO IRA BUSCAR TODOS
        this.clearArray = true;
        this.offset = 0;
        this.buscarDados = true;
        this.limit = this.lenghtRow;

        if (this.paginated.length < this.lenghtRow) {
          this.buscarDados = true;
        } else {
          this.buscarDados = false;
        }
      }

      // VERIFICA SE O TERMO PESQUISADO É O MESMO
      if (
        this.searchTerm == this.globalSearchTerm &&
        this.searchTerm != null &&
        this.searchTerm != "" &&
        this.globalSearchTerm != null &&
        this.globalSearchTerm != ""
      ) {
        this.clearArray = false;
      }

      this.currentPerPage = pagination.currentPerPage;
      const perPageChangedEvent = this.pageChangedEvent();
      this.$emit("perPageChanged", perPageChangedEvent);
    },

    sort(index) {
      if (!this.isSortableColumn(index)) return;
      if (this.sortColumn === index) {
        this.sortType = this.sortType === "asc" ? "desc" : "asc";
      } else {
        this.sortType = "asc";
        this.sortColumn = index;
      }
      this.sortChanged = true;

      var colunaOrdenacao = null;
      if (
        this.columns[index].colSortable != null &&
        this.columns[index].colSortable != ""
      ) {
        colunaOrdenacao = this.columns[index].colSortable;
      } else {
        colunaOrdenacao = this.columns[index].field;
      }

      this.offset = 0;
      this.limit = this.currentPerPage;

      this.$emit("sortTable", {
        colOrdem: colunaOrdenacao,
        ordem: this.sortType,
        offset: 0,
        limit: this.currentPerPage,
        clearArray: true,
      });
    },

    click(row, index) {
      if (this.onClick) this.onClick(row, index);
    },

    searchTable() {
      if (this.searchTrigger == "enter") {
        this.forceSearch = true;
        this.sortChanged = true;
      }
    },

    // field can be:
    // 1. function
    // 2. regular property - ex: 'prop'
    // 3. nested property path - ex: 'nested.prop'
    collect(obj, field) {
      // utility function to get nested property
      function dig(obj, selector) {
        var result = obj;
        const splitter = selector.split(".");
        for (let i = 0; i < splitter.length; i++)
          if (typeof result === "undefined") return undefined;
          else result = result[splitter[i]];
        return result;
      }

      if (typeof field === "function") return field(obj);
      if (typeof field === "string") return dig(obj, field);
      return undefined;
    },

    collectFormatted(obj, column) {
      var value = this.collect(obj, column.field);

      if (value === undefined) return "";
      // lets format the resultant data
      var type = column.typeDef;

      if (column.type == "valor") {
        if (value != null) {
          value = Number(value)
            .toFixed(2)
            .replace(".", ",")
            .replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
        } else {
          value = "0,00";
        }
        // value = (Number(value)).toLocaleString("pt-BR");
      }

      if (column.type == "hora") {
        value = this.converteSegundos(value);
      }

      return type.format(value, column);
    },

    calculateOperator(column) {
      var retorno = 0;
      var that = this;
      var dados = [];

      if (that.typeCalculateFooterTable == "all-rows") {
        dados = this.rows;
      } else if (that.typeCalculateFooterTable == "show-rows") {
        dados = that.paginated;
      }

      $.each(dados, (index, linha) => {
        var value = that.collect(linha, column.field);

        if (value !== undefined && value != null) {
          if (column.footer.operation == "sum") {
            retorno += Number(value);
          } else if (column.footer.operation == "count") {
            retorno += 1;
          }
        }
      });

      if (column.type == "valor") {
        retorno = Number(retorno)
          .toFixed(2)
          .replace(".", ",")
          .replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
      }

      return retorno;
    },

    formattedRow(row) {
      var formattedRow = {};
      for (const col of this.typedColumns) {
        if (col.field) {
          formattedRow[col.field] = this.collectFormatted(row, col);
        }
      }
      return formattedRow;
    },

    // Check if a column is sortable.
    isSortableColumn(index) {
      if (this.columns[index].sortable != undefined) {
        const sortable = this.columns[index].sortable;
        const isSortable =
          typeof sortable === "boolean" ? sortable : this.sortable;
        return isSortable;
      }
    },

    // Get classes for the given header column.
    getHeaderClasses(column, index) {
      const isSortable = this.isSortableColumn(index);
      const classes = {
        ...this.getClasses(index, "th"),
        sorting: isSortable,
        "sorting-desc":
          isSortable && this.sortColumn === index && this.sortType === "desc",
        "sorting-asc":
          isSortable && this.sortColumn === index && this.sortType === "asc",
      };
      return classes;
    },

    // Get classes for the given column index & element.
    getClasses(index, element) {
      const { typeDef, [`${element}Class`]: custom } = this.typedColumns[index];
      let isRight = typeDef.isRight;
      if (this.rtl) isRight = true;
      const classes = {
        "right-align": isRight,
        "left-align": !isRight,
        [custom]: !!custom,
      };
      return classes;
    },

    // since vue doesn't detect property addition and deletion, we
    // need to create helper function to set property etc
    updateFilters(column, value) {
      const _this = this;
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        _this.$set(_this.columnFilters, column.field, value);
      }, 400);
    },

    // get column's defined placeholder or default one
    getPlaceholder(column) {
      const placeholder = column.placeholder || `Buscar por ${column.label}`;
      return placeholder;
    },

    getCurrentIndex(index) {
      if (this.paginate) {
        return (this.currentPage - 1) * this.currentPerPage + index + 1;
      }
      return (this.currentPage - 1) * this.rows.length + index + 1;
    },

    getRowStyleClass(row) {
      let classes = "";
      this.onClick ? (classes += "clickable") : "";
      let rowStyleClasses;
      if (typeof this.rowStyleClass === "function") {
        rowStyleClasses = this.rowStyleClass(row);
      } else {
        rowStyleClasses = this.rowStyleClass;
      }
      if (rowStyleClasses) {
        classes += ` ${rowStyleClasses}`;
      }
      if (row.rowClass != undefined && row.rowClass != null) {
        classes += ` ${row.rowClass}`;
      }
      return classes;
    },

    getTdClass(row, column) {
      let classes = "";

      if (row.tdClass != undefined && row.tdClass != null) {
        if (row.tdClass[0] == column.field) {
          classes += ` ${row.tdClass[1]}`;
        } else {
          classes += " ";
        }
      }
      return classes;
    },
    uncheckAll(evt) {
      var that = this;
      $.each(that.itensMarcados, (index, value) => {
        if (
          that.itensMarcados[index] != undefined &&
          that.itensMarcados[index].selected
        ) {
          that.itensMarcados[index].selected = false;
        }
      });

      // Limpar tudo, incluindo itemSelecionado, casop evt seja truew
      if (evt) {
        this.lastItemChecked = -1;
        this.itemSelecionado = -1;

        this.$emit("selectedRow", -1);
      }
    },
    pageUp(e) {
      if (!this.enableToggleRow) {
        return false;
      }
      var that = this;
      var next = this.itemSelecionado - 1;

      var shiftKey = e.shiftKey;

      // console.log(this.lastItemChecked);
      // If shiftkey is pressed, then we will maiten the current row selected and select the next one.
      if (shiftKey) {
        // check next item and keeps the current checked
        var nextItemCheck = that.lastItemChecked - 1;
        var nextSelected = that.originalRows[nextItemCheck];
        // console.log("lastitem bellow");
        // console.log(that.paginated[nextItemCheck]);

        if (
          that.originalRows[nextItemCheck] == undefined ||
          that.lastItemChecked <=
            (that.currentPage - 1) * that.currentPerPage ||
          that.lastItemChecked < 0
        ) {
          return false;
        }

        // if (that.originalRows[that.lastItemChecked].disabled) {
        //   that.lastItemChecked = nextItemCheck;
        //   that.pageUp(e);
        //   return;
        // }

        // se fir oruneura narca eke tanbpen
        if (that.itensMarcados.length <= 1) {
          // that.originalRows[nextItemCheck].selected = true;
          // that.itensMarcados.push(that.originalRows[nextItemCheck]);
          //         that.lastItemChecked = nextItemCheck;
          //           that.itemSelecionado = next;
          // that.pageDown(e);
          // return;

          // console.log(that.originalRows[that.lastItemChecked].disabled);
          if (!that.originalRows[that.lastItemChecked].disabled) {
            that.originalRows[that.lastItemChecked].selected = true;
            that.itensMarcados.push(that.originalRows[that.lastItemChecked]);
          }

          // that.pageDown(e);
          // return;
        }

        // //Se próxima item estiver selecionado, vai apra próximo até achar alguém que não esteja disabled
        if (that.originalRows[nextItemCheck].disabled) {
          // var prevRow = that.lastItemChecked + 1;
          // if (!that.originalRows[prevRow].selected) {
          that.originalRows[nextItemCheck].selected = false;
          that.itensMarcados.splice(nextItemCheck, 1);
          // }

          that.lastItemChecked = nextItemCheck;

          // console.log("loop page up");

          that.pageUp(e);
          return;
        }

        // if (that.originalRows[that.lastItemChecked].disabled) {
        //   that.originalRows[that.lastItemChecked].selected = false;
        //   that.itensMarcados.splice(that.lastItemChecked, 1);

        //   that.lastItemChecked = nextItemCheck;

        //   that.pageUp(e);
        //   return;
        // }

        // if (that.originalRows[nextItemCheck].disabled) {
        //   // that.originalRows[that.lastItemChecked].selected = false;

        //   that.lastItemChecked = nextItemCheck;

        //   that.pageUp(e);
        //   return;
        // }

        if (that.originalRows[nextItemCheck].selected) {
          that.originalRows[that.lastItemChecked].selected = false;
          that.itensMarcados.splice(that.lastItemChecked, 1);
        }
        that.originalRows[nextItemCheck].selected = true;
        that.itensMarcados.push(nextSelected);

        that.lastItemChecked = nextItemCheck;
        that.itemSelecionado = next;
        // }

        // console.log(nextItemCheck);
        // console.log("next item above");

        //  return false;
      } else {
        // If shift key is not pressed, we will unselect the current row, and select the next one.

        // Uncheck eveyone
        this.uncheckAll();

        // console.log(that.lastItemChecked);
        // se tiuver alguma selecitonado usqa op curretn selected do checkbox apra marcar qual item esta selecionado no momento
        if (that.itensMarcados.length > 0) {
          that.itemSelecionado = that.lastItemChecked;
          next = that.lastItemChecked - 1;
        }

        if (
          this.originalRows[next] == undefined ||
          this.itemSelecionado <=
            (this.currentPage - 1) * this.currentPerPage ||
          this.itemSelecionado < 0
        ) {
          if (this.originalRows[that.itemSelecionado] != undefined) {
            if (!this.originalRows[that.itemSelecionado].disabled) {
              that.originalRows[that.itemSelecionado].rowSelected = true;
              that.$emit("selectedRow", that.itemSelecionado);
              that.lastItemChecked = that.itemSelecionado;
            } else {
              that.originalRows[that.itemSelecionado + 1].rowSelected = true;
              that.$emit("selectedRow", that.itemSelecionado + 1);
              that.lastItemChecked = that.itemSelecionado + 1;
            }
          }
          return false;
        }

        if (this.originalRows[next].disabled) {
          that.originalRows[that.itemSelecionado].rowSelected = false;
          that.itemSelecionado = next;
          that.lastItemChecked = next;
          that.pageUp(e);
          return;
        }

        this.originalRows[this.itemSelecionado].rowSelected = false;
        this.originalRows[next].rowSelected = true;
        this.itemSelecionado = next;
        this.lastItemChecked = next;

        this.$emit("selectedRow", next);
      }

      // Fix the scroll
      this.fixScroll(e);
    },
    pageDown(e) {
      if (!this.enableToggleRow) {
        return false;
      }

      var that = this;
      var next = this.itemSelecionado + 1;
      // console.log(that.itensMarcados.length);
      var shiftKey = e.shiftKey;

      // If shiftkey is pressed, then we will maiten the current row selected and select the next one.
      if (shiftKey) {
        // check next item and keeps the current checked
        var nextItemCheck = that.lastItemChecked + 1;
        var nextSelected = that.originalRows[nextItemCheck];

        // console.log("last item checke bellow");
        // console.log(that.lastItemChecked);
        if (
          that.originalRows[nextItemCheck] == undefined ||
          that.lastItemChecked >= that.currentPage * that.currentPerPage ||
          that.lastItemChecked < 0
        ) {
          return false;
        }

        // se fir oruneura narca eke tanbpen
        if (that.itensMarcados.length <= 1) {
          // that.originalRows[nextItemCheck].selected = true;
          // that.itensMarcados.push(that.originalRows[nextItemCheck]);
          //         that.lastItemChecked = nextItemCheck;
          //           that.itemSelecionado = next;
          // that.pageDown(e);
          // return;

          // console.log(that.originalRows[that.lastItemChecked].disabled);
          if (!that.originalRows[that.lastItemChecked].disabled) {
            that.originalRows[that.lastItemChecked].selected = true;
            that.itensMarcados.push(that.originalRows[that.lastItemChecked]);
          }

          // that.pageDown(e);
          // return;
        }

        // Se próxima item estiver selecionado, vai apra próximo até achar alguém que não esteja disabled
        if (that.originalRows[nextItemCheck].disabled) {
          // var prevRow = that.lastItemChecked + 1;
          // if (!that.originalRows[prevRow].selected) {
          that.originalRows[nextItemCheck].selected = false;
          that.itensMarcados.splice(nextItemCheck, 1);
          // }

          that.lastItemChecked = nextItemCheck;
          //  console.log("loop page down");
          that.pageDown(e);
          return;
        }

        if (that.originalRows[nextItemCheck].selected) {
          that.originalRows[that.lastItemChecked].selected = false;
          that.itensMarcados.splice(that.lastItemChecked, 1);
        }

        that.originalRows[nextItemCheck].selected = true;
        that.itensMarcados.push(nextSelected);

        // console.log(nextItemCheck);
        // console.log("next item above");
        // console.log(that.itensMarcados.length)

        that.lastItemChecked = nextItemCheck;
        that.itemSelecionado = next;
        //     }

        // console.log(next);
        // return false;
      } else {
        // If shift key is not pressed, we will unselect the current row, and select the next one.

        // Uncheck eveyone
        this.uncheckAll();

        // console.log(that.lastItemChecked);
        // se tiuver alguma selecitonado usqa op curretn selected do checkbox apra marcar qual item esta selecionado no momento
        if (that.itensMarcados.length > 0) {
          that.itemSelecionado = that.lastItemChecked;
          next = that.lastItemChecked + 1;
        }

        // console.log(that.lastItemChecked);
        // console.log(that.itemSelecionado);

        if (
          this.originalRows[next] == undefined ||
          next >= this.currentPage * this.currentPerPage ||
          this.itemSelecionado < 0
        ) {
          if (this.originalRows[that.itemSelecionado] != undefined) {
            if (!this.originalRows[that.itemSelecionado].disabled) {
              this.originalRows[that.itemSelecionado].rowSelected = true;
              this.$emit("selectedRow", that.itemSelecionado);
              that.lastItemChecked = that.itemSelecionado;
            } else {
              this.originalRows[that.itemSelecionado - 1].rowSelected = true;
              this.$emit("selectedRow", that.itemSelecionado - 1);
              that.lastItemChecked = that.itemSelecionado - 1;
            }
          }

          return false;
        }

        if (this.originalRows[next].disabled) {
          that.originalRows[that.itemSelecionado].rowSelected = false;
          that.itemSelecionado = next;
          that.lastItemChecked = next;
          that.pageDown(e);
          return;
        }

        // console.log("----- itemselecionado then next------");
        // console.log(that.itemSelecionado);
        // console.log(next);
        this.originalRows[this.itemSelecionado].rowSelected = false;
        this.originalRows[next].rowSelected = true;
        this.itemSelecionado = next;
        this.lastItemChecked = next;
        // console.log(next + "is the row selected");
        this.$emit("selectedRow", next);
      }

      // Fix the scroll
      this.fixScroll(e);
    },
    goTop() {
      var goodTable = $(`#${this.$el.offsetParent.id} .scroll-table`);

      if (goodTable != undefined) {
        goodTable.scrollTop(0); // set to top
      }
    },
    fixScroll(e) {
      //    console.log(this.currentPage);
      //      console.log(this.currentPerPage);

      e.preventDefault();
      // //console.log($(".row-selected").offset().top);
      // //console.log($(".good-table").height());
      var parentId = this.$el.offsetParent.id;

      if (parentId == "") {
        // eslint-disable-next-line no-console
        console.debug(
          'Erro ao corrigir o scroll da tabela: A tabela selecionada não tem nenhum "Parent ID"'
        );
        return false;
      }
      var rowSelected = $(`#${parentId} .row-selected`);
      var goodTable = $(`#${parentId} .scroll-table`);

      // console.log(rowSelected.length);
      // console.log(goodTable.length);
      if (rowSelected.length == 0) {
        if (e.keyCode == 40) {
          rowSelected = $(".selected").last();
        } else if (e.keyCode == 38) {
          rowSelected = $(".selected").first();
        }
      }

      if (rowSelected.length == 0) {
        return false;
      }

      var distFromTabTop = rowSelected.offset().top - goodTable.offset().top;

      // console.log("distance from top");
      // console.log(distFromTabTop);
      // console.log("----------------------");
      // console.log(rowSelected);
      // console.log(goodTable);
      // console.log(distFromTabTop);
      // console.log("here we are");
      if (distFromTabTop <= 55) {
        goodTable.scrollTop(0); // set to top
        goodTable.scrollTop(rowSelected.offset().top - goodTable.height() - 50);
      } else if (distFromTabTop >= goodTable.height() - 50) {
        goodTable.scrollTop(0); // set to top
        goodTable.scrollTop(
          rowSelected.offset().top - (goodTable.offset().top + 50)
        );
      }
      // if (top >= height || top <= 200) {
      //   $(".good-table").scrollTop(0); //set to top
      //   $(".good-table").scrollTop(
      //     $(".row-selected").offset().top - $(".good-table").height()
      //   ); //then set equal to the position of the selected element minus the height of scrolling div
      // }
      // $(".good-table").scrollTop(0); //set to top
      // $(".good-table").scrollTop(
      //   $(".row-selected").offset().top - $(".good-table").height()
      // ); //then set equal to the position of the selected element minus the height of scrolling div
    },
    teclaPressionadaTable(e) {
      this.$emit("teclaPressionadaTable", e);
    },
    atalhosDeTecla(e) {
      // console.log(e);
      // console.log("enter press");

      return;
      var that = this;

      // //console.log(this.canScroll);
      // //38 up, move para cima na table
      // if (e.keyCode == 38 && this.canScroll) {
      //   // var prev = that.itemSelecionado - 1;
      //   // that.originalRows[that.itemSelecionado].rowSelected = false;
      //   // that.originalRows[prev].rowSelected = true;
      //   // that.itemSelecionado = prev;
      //   // that.$emit("selectedRow", prev);
      //   $.each(that.originalRows, function(index, value) {
      //     if (value.rowSelected) {
      //       var prev = index - 1;

      //       if (that.originalRows[prev] == undefined) {
      //         return false;
      //       }

      //       that.originalRows[index].rowSelected = false;
      //       that.originalRows[prev].rowSelected = true;
      //       that.$emit("selectedRow", prev);

      //       return false;
      //     }
      //   });
      //   return false;
      // }

      // //40 down, move para baixo na table
      // if (e.keyCode == 40 && this.canScroll) {
      //   $.each(that.originalRows, function(index, value) {
      //     if (value.rowSelected) {
      //       var next = index + 1;
      //       //console.log(next);
      //       if (that.originalRows[next] == undefined) {
      //         that.showMore();
      //         return false;
      //       }

      //       that.originalRows[index].rowSelected = false;
      //       that.originalRows[next].rowSelected = true;
      //       that.$emit("selectedRow", next);
      //       return false;
      //     }
      //   });
      //   return false;
      // }

      // ABRE MODAL INCLUIR FORNECEDORES - F9
      if (e.keyCode == 113) {
        if (that.globalSearch) {
          if (that.globalSearchTerm != null && that.globalSearchTerm != "") {
            that.globalSearchTerm = "";
          }
          that.$refs.InputVueGooDTable.focus();
        }
        return false;
      }

      // ABRE MODAL INCLUIR FORNECEDORES - F9
      if (e.keyCode == 17 && e.ctrlKey == true) {
        that.dragscroll = true;
        return false;
      }
      if (e.keyCode == 17 && e.ctrlKey == false) {
        that.dragscroll = false;
      }
    },

    generateFieldsReport() {
      this.jsonFields = {};
      for (let column of this.columns) {
        this.jsonFields[column.label] = column.field;
      }
    },

    generateFile(tipo) {
      if (tipo != "" && tipo != undefined) {
        this.fileName = `${this.nameFile}.${tipo}`;
        if (tipo == "xlsx") {
          this.typeFile = "xlsx";
        } else {
          this.typeFile = "csv";
        }

        /** variavel para guardar colunas exibidas */
        var showCol = [];

        /** informacoes padroes q eram passadas para serem exportadas */
        var fields = this.jsonFields;
        var rows = this.originalRows;
        /** percorre columns para eliminar colunas ocultas */
        var that = this;
        $.each(this.columns, (index, row) => {
          if (!row.hidden && !row.blockexport) {
            showCol.push({ field: row.field, label: row.label });
          }
        });

        /** se existir colunas selecionadas para serem exibidas */
        if (showCol.length > 0) {
          /** zera arrays com os dados que serao exportados */
          rows = [];
          fields = [];

          /** monta array com colunas exibidas */
          for (let column of showCol) {
            fields[column.label] = column.field;
          }

          /** monta os dados apenas das colunas exibidas */
          $.each(that.originalRows, (index, object) => {
            var registros = {};
            for (var key in object) {
              $.each(showCol, (key, value) => {
                registros[value.field] =
                  object[value.field] != null ? object[value.field] : "";
              });
            }
            rows.push(registros);
          });
        }

        jsonExport.generate({
          data: rows,
          fields,
          type: this.typeFile,
          name: this.fileName,
          delimiter: ";",
        });

        this.showModalDownload = false;
      }
    },

    getTooltipSide(index) {
      if (index == 0) return "right";
      if (index == this.columns.length - 1) return "left";
      return "bottom";
    },
  },
};
</script>

<style lang="scss" scoped>
/* Utility styles

                      .************************************************/
@import "~@/assets/css/sass/bootstrap/variables";
#good-table {
  .page-info {
    font-size: 12px;
    line-height: 31px;
    padding: 0 5px;
  }
  .no-padding-right {
    padding-right: 0px !important;
  }
  .no-padding-left {
    padding-left: 0px !important;
  }
  .btn-paginate {
    font-size: 18px !important;
    line-height: 30px !important;
  }
}

#loadingTable {
  background: #ffffffad;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  padding-top: 20px;
  overflow: hidden;
  padding-bottom: 20px;

  .lds-roller {
    width: 64px;
    margin: auto;
    top: 40%;
    position: relative;
    height: 64px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: linear-gradient(to right, #00773d -15%, $brand-primary 100%);
    margin: -3px 0 0 -3px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

#InputVueGooDTable {
  text-transform: uppercase;

  text-transform: uppercase;
  background: #f7f7f7f7;
  border-radius: 3px;
  border: 1px solid #e5e5e5;
  font-size: 13px;
  height: 31px !important;
  margin-bottom: 5px;
  padding: 5px;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  line-height: 0px;
  float: left;
  margin: 0px 0px 0px 0px;

  &:focus,
  &:active {
    -webkit-box-shadow: 0 0px 1px 1px #00000040 !important;
    box-shadow: 0 0px 1px 1px #00000040 !important;
  }
  &::-webkit-input-placeholder {
    text-transform: capitalize;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  // -webkit-border-radius: 10px;
  // border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  // -webkit-border-radius: 10px;
  // border-radius: 10px;
  background: #ccc;
  //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}

//.row-selected,
//.selected {
//  td {
//    background-color: #a6a6a6;
//    color: #fff;
//  }
//}

.editing {
  td {
    background-color: #2a7147;
    color: #fff;
  }
}

.selected td > label input[type="checkbox"]::before {
  color: #329e6e;
  background: #ffffff;
}

.left-align {
  text-align: left !important;
}

.center-align {
  text-align: center !important;
}

.right-align {
  text-align: right !important;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

label.checkbox {
  margin-bottom: 0;
  text-align: center;
}

.margin-bottom-section {
  padding-bottom: 15px;
}

/* Table specific styles
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          						************************************************/

table {
  border-collapse: collapse;
  background-color: transparent;
  margin-bottom: 0px;
}
.table {
  width: 100%;
  max-width: 100%;
  table-layout: auto;
  font-size: 13px;
  font-weight: 300;
}

.table.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.table.table-bordered td,
.table-bordered th {
  border: 1px solid #ddd;
}

// .table thead {
//   text-transform: capitalize;
// }

.table td,
.table th:not(.line-numbers) {
  padding: 0.75rem 1.5rem 0.75rem 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ddd;
}

.table.small td {
  padding: 0.5px 5px;
  font-size: 12px;
}
.table.small th {
  padding: 0.4rem 0.8rem 0.4rem 0.8rem;
}

.rtl .table td,
.rtl .table th:not(.line-numbers) {
  padding: 0.75rem 0.75rem 0.75rem 1.5rem;
}

.table.condensed td,
.table.condensed th {
  padding: 0.4rem 0.8rem 0.4rem 0.8rem;
}

.table thead th,
.table.condensed thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  /* padding-right: 1.5rem; */
  background-color: rgba(35, 41, 53, 0.03);
  vertical-align: middle;
}
.rtl .table thead th,
.rtl .table.condensed thead th {
  padding-left: 1.5rem;
  padding-right: 0.75rem;
}

tr.clickable {
  cursor: pointer;
}

.input-condensed {
  height: 26px !important;
  font-size: 12px !important;
}

.table input[type="text"],
.table select {
  box-sizing: border-box;
  display: block;
  width: calc(100%);
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 2px;
  -webkit-box-shadow: inset 0 1px 1px rgba(35, 41, 53, 0.075);
  box-shadow: inset 0 1px 1px rgba(35, 41, 53, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

table th.sorting-asc,
table th.sorting-desc {
  color: rgba(0, 0, 0, 0.66);
  position: relative;
}

table th.sorting:after,
table th.sorting-asc:after {
  font-family: "Material Icons";
  position: absolute;
  height: 0px;
  width: 0px;
  content: "";
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid rgba(35, 41, 53, 0.25);
  margin-top: 6px;
  margin-left: 5px;
}

.rtl table th.sorting:after,
.rtl table th.sorting-asc:after {
  margin-right: 5px;
  margin-left: 0px;
  display: inline-block;
  border-bottom-color: rgba(35, 41, 53, 0.25);
}

table th.sorting:hover:after {
  display: inline-block;
  border-bottom-color: rgba(35, 41, 53, 0.25);
}

table th.sorting-asc:after,
table th.sorting-desc:after {
  display: inline-block;
}

table th.sorting-desc:after {
  border-top: 6px solid rgba(0, 0, 0, 0.66);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: none;
  margin-top: 8px;
}

.responsive {
  width: calc(100% - 0.4px);
  //overflow-x: auto;
  //margin-top: 5px;
  // border-right: 1px solid #e5e5e5;
}

.responsive-no-scroll {
  overflow-x: auto;
}

/* Table tela inteira com busca sob demanda no scroll */
.scroll-table {
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 157px);

  th {
    position: sticky !important;
    background: #f5f5f5 !important;
    top: 0;
    z-index: 5;
    // text-transform: uppercase;
  }

  table tr th {
    outline: 1px solid #e9ecef;
    border: none;
    outline-offset: -1px;
  }

  .table-footer {
    border-top: none;
    td {
      position: -webkit-sticky !important;
      position: sticky !important;
      background: #f5f5f5 !important;
      bottom: 0;
      z-index: 5;
      /* outline: 2px solid #9a9a9a; */
      border: none;
      box-shadow: 0 -3px 0 #888;
    }
  }
}

/* Table header specific styles
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          						************************************************/

.table-header {
  padding: 0.75rem;
}

.table-header .table-title {
  margin: 0px;
  font-size: 18px;
}

/* Global Search
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          						  **********************************************/
.global-search {
  position: relative;
  padding-left: 0px;
}
.global-search-icon {
  position: absolute;
  left: 0px;
  max-width: 32px;
}
.global-search-icon > img {
  max-width: 100%;
  margin-top: 8px;
  opacity: 0.5;
}
table .global-search-input {
  width: calc(100% - 30px);
  margin-bottom: 0px;
}

.container-top-bar {
  //width: 100%;
  //height: 23px;
  // padding: 0px 5px;
  margin-bottom: 6px;
  //display: flex;
  // margin-top: 5px;
  // background-color: rgba(35, 41, 53, 0.03);

  .filter {
    width: 35px;
    float: right;
    margin-left: 3px;
  }

  .btn-colunas,
  .btn-download {
    margin-left: 3px;
  }

  .btn-filtro,
  .btn-gerar-arquivo {
    // width: 35px;
    // height: 25px;
    // //margin-left: 3px;
    // padding: 0;

    width: 35px;
    height: 31px;
    // margin-left: 3px;
    border-color: #e5e5e5;
    padding: 0;
  }

  .btn-colunas {
    width: 35px;
    height: 31px;
    border-color: #e5e5e5;
    margin-left: 3px;
    padding: 0;
  }

  .btn-download-file {
    display: none;
  }

  .section-busca {
    // width: 100%;
    // margin-left: 5px;
    // margin-right: 5px;
    float: left;

    input {
      width: calc(100% - 38px) !important;
      float: left;
      margin-bottom: 0px;
    }

    button {
      // width: 35px;
      // float: left;
      // margin-left: 3px;

      width: 35px;
      float: left;
      border-color: #e5e5e5;
      height: 31px;
      margin-left: 3px;
    }
  }
}

.csvSearch {
  display: flex;
  gap: 10px;
}

.custom-csv-style {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
}

@media (max-width: 490px) {
  .section-busca {
    input {
      width: 86% !important;
      float: left;
      margin-bottom: 0px;
    }

    button {
      width: 12%;
      float: left;
      margin-left: 3px;
    }
  }
}

/* Line numbers
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          						  **********************************************/
table th.line-numbers,
.table.condensed th.line-numbers {
  background-color: rgba(35, 41, 53, 0.05);
  padding-left: 3px;
  padding-right: 3px;
  word-wrap: break-word;
  width: 45px;
  text-align: center;
}

.good-table.rtl {
  direction: rtl;
}

.text-disabled {
  color: #aaa;
}

.good-table {
  flex-direction: column;
  display: flex;
  overflow: auto;
}
/* magnifying glass css */
.magnifying-glass {
  margin-top: 3px;
  display: block;
  width: 15px;
  height: 15px;
  border: 3px solid #ccc;
  position: relative;
  border-radius: 50%;
}
.magnifying-glass::before {
  content: "";
  display: block;
  position: absolute;
  right: -10px;
  bottom: -6px;
  background: #ccc;
  width: 10px;
  height: 4px;
  border-radius: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.text-center {
  text-align: center !important;
}

.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.no-padding-right {
  padding-right: 0px;
}

.no-padding-left {
  padding-left: 0px;
}

.btn-seach {
  padding: 1px 3px;
  border-radius: 2px;
  color: var(--brand-primary, $brand-primary);
}

.dropdown-file {
  left: -156px !important;
  min-width: 56px !important;
  z-index: 1100;
}

.dropdown-menu {
  min-width: 178px;
}

.dropdown-menu .divider {
  margin: 3px !important;
}
.dropdown-file {
  min-width: 56px !important;
  z-index: 1100;
  width: 170px;
}

.dropdown-menu-gera-arquivo .btn-tipo {
  padding: 3px 8px;
  font-size: 10px;
}
.name-file,
.tipo-file {
  padding: 3px 10px;
}

.btn-tipo {
  padding: 3px 3px;
  width: 50px;
}

.select-colunas {
  width: 45px;
  float: right;
}

.loading {
  background: rgba(238, 238, 238, 0.95);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  margin-top: -5px;
}

.content-loading {
  vertical-align: middle;
  top: 38%;
  left: -3%;
  position: relative;
  color: #aaa;
  text-align: center;
}

.sorting {
  padding-right: 20px !important;
}

.table-footer {
  // background: #e9e7e7 !important;
  // text-align: right;
  // border-top: 2px solid #888;
  // font-weight: 444;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

.td-checkbox {
  width: 10px !important;
}
</style>

<style lang="scss">
.headerIconSlot {
  float: left;
  padding-left: 10px;
  border-left: 1px solid #e5e5e5;
}
.select-colunas {
  .bs-caret {
    display: none !important;
  }

  button.btn.dropdown-toggle.btn-default {
    display: none;
  }

  button.btn.dropdown-toggle.bs-placeholder.btn-default {
    display: none !important;
  }

  .bootstrap-select.btn-group .dropdown-toggle .filter-option {
    display: none !important;
  }

  .btn-group.bootstrap-select.show-tick {
    width: 0 !important;
    top: 17px;
  }

  button.actions-btn.bs-select-all.btn.btn-default {
    display: none;
  }
}

.dropdown-file {
  min-width: 56px !important;
  z-index: 1100;
  width: 170px;
}

.dropdown-menu-gera-arquivo .btn-tipo {
  padding: 3px 8px;
  font-size: 10px;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed !important;
  background: #ececec !important;
  color: #717171 !important;
}

#modal-download-table {
  .modal-container {
    width: 30% !important;
  }
}
</style>
