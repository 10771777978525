<template>
  <div>
    <!-- MODAL FILTROS -->
    <modal
      :show-modal="showModal"
      class="modal-carrinho-compra"
      :titulo="'Carrinho de compras'"
      @close="close()"
    >
      <!-- MODAL FILTRO CONTENT -->
      <div slot="body">
        <div class="modal-header">
          <div class="modal-header-info">
            <h4 class="session-header green">Informações do pedido:</h4>
            <div class="modal-header-info-items">
              <div class="item-header-info">
                <span>Razão:</span>
                {{ dadosPedido.informacoes.nomerazao }}
              </div>
              <div class="item-header-info">
                <span>Unidade:</span>
                {{ dadosPedido.informacoes.unidade }}
              </div>
              <div class="item-header-info">
                <span>Tabela de venda:</span>
                {{ dadosPedido.informacoes.tabvnd }}
              </div>
              <div class="item-header-info">
                <span>Tipo de pagamento:</span>
                {{ dadosPedido.informacoes.formaPagamento }}
              </div>
              <div class="item-header-info">
                <span>Condição de pagamento:</span>
                {{ dadosPedido.informacoes.condicaoPagamento }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-container">
          <div class="container-table">
            <table class="table-items">
              <thead>
                <tr>
                  <th>Cód</th>
                  <th>Descrição</th>
                  <th>Qtd</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in itemsCarrinho"
                  id="items-carrinho"
                  :key="item.cod_produto"
                >
                  <td class="item">
                    <span>{{ item.cod_produto }}</span>
                  </td>
                  <td class="item">
                    <span>{{ item.descricao }}</span>
                  </td>
                  <td class="item">
                    <span>{{ item.qtd }}</span>
                  </td>
                  <td class="item">
                    <button @click="handleRemoveItem(item)">
                      <i class="fa fa-close" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div slot="footer" class="footer-modal">
        <button class="btn btn-primary" @click="close()">
          <i class="fa fa-close" aria-hidden="true"></i> FECHAR
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import modal from "@/components/root/modal/Modal";
import bus from "@/utils/events/bus";
import { mascaraValor } from "@/app/global";

export default {
  name: "ModalParametros",
  components: { modal },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dadosPedido: {
        razao: "",
        segmento: "",
        unidade: "",
        formaPagamento: "",
        condicaoPagamento: "",
        pedidoId: "",
      },
      itemsCarrinho: [],
    };
  },
  created() {
    this.$store.getters.carrinhoAtual.length !== 0
      ? (this.itemsCarrinho = this.$store.getters.carrinhoAtual)
      : false;

    Object.keys(this.$store.getters.dadosPedido).length !== 0
      ? (this.dadosPedido = this.$store.getters.dadosPedido)
      : false;
  },
  methods: {
    /**
     * Função responsável por remover item do carrinho
     * Chamada por botão no modal
     */

    handleRemoveItem(item) {
      item.qtd = 0;
      item.pedido_id = this.dadosPedido.pedidoId;

      this.$store.dispatch("addItemCarrinhoAtual", item);
      bus.$emit("shouldRemoveCartItem", item.cod_produto);

      this.itemsCarrinho = this.$store.getters.carrinhoAtual;
    },

    /*
     * Função: Method resposável por emitir o evento de fechar o modal de parametros.
     * Chamada por: Evento '@close' do componente 'Modal'.
     * */
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  display: flex;
  flex-direction: column;

  label {
    margin: 5px 0;
  }

  select {
    text-transform: uppercase;
  }
}
</style>

<style lang="scss">
.modal-carrinho-compra {
  position: absolute;
  z-index: 9999999;
  padding: 0;

  .modal-header {
    width: 100%;
    padding: 5px 5px;
    border-bottom: none !important;

    p {
      font-size: 14px;
      line-height: 12px;
      margin: 0;
    }

    small {
      font-size: 12px;
      line-height: 10px;
    }

    .modal-header-info {
      margin: 10px 0;
      border: 01px solid #eee;
      border-left: 4px solid #00773d;
      background: #f9f9f9;
      padding: 10px 10px;

      .session-header {
        color: #eee;
        margin-bottom: 15px;
        font-weight: 600;
        font-family: "Montserrat";

        &.green {
          color: #00773d !important;
        }
      }

      .modal-header-info-items {
        .item-header-info {
          display: block;
          margin-right: 10px;

          span {
            font-size: 14px;
            font-weight: bold;
            margin-right: 0px;
          }
        }
      }
    }
  }

  .modal-container {
    .container-table {
      padding: 0px 5px 5px 5px;
      max-height: 300px;

      .table-items {
        width: 100%;
        border-collapse: separate !important;
        border-spacing: 0 5px;
        margin: 0 !important;

        thead {
          position: sticky;

          tr th {
            padding: 5px;
            border: 1px solid #eee !important;

            &:last-child {
              border: 0 !important;
            }
          }
        }

        #items-carrinho {
          .item {
            border: 1px solid #eee;
            padding: 5px;

            &:first-child {
              border-left: 2px solid #00773d;
              padding-left: 10px;
              width: 50px;
            }

            &:last-child {
              text-align: center;
              max-width: 10px;
            }

            button {
              background: transparent;
              border: 0;
              color: #f34c3c;
            }
          }
        }
        margin: 10px 0px;
      }
    }
  }
}
</style>
