<template>
  <div id="modal-save-cart">
    <Modal
      :show-modal="true"
      titulo="Seu carrinho ficará salvo por até 7 dias"
      @close="close()"
    >
      <div slot="body">
        <span>
          Após esse período, caso o pedido ainda não tenha sido finalizado, ele
          será excluído.
        </span>
      </div>
      <div slot="footer">
        <button class="btn btn-secondary-outline btn-outline" @click="saveCart">
          Salvar carrinho
        </button>
        <button class="btn btn-primary" @click="goToCheckout()">
          Concluir a compra
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/root/modal/Modal.vue";
import bus from "@/utils/events/bus";

export default {
  name: "ModalSaveCart",
  components: {
    Modal,
  },
  methods: {
    saveCart() {
      this.close();
      localStorage.removeItem("pedidoaberto");
      this.$store.dispatch("setDadosPedido", []);
      this.$store.dispatch("addItemCarrinhoAtual", "shouldCleanCart");
      bus.$emit("show-notification", [
        "success",
        "Seu carrinho foi salvo com sucesso!",
      ]);
    },
    goToCheckout() {
      this.close();
      this.$router.push({
        name: "carrinhoRota",
        params: {},
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
#modal-save-cart {
  .modal-container {
    max-width: 600px;
  }
}
</style>
