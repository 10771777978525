// import PedidoEletronico from "./components/Main.vue";

export default [
  {
    path: "/pedido-eletronico",
    name: "pedidoEletronico",
    component: () =>
      import(
        /* webpackChunkName: "view-pedido-eletronico" */ `./components/Main.vue`
      ).catch((error) => {
        location.reload();
      }),
    // props: (route) => ({ query: route.query.codigo }, { query: route.query.categoria })
  },
];
