<template>
  <div class="container preload">
    <div class="creditcard" :class="{ flipped: flipCard }">
      <div class="front">
        <div id="ccsingle"></div>
        <svg
          id="cardfront"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 750 471"
          style="enable-background: new 0 0 750 471"
          xml:space="preserve"
        >
          <g id="Front">
            <g id="CardBackground">
              <g id="Page-1_1_">
                <g id="amex_1_">
                  <path
                    id="Rectangle-1_1_"
                    class="lightcolor green"
                    d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                            C0,17.9,17.9,0,40,0z"
                  />
                </g>
              </g>
              <path
                class="darkcolor greendark"
                d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z"
              />
            </g>
            <text
              id="svgnumber"
              transform="matrix(1 0 0 1 60.106 295.0121)"
              class="st2 st3 st4"
            >
              {{ number }}

              <!-- <template v-for="(n, $index) in '#### #### #### ####'">
                <template v-if="cardNumber && cardNumber.length > $index">
                  {{ cardNumber[$index] }}
                </template>
                <template v-else>
                  *
                </template>
              </template> -->
            </text>
            <text
              id="svgname"
              transform="matrix(1 0 0 1 54.1064 410.81)"
              class="st2 st5 st6"
            >
              {{ cardHolder }}
            </text>

            <g>
              <text
                id="svgexpire"
                transform="matrix(1 0 0 1 541.4219 410.81)"
                class="st2 st5 st9"
              >
                {{ expiration }}
              </text>
            </g>
            <g id="cchip">
              <image
                v-if="brandIcon"
                x="4em"
                y="2em"
                width="10em"
                height="7em"
                :xlink:href="brandIcon"
              ></image>
              <text
                v-else
                id="svgexpire"
                transform="matrix(1 0 0 1 60.106 100)"
                class="st2 st3 st4"
              >
                {{ brand }}
              </text>
            </g>
          </g>
          <g id="Back"></g>
        </svg>
      </div>
      <div class="back">
        <svg
          id="cardback"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 750 471"
          style="enable-background: new 0 0 750 471"
          xml:space="preserve"
        >
          <g id="Front">
            <line class="st0" x1="35.3" y1="10.4" x2="36.7" y2="11" />
          </g>
          <g id="Back">
            <g id="Page-1_2_">
              <g id="amex_2_">
                <path
                  id="Rectangle-1_2_"
                  class="darkcolor greendark"
                  d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                        C0,17.9,17.9,0,40,0z"
                />
              </g>
            </g>
            <rect y="61.6" class="st2" width="750" height="78" />
            <g>
              <path
                class="st3"
                d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                    C707.1,246.4,704.4,249.1,701.1,249.1z"
              />
              <rect
                x="42.9"
                y="198.6"
                class="st4"
                width="664.1"
                height="10.5"
              />
              <rect
                x="42.9"
                y="224.5"
                class="st4"
                width="664.1"
                height="10.5"
              />
              <path
                class="st5"
                d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z"
              />
            </g>
            <text
              id="svgsecurity"
              transform="matrix(1 0 0 1 621.999 227.2734)"
              class="st6 st7"
            >
              {{ cardCvc }}
            </text>
            <!-- <g class="st8">
              <text
                transform="matrix(1 0 0 1 518.083 280.0879)"
                class="st9 st6 st10"
              >
                security code
              </text>
            </g> -->
            <rect x="58.1" y="378.6" class="st11" width="375.5" height="13.5" />
            <rect x="58.1" y="405.6" class="st11" width="421.7" height="13.5" />
            <text
              id="svgnameback"
              transform="matrix(1 0 0 1 59.5073 228.6099)"
              class="st12 st13"
            >
              {{ cardHolder }}
            </text>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardMock",
  props: {
    cardNumber: {
      default: "**** **** **** ****",
    },
    cardHolder: {
      default: "Nome e sobrenome",
    },
    cardExpiration: {
      default: "MM/AAAA",
    },
    flipCard: {
      default: false,
    },
    cardCvc: {
      default: "***",
    },
    cardBrand: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      cardMasks: {
        default: "**** **** **** ****",
        amex: "**** ****** *****",
        cvvAmex: "####",
        cvvDefault: "###",
      },
    };
  },
  computed: {
    brandIcon() {
      try {
        return require(`@/assets/img/pagamento/${brand}.png`);
      } catch (e) {
        return;
      }
    },
    brand() {
      if (!this.cardBrand) {
        return (
          this.$store.getters.siteConfig.app_name_short &&
          this.$store.getters.siteConfig.app_name_short.toUpperCase()
        );
      }
      return this.cardBrand;
    },
    getMask() {
      return this.cardBrand == "amex"
        ? this.cardMasks.amex
        : this.cardMasks.default;
    },
    number() {
      return this.getItem(this.cardNumber, this.getMask);
    },
    expiration() {
      return this.getItem(this.cardExpiration, "MM/AAAA");
    },
  },
  methods: {
    getItem(value, mask, separator = " ") {
      let i;
      let item = "";

      for (i = 0; i < mask.length; i++) {
        if (mask && mask[i]) {
          if (value && value.length > i && value[i] && !isNaN(value[i])) {
            // item += value[i];
            // if (mask[i] == " ") {
            // item += " " + value[i];
            // } else {
            item += value[i];
            // }
          } else {
            item += mask[i];
          }
        } else {
          item += separator;
        }
      }
      return item;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/_variables.scss";

body {
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-family: "Raleway";
}

.payment-title {
  width: 100%;
  text-align: center;
}

.form-container .field-container:first-of-type {
  grid-area: name;
}

.form-container .field-container:nth-of-type(2) {
  grid-area: number;
}

.form-container .field-container:nth-of-type(3) {
  grid-area: expiration;
}

.form-container .field-container:nth-of-type(4) {
  grid-area: security;
}

.field-container input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.field-container {
  position: relative;
}

.form-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto auto;
  grid-template-rows: 90px 90px 90px;
  grid-template-areas: "name name" "number number" "expiration security";
  max-width: 400px;
  padding: 20px;
  color: #707070;
}

label {
  padding-bottom: 5px;
  font-size: 13px;
}

input {
  margin-top: 3px;
  padding: 15px;
  font-size: 16px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}

.ccicon {
  height: 38px;
  position: absolute;
  right: 6px;
  top: calc(50% - 17px);
  width: 60px;
}

/* CREDIT CARD IMAGE STYLING */
// .preload * {
//   -webkit-transition: none !important;
//   -moz-transition: none !important;
//   -ms-transition: none !important;
//   -o-transition: none !important;
// }

.container {
  width: 100%;
  max-width: 400px;
  max-height: 251px;
  height: 54vw;
  padding: 20px;
}

#ccsingle {
  position: absolute;
  right: 15px;
  top: 20px;
}

#ccsingle svg {
  width: 100px;
  max-height: 60px;
}

.creditcard svg#cardfront,
.creditcard svg#cardback {
  // width: 100%;
  // -webkit-box-shadow: 1px 5px 6px 0px black;
  // box-shadow: 1px 5px 6px 0px black;
  // border-radius: 22px;
}

#generatecard {
  cursor: pointer;
  float: right;
  font-size: 12px;
  color: #fff;
  padding: 2px 4px;
  background-color: #909090;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

/* CHANGEABLE CARD ELEMENTS */
.creditcard .lightcolor,
.creditcard .darkcolor {
  -webkit-transition: fill 0.5s;
  transition: fill 0.5s;
}

.creditcard .lightblue {
  fill: #03a9f4;
}

.creditcard .lightbluedark {
  fill: #0288d1;
}

.creditcard .red {
  fill: #ef5350;
}

.creditcard .reddark {
  fill: #d32f2f;
}

.creditcard .purple {
  fill: #ab47bc;
}

.creditcard .purpledark {
  fill: #7b1fa2;
}

.creditcard .cyan {
  fill: #26c6da;
}

.creditcard .cyandark {
  fill: #0097a7;
}

.creditcard .green {
  fill: var(--brand-primary, $brand-primary);
}

.creditcard .greendark {
  fill: var(--brand-secondary, $brand-secondary);
}

.creditcard .lime {
  fill: #d4e157;
}

.creditcard .limedark {
  fill: #afb42b;
}

.creditcard .yellow {
  fill: #ffeb3b;
}

.creditcard .yellowdark {
  fill: #f9a825;
}

.creditcard .orange {
  fill: #ff9800;
}

.creditcard .orangedark {
  fill: #ef6c00;
}

.creditcard .grey {
  fill: #bdbdbd;
}

.creditcard .greydark {
  fill: #616161;
}

/* FRONT OF CARD */
#svgname {
  text-transform: uppercase;
}

#cardfront .st2 {
  fill: #ffffff;
}

#cardfront .st3 {
  font-family: "Source Code Pro", monospace;
  font-weight: 600;
}

#cardfront .st4 {
  font-size: 54.7817px;
}

#cardfront .st5 {
  font-family: "Source Code Pro", monospace;
  font-weight: 400;
}

#cardfront .st6 {
  font-size: 33.1112px;
}

#cardfront .st7 {
  opacity: 0.6;
  fill: #ffffff;
}

#cardfront .st8 {
  font-size: 24px;
}

#cardfront .st9 {
  font-size: 36.5498px;
}

#cardfront .st10 {
  font-family: "Source Code Pro", monospace;
  font-weight: 300;
}

#cardfront .st11 {
  font-size: 16.1716px;
}

#cardfront .st12 {
  fill: #4c4c4c;
}

/* BACK OF CARD */
#cardback .st0 {
  fill: none;
  stroke: #0f0f0f;
  stroke-miterlimit: 10;
}

#cardback .st2 {
  fill: #111111;
}

#cardback .st3 {
  fill: #f2f2f2;
}

#cardback .st4 {
  fill: #d8d2db;
}

#cardback .st5 {
  fill: #c4c4c4;
}

#cardback .st6 {
  font-family: "Source Code Pro", monospace;
  font-weight: 400;
}

#cardback .st7 {
  font-size: 27px;
}

#cardback .st8 {
  opacity: 0.6;
}

#cardback .st9 {
  fill: #ffffff;
}

#cardback .st10 {
  font-size: 24px;
}

#cardback .st11 {
  fill: #eaeaea;
}

#cardback .st12 {
  font-family: "Rock Salt", cursive;
}

#cardback .st13 {
  font-size: 37.769px;
}

/* FLIP ANIMATION */
.container {
  perspective: 1000px;
}

.creditcard {
  width: 100%;
  max-width: 400px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: -webkit-transform 0.6s;
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  cursor: pointer;
}

.creditcard .front,
.creditcard .back {
  position: absolute;
  width: 100%;
  max-width: 400px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  color: #47525d;
}

.creditcard .back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.creditcard.flipped {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
</style>
