<template>
  <div id="loading-item-produto">
    <div class="container">
      <div class="skeleton skeleton-slider-carousel">
        <div v-for="(value, index) in 7" :key="index" class="skeleton-wrapper">
          <div class="skeleton-wrapper-inner">
            <div class="skeleton-wrapper-body">
              <div class="skeleton-slider-item-produto"></div>
              <div class="skeleton-content-1"></div>
              <div class="skeleton-content-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingBanner",
  beforeCreate() {
    this.$emit("created");
  },
  mounted () {
    $("#loading-item-produto .skeleton-slider-carousel")
      .not(".slick-initialized")
      .slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 548,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

#loading-item-produto {
  margin-bottom: 50px;
  max-width: 100%;
}

.skeleton-wrapper {
  background: #fff;
  //border: 1px solid;
  //border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  margin: 10px 5px;
  width: 270px;
  height: 300px;
  float: left;
}

.skeleton-wrapper-inner {
  height: 110px;
  padding: 110px;
  position: relative;
}

.skeleton-wrapper-body div {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderSkeleton;
  -webkit-animation-timing-function: linear;
  background: #f6f7f8;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#f6f7f8),
    color-stop(0.2, #edeef1),
    color-stop(0.4, #f6f7f8),
    to(#f6f7f8)
  );
  background-image: -webkit-linear-gradient(
    left,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: auto;
  height: auto;
  position: relative;
}
.skeleton-wrapper-body {
  -webkit-animation-name: skeletonAnimate;
  background-image: -webkit-gradient(
    linear,
    center top,
    center bottom,
    from(deg),
    color-stop(0, red),
    color-stop(0.15, orange),
    color-stop(0.3, yellow),
    color-stop(0.45, green),
    color-stop(0.6, blue),
    color-stop(0.75, indigo),
    color-stop(0.8, violet),
    to(red)
  );
  background-image: -webkit-linear-gradient(
    135deg,
    red 0%,
    orange 15%,
    yellow 30%,
    green 45%,
    blue 60%,
    indigo 75%,
    violet 80%,
    red 100%
  );
  background-repeat: repeat;
  background-size: 50% auto;
}
.skeleton-wrapper-body div {
  position: absolute;
  right: 15px;
  left: 15px;
  top: 15px;
}
div.skeleton-slider-item-produto {
  top: 10px;
  height: 100%;
  left: 10px;
  right: 10px;
}
div.skeleton-content-1 {
  left: 10px;
  right: 10px;
  height: 20px;
}
div.skeleton-content-2 {
  left: 10px;
  width: 100px;
  height: 10px;
}
div.skeleton-content-1 {
  top: 250px;
}
div.skeleton-content-2 {
  top: 280px;
}
@-webkit-keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 1200px;
  }
}
@-webkit-keyframes skeletonAnimate {
  from {
    background-position: top left;
  }
  to {
    background-position: top right;
  }
}

#loading-item-produto .slick-next {
  right: -20px;
}

#loading-item-produto .slick-prev {
  left: -20px;
}
</style>
