import cookie from "vue-cookie";
import store from "./vuex";
import router from "./router";

import Bus from "@/utils/events/bus";

import * as dpcAxios from "@/dpcAxios";

export const logout = async () => {
  try {
    const url = `${process.env.VUE_APP_ECOMMERCE}auth/logout`;

    const { data } = await dpcAxios.connection().post(url);

    if (!data.error) {
      logoff();
      window.location.href = "/";
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err.message);
    Bus.$emit("show-notification", ["error", "Desculpe.", `${err.message}`]);
  }
};

export const logoff = () => {
  if (router.meta && router.meta.requiresAuthentication) {
    window.location.href = "/login";
  }

  store.dispatch("setIsAuthenticated", false);
  localStorage.removeItem("pedidoaberto");
  store.dispatch("setDadosPedido", {});
};

export const isAuthenticated = () => store.getters.getIsAuthenticated;

export const hasPedidoAberto = () =>
  Object.keys(this.$store.getters.dadosPedido).length !== 0;
