// import Catalogo from "./components/Main.vue";

import * as dpcAxios from "@/dpcAxios";

export default [
  {
    path: "/catalogo/:categoriaName?",
    name: "catalogoRota",
    component: () =>
      import(
        /* webpackChunkName: "view-catalogo" */ `./components/Main.vue`
      ).catch((error) => {
        location.reload();
      }),
    props: (route) => (
      { query: route.query.busca },
      { params: route.query.categoria },
      { query: route.query.fornecedor },
      { query: route.query.sub },
      { query: route.query.ordem },
      { query: route.query.visualizacao },
      { query: route.query.ordenacao },
      { query: route.query.filtroId },
      { query: route.query.cmb },
      { query: route.query.apenas_ofertas },
      { query: route.query.apenas_promocionados },
      { query: route.query.apenas_novos },
      { query: route.query.isLoginRequired }
    ),
  },
  {
    path: "/f/:filtroId",
    name: "catalogoFiltroRota",
    beforeEnter: async (to, from, next) => {
      let cmb;
      let apenas_ofertas;
      let apenas_promocionados;
      let apenas_novos;
      let isLoginRequired;

      try {
        const { data } = await dpcAxios
          .connection()
          .get(
            `${process.env.VUE_APP_APIB2B}/v1/navigation/search/filter/${to.params.filtroId}`
          );

        cmb = data.filter?.apenas_combos;
        apenas_ofertas = data.filter?.apenas_ofertas;
        apenas_promocionados = data.filter?.apenas_promocionados;
        apenas_novos = data.filter?.apenas_novos;
        isLoginRequired = data.filter?.login_required;
      } catch (error) {
        console.error("Filter error", error);

        return next({
          name: "notFound",
        });
      }

      next({
        name: "catalogoRota",
        query: {
          filtroId: to.params.filtroId,
          ...(apenas_promocionados ? { apenas_promocionados } : {}),
          ...(cmb ? { cmb } : {}),
          ...(apenas_ofertas ? { apenas_ofertas } : {}),
          ...(isLoginRequired ? { isLoginRequired } : {}),
          ...(apenas_novos ? { apenas_novos } : {}),
          search_origin: "short-link",
        },
      });
    },
  },
];
