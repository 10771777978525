<template>
  <div class="payment_container">
    <back-button route="/carrinho" text="Visualizar itens"></back-button>
    <form
      v-if="carrinhoAtual && carrinhoAtual.length > 0"
      class="payment_box"
      method="post"
      @submit.prevent
    >
      <div class="payment_title">
        <h1>Pagamento</h1>
      </div>
      <div class="payment_box_content">
        <div class="payment_container_options">
          <div class="payment_rightContent">
            <div class="payment_subtitle">
              <h1>FORMAS DE PAGAMENTO</h1>
              <div class="payment_box_btn">
                <button v-if="isCardSave | notCardSave" class="btn_creditCard">
                  <img src="@/assets/img/pagamento/cardIcon.svg" alt />
                  CARTÃO DE CRÉDITO
                </button>
                <button v-if="isBoleto" class="btn_boleto btn_active">
                  <img src="@/assets/img/pagamento/barCode.svg" />
                  BOLETO
                </button>
              </div>
            </div>
            <div>
              <div class="payment_box_alignment">
                <template v-if="!loadingUserCards">
                  <cardSave
                    v-if="isCardSave"
                    :payment-amount="totalLiquido"
                    @addNewCard="changeBox()"
                  />
                  <boleto v-if="isBoleto" />
                  <creditCard
                    v-if="notCardSave"
                    :payment-amount="totalLiquido"
                  />
                </template>
                <loading-payment-info v-else></loading-payment-info>
              </div>
            </div>
          </div>
          <div class="payment_leftContent">
            <div class="payment_address_resume">
              <div class="icon_local">
                <img src="@/assets/img/pagamento/local.svg" alt />
              </div>
              <div class="client_address_info">
                <div class="icon_local-Mobile">
                  <img src="@/assets/img/pagamento/local.svg" alt />
                </div>
                <div style="padding-bottom: 10px" class="address_resume-main">
                  <h3>LOCAL DE ENTREGA</h3>
                </div>

                <p>{{ dadosUser.endereco }}</p>
              </div>
            </div>

            <div class="payment_address_resume">
              <div class="icon_local">
                <img src="@/assets/img/pagamento/box.svg" alt />
              </div>
              <div class="client_address_info">
                <div class="icon_local-Mobile">
                  <img src="@/assets/img/pagamento/box.svg" alt />
                </div>
                <div class="empresaResumo">
                  <h3>TOTAL DE PRODUTOS</h3>
                </div>
                <p>{{ totalProduct }} Produtos</p>
              </div>
            </div>

            <div class="payment_address_resume">
              <div class="icon_local">
                <img src="@/assets/img/pagamento/nota.svg" alt />
              </div>
              <div class="client_address_info">
                <div class="icon_local-Mobile">
                  <img src="@/assets/img/pagamento/nota.svg" alt />
                </div>
                <div class="empresaResumo">
                  <h3>EMPRESA</h3>
                </div>
                <p>{{ dadosUser.nome }}</p>
                <p>{{ dadosUser.cpfcnpj }}</p>
              </div>
            </div>
            <div class="payment_finish_total">
              <h3>VOCÊ IRÁ PAGAR</h3>
              <h1>R${{ productAmmount }}</h1>
            </div>

            <information
              v-if="errorMessage"
              message-type="error"
              :message="errorMessage"
            ></information>

            <div class="buttonFinishDiv">
              <!-- <button class="btn_finalizarCompra" @click="sendPayment()">FINALIZAR PEDIDO</button> -->

              <place-order-btn
                btn-text="FINALIZAR PEDIDO"
                :loading="loadingFinalizar"
                :disabled="validaValorRegra"
                @placeOrder="sendPayment()"
              ></place-order-btn>

              <!-- Improve later  -->
              <div
                v-if="!isBoleto"
                style="padding: 15px 0; font-weight: 600; font-size: 13px"
              >
                <span
                  >Após o faturamento do seu pedido, caso ocorra algum corte e o
                  valor faturado fique inferior ao pago, a operadora do seu
                  cartão estornará a diferença em até 5 dias.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div v-else class="container">
      <div class="empty-cart">
        <div class="empty-cart-header">
          <i class="fa fa-truck" />
          <h1>Seu caminhão está vazio!</h1>

          <h2>
            <a style="margin-top: 1px" @click="handleButtonCatalogo()"
              >Clique aqui</a
            >
            para visualizar nossos produtos.
          </h2>

          <button
            class="btn-submit btn-primary"
            @click="handleButtonCatalogo()"
          >
            VER CATÁLOGO
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import creditCard from "@/components/credit-card/credit-card.vue";
import cardSave from "@/components/card-save/index.vue";
import boleto from "@/components/boleto/index.vue";
import bus from "@/utils/events/bus";
import * as global from "@/app/global";
import { mascaraValor } from "@/app/global";

import PlaceOrderBtn from "../../components/PlaceOrderBtn";
import Information from "../../components/Information";

import loadingPaymentInfo from "./LoadingPaymentInfo";
import BackButton from "../../components/BackButton";

export default {
  name: "Catalogo",
  components: {
    creditCard,
    cardSave,
    boleto,
    PlaceOrderBtn,
    Information,
    loadingPaymentInfo,
    BackButton,
  },
  data() {
    return {
      isCardSave: false,
      productAmmount: 0,
      cardID: null,
      dadosUser: {
        nome: null,
        email: null,
        telefone: null,
        cpfcnpj: null,
        endereco: null,
        seqrede: null,
      },
      pedidoID: Number,
      totalProduct: 0,
      notCardSave: false,
      isBoleto: false,
      errorMessage: null,
      loadingFinalizar: false,
      loadingUserCards: false,
    };
  },
  computed: {
    carrinhoAtual() {
      return this.$store.getters.carrinhoAtual;
    },
    dadosPedido() {
      return this.$store.getters.dadosPedido;
    },
    hasDisabledItem() {
      return this.carrinhoAtual.find((item) => item.status === 0);
    },
    validaValorRegra() {
      if (this.valorRegra > this.totalLiquido && this.totalLiquido != 0) {
        return true;
      }
      return false;
    },
    valorProdutos() {
      return mascaraValor(this.totalProdutos);
    },
    totalLiquido() {
      return this.totalProdutos + this.totalSt - this.totalDesconto;
    },
    totalProdutos() {
      return this.carrinhoAtual.reduce((ac, item) => {
        if (item.valor > 0 && item.qtd > 0 && item.status != 0)
          return ac + item.valor * item.qtd;

        return ac;
      }, 0);
    },
    totalDesconto() {
      return this.carrinhoAtual.reduce((ac, item) => {
        if (item.vlrdesconto > 0 && item.qtd > 0 && item.status != 0)
          return ac + item.vlrdesconto * item.qtd;

        return ac;
      }, 0);
    },
    totalSt() {
      return this.carrinhoAtual.reduce((ac, item) => {
        if (item.vlrst > 0 && item.qtd > 0 && item.status != 0)
          return ac + item.vlrst * item.qtd;

        return ac;
      }, 0);
    },
    valorSt() {
      return mascaraValor(this.totalSt);
    },
    valorDesconto() {
      return mascaraValor(this.totalDesconto);
    },
    valorLiquido() {
      return mascaraValor(this.totalLiquido);
    },
    paymentData() {
      return this.$store.getters.dadosPedido.paymentData;
    },
  },
  beforeCreate() {
    const dadosPedido = this.$store.getters.dadosPedido;
    if (!dadosPedido.pedidoId || !Account.isAuthenticated()) {
      this.$router.push({ name: "MinhaConta" });
    }

    // Só fica nessa tela se for cartão. No futuro, ficará também quando for boleto.
    if (dadosPedido.formaPagamento != 4) {
      this.$router.push({ name: "carrinhoRota" });
    }
  },
  created() {
    this.verifyPaymenthMethod();
    this.buscaDadosUsuario();
    this.getCarrinhoAtual();
    this.productAmmount = mascaraValor(this.totalLiquido);
    // this.changeBox();
    this.$store.dispatch("faturamentoData", this.dadosUser);

    // console.log(this.$store);
  },
  methods: {
    /**
     * Função responsável por limpar o carrinho quando o pedido é finalizado
     * Chamada ao click do botão no resumo do pedido
     */

    limpaCarrinho() {
      this.$store.dispatch("addItemCarrinhoAtual", "shouldCleanCart");
      this.$store.dispatch("setDadosPedido", {});
    },

    /*
     * Função responsavel por mudar box para boleto
     */
    mudarBoleto() {
      if (this.isBoleto == false) {
        this.notCardSave = false;
        this.isCardSave = false;
        this.isBoleto = true;
      }
    },

    /**
     * Função responsavel por buscar dados do usuário
     */
    buscaDadosUsuario() {
      if (this.$store.getters.dadosPedido.razao) {
        dpcAxios
          .connection()
          .get(
            process.env.VUE_APP_ECOMMERCE +
              api.env.MINHACONTA +
              this.$store.getters.dadosPedido.razao
          )
          .then((response) => {
            if (response.data.error == 0) {
              var cadastro = response.data.cadastro;
              this.dadosUser.nome = cadastro.nomerazao;
              this.dadosUser.email = cadastro.email;

              this.dadosUser.cpfcnpj =
                cadastro.cpfcnpj != null
                  ? global.mascaraCpfCnpj(cadastro.cpfcnpj)
                  : null;

              this.dadosUser.seqrede = cadastro.seqrede;
              // formata endereço
              var endereco = "";
              if (cadastro.logradouro != null) {
                endereco += `${cadastro.logradouro}, `;
              }
              if (cadastro.nrologradouro != null) {
                endereco += `${cadastro.nrologradouro}, `;
              }
              if (cadastro.bairro != null) {
                endereco += `${cadastro.bairro}, `;
              }
              if (cadastro.cidade != null) {
                endereco += `${cadastro.cidade} - `;
              }

              if (cadastro.uf != null) {
                endereco += `${cadastro.uf}, `;
              }

              if (cadastro.cep != null) {
                endereco += global.marcaraCep(cadastro.cep);
              }

              this.dadosUser.endereco = endereco;

              // formata telefone
              var telefone = "";
              var fone1 = cadastro.fonenro1 != null ? cadastro.fonenro1 : "";
              fone1 = fone1 != "" ? global.mascaraTelefone(fone1) : "";

              var fone2 = cadastro.fonenro2 != null ? cadastro.fonenro2 : "";
              fone2 = fone2 != "" ? global.mascaraTelefone(fone2) : "";

              var fone3 = cadastro.fonenro3 != null ? cadastro.fonenro3 : "";
              fone3 = fone3 != "" ? global.mascaraTelefone(fone3) : "";

              telefone = fone1;
              telefone +=
                fone2 != "" ? (telefone != "" ? ` | ${fone2}` : fone2) : "";
              telefone +=
                fone3 != "" ? (telefone != "" ? ` | ${fone3}` : fone3) : "";

              this.dadosUser.telefone = telefone;
            } else {
              bus.$emit("show-notification", [
                "error",
                "Desculpe.",
                "Algo deu errado ao buscar os dados do seu usuário, favor tentar novamente mais tarde.",
              ]);
            }
          })
          .catch((e) => {
            this.showBlackModal = false;
            bus.$emit("show-notification", [
              "error",
              "Desculpe.",
              e.response.status == 429
                ? "Muitas requisições realizadas em pouco tempo, favor tentar novamente dentro de alguns minutos."
                : "Algo deu errado ao buscar os dados do seu usuário, favor tentar novamente mais tarde.",
            ]);
          });
      }
    },

    /**
     * Função reponsável por enviar pagamento para api é limpar pedido em aberto
     */
    async sendPayment() {
      this.errorMessage = null;

      if (this.isBoleto) {
        this.payWithBoleto();
      } else {
        this.payWithCard();
      }
      /**
       * Verificar se os campos estão preenchidos
       *  => SE FORMA DE PAGAMENTO FOR IGUAL AO BOLETO ENVIAR ID DO PEDIDO PARA API
       *  => CASO FOR CARTÃO ENVIAR payment PARA O MESMO URL JUNTO COM
       * O pedidoID
       *  => CASO CARTÃO ESTEJA SALVO ENVIAR cardID É pedidoID
       */
      // if (this.isCardSave) {
      //   this.loadingFinalizar = true;
      //   try {
      //     /** Fazer requisição na api e verificar se o usuário tem cartão salvo  */
      //     const response = await dpcAxios
      //       .connection()
      //       .post(
      //         process.env.VUE_APP_ECOMMERCE +
      //           api.env.FINALIZAR_PEDIDO_CARTAO_EC,
      //         {
      //           pedido_id: this.$store.getters.dadosPedido.pedidoId,
      //           card_id: this.$store.getters.savedCardSelected,
      //           card_cvv: this.$store.getters.cvvCardSave,
      //         }
      //       );
      //     const { error, message } = response.data;
      //     if (error == 0) {
      //       this.$store.dispatch("setCartaoSucesso", true);
      //       bus.$emit("show-notification", ["success", "Sucesso", message]);
      //       localStorage.removeItem("pedidoaberto");
      //       this.limpaCarrinho();
      //       // window.location.href = "/minha-conta/pedidos";
      //       this.$router.push({ name: "MinhaContaPedidos" });
      //     } else {
      //       this.$store.dispatch("setCartaoErro", true);
      //       this.errorMessage = message;
      //     }
      //   } catch (e) {
      //     console.error(e);
      //     this.errorMessage =
      //       "Desculpe, ocorreu um erro ao finalizar seu pedido. Por favor, tente novamente.";
      //   }

      //   this.loadingFinalizar = false;
      // }
      // if (this.isBoleto) {

      // }
      // if (this.notCardSave) {
      //   if (!this.validatePaymentData()) {
      //     return;
      //   }

      //   this.loadingFinalizar = true;

      //   try {
      //     const response = await dpcAxios
      //       .connection()
      //       .post(
      //         process.env.VUE_APP_ECOMMERCE +
      //           api.env.FINALIZAR_PEDIDO_CARTAO_EC,
      //         {
      //           pedido_id: this.$store.getters.dadosPedido.pedidoId,
      //           card: this.$store.getters.paymentData,
      //         }
      //       );
      //     const { error, message } = response.data;
      //     if (error == 0) {
      //       this.$store.dispatch("setCartaoSucesso", true);
      //       bus.$emit("show-notification", ["success", "Sucesso", message]);
      //       localStorage.removeItem("pedidoaberto");
      //       this.limpaCarrinho();
      //       this.$router.push({ name: "MinhaContaPedidos" });
      //     } else {
      //       this.$store.dispatch("setCartaoErro", true);
      //       this.errorMessage = message;
      //     }
      //   } catch (e) {
      //     console.error(e);
      //     this.errorMessage =
      //       "Desculpe, ocorreu um erro ao finalizar seu pedido. Por favor, tente novamente!";
      //   }

      //   this.loadingFinalizar = false;
      // }
    },
    async payWithBoleto() {
      this.loadingFinalizar = true;
      try {
        const response = await dpcAxios
          .connection()
          .post(process.env.VUE_APP_ECOMMERCE + api.env.FINALIZAR_PEDIDO_EC, {
            pedido_id: this.$store.getters.dadosPedido.pedidoId,
          });
        const { error, message } = response.data;
        if (error == 0) {
          this.$store.dispatch("setCartaoSucesso", true);
          bus.$emit("show-notification", ["success", "Sucesso", message]);
          localStorage.removeItem("pedidoaberto");
          this.limpaCarrinho();
          // window.location.href = "/minha-conta/pedidos";
          this.$router.push({ name: "MinhaContaPedidos" });
        } else {
          this.$store.dispatch("setCartaoErro", true);
          this.errorMessage = message;
        }
      } catch (e) {
        console.error(e);
        this.errorMessage =
          "Desculpe, ocorreu um erro ao finalizar seu pedido. Por favor, tente novamente.";
      }

      this.loadingFinalizar = false;
    },
    async payWithCard() {
      if (!this.validatePaymentData()) {
        return;
      }

      let params = {
        pedido_id: this.$store.getters.dadosPedido.pedidoId,
      };

      if (this.isCardSave) {
        params.card = {
          card_id: this.$store.getters.savedCardSelected,
          cvv: this.$store.getters.cvvCardSave,
        };
      } else {
        // params.card = this.$store.getters.paymentData;

        const paymentData = this.$store.getters.paymentData;
        params.card = {
          cardBrand: paymentData.cardBrand,
          cardCvv: String(paymentData.cardCvv),
          cardMonth: String(paymentData.cardMonth),
          cardName: paymentData.cardName,
          cardNumber: paymentData.cardNumber.replace(/\s/g, ""),
          // cardParcels: 3,
          cardYear: paymentData.cardYear,
          saveCard: paymentData.saveCard,
          cardDocument: paymentData.cardDocument,
        };
      }

      this.loadingFinalizar = true;
      try {
        const response = await dpcAxios
          .connection()
          .post(
            process.env.VUE_APP_ECOMMERCE + api.env.FINALIZAR_PEDIDO_EC,
            params
          );
        const { error, message } = response.data;
        if (error == 0) {
          this.$store.dispatch("setCartaoSucesso", true);
          bus.$emit("show-notification", ["success", "Sucesso", message]);
          localStorage.removeItem("pedidoaberto");
          this.limpaCarrinho();
          // window.location.href = "/minha-conta/pedidos";
          this.$router.push({ name: "MinhaContaPedidos" });
        } else {
          this.$store.dispatch("setCartaoErro", true);
          this.errorMessage = message;
        }
      } catch (e) {
        console.error(e);
        this.errorMessage =
          "Desculpe, ocorreu um erro ao finalizar seu pedido. Por favor, tente novamente.";
      }

      this.loadingFinalizar = false;
    },
    // TODO: Mover função para mixin ou algo do tipo. E melhorá-la. Exibir erros nos locais corretos, como na parte inferior do input, por exemplo
    // e mudar o border-color do mesmo para vermelho. Exemplo: https://uxplanet.org/how-to-write-good-error-messages-858e4551cd4
    validatePaymentData() {
      this.errorMessage = null;
      let message = null;

      if (!this.isCardSave) {
        const paymentData = this.$store.getters.paymentData;
        if (!paymentData) {
          message =
            "Por favor, verifique se você inseriu corretamente suas informações de pagamento.";
        }

        if (!paymentData.cardMonth || !paymentData.cardYear) {
          message = "Por favor, informe o mês e ano de vencimento do cartão";
        }

        if (!paymentData.cardCvv) {
          message = "Por favor, informe o código de segurança do cartão";
        } else if (
          paymentData.cardCvv.length < 3 ||
          paymentData.cardCvv.length > 4
        ) {
          message =
            "Prezado, o código de segurança (CVV) deve ter 3 ou 4 dígitos.";
        }

        if (!paymentData.cardName) {
          message = "Por favor, insira o nome do títular do cartão";
        }

        if (!paymentData.cardNumber) {
          message = "Por favor, informe o número do cartão";
        }
      } else if (this.isCardSave) {
        if (!this.$store.getters.savedCardSelected) {
          message = "Por favor, selecione um cartão.";
        }

        if (!this.$store.getters.cvvCardSave) {
          message = "Por favor, digite o código de segurança do cartão.";
        }
      }

      if (message) {
        this.errorMessage = message;
        return false;
      }

      return true;
    },
    /**
     * Ao clickar no botão de adicionar novo cartão,
     * colocar componenter credit-card visível
     */
    changeBox() {
      if (this.isCardSave) {
        this.isCardSave = false;
        this.isBoleto = false;
        this.notCardSave = true;
      } else {
        this.isCardSave = true;
        this.isBoleto = false;
        this.notCardSave = false;
      }
    },

    changeToCard() {
      this.verifyUserSaveCard();
    },

    /**
     * Função responsável por pegar total de itens no carrinhho
     */
    getCarrinhoAtual() {
      for (
        let carrinhoqt = 0;
        carrinhoqt < this.carrinhoAtual.length;
        carrinhoqt++
      ) {
        const qtProduct = this.carrinhoAtual[carrinhoqt].qtd;
        this.totalProduct = qtProduct + this.totalProduct;
      }
    },
    /**
     * Função responsável por verificar se existe cartão salvo do usuário
     */
    async verifyUserSaveCard() {
      // Entra na rota, verifica se existe cartão salvo do usuário,
      // se tem seta isCardSave = true caso não tenha seta isCardSave = false

      // nao deixar buscar cartao salvo, ainda nao implementado no servico de pagamentos.
      // this.notCardSave = true;
      // return false;

      this.loadingUserCards = true;
      try {
        const response = await dpcAxios
          .connection()
          .get(process.env.VUE_APP_ECOMMERCE + api.env.BUSCA_CARTAO_SALVO);
        if (response.data && response.data.cards.length > 0) {
          this.$store.dispatch("saveCards", response.data.cards);
          this.isCardSave = true;
          this.notCardSave = false;
          this.isBoleto = false;
        } else {
          this.notCardSave = true;
        }
      } catch (error) {
        this.notCardSave = true;
      }

      this.loadingUserCards = false;
    },

    verifyPaymenthMethod() {
      if (this.dadosPedido.formaPagamento == 1) {
        this.isBoleto = true;
      } else {
        this.verifyUserSaveCard();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.payment_container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  background-color: #eee;
}

.payment_box {
  display: flex;
  width: 100%;
  // min-height: 720px;
  flex-direction: column;

  box-sizing: border-box;

  padding: 0 20px;
  margin: 0 auto;
}
.payment_title {
  padding-top: 20px;
  padding-bottom: 20px;
}

.payment_subtitle h1 {
  all: unset;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.02em;
}
.payment_title h1 {
  all: unset;
  font-weight: bold;
  font-size: 20px;
}
.payment_box_content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #e5e5e5;
  margin-bottom: 50px;
  background-color: white;
}

@media (max-width: 518px) {
  .payment_box {
    padding: 5px;
    margin: 0;
    // width: 100%;
  }
}

.btn_creditCard,
.btn_boleto {
  all: unset;
  width: 165.18px;
  height: 41px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  border: 1px solid #e5e5e5;
}

.btn_creditCard,
.btn_boleto:hover {
  border: 1px solid #226e48;
  color: #226e48;
}

.btn_active {
  border: 1px solid #226e48;
  color: #226e48;
}

.payment_box_btn {
  display: flex;
}
.payment_rightContent {
  display: flex;
  flex-direction: column;
  flex: 1 1 55%;
  border-right: 1px solid #e5e5e5;
  // padding-right: 30px;
}
.payment_box_alignment {
  display: flex;
}
.saveCardInput {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
}

.payment_leftContent {
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
  height: 100%;
  margin-left: 50px;
}

.payment_card_data {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-left: 50px;
}
.payment_container_options {
  display: flex;
}
.payment_card_box {
  display: flex;
  flex-direction: column;
}
.expirationDate_container label {
  margin-bottom: 8px;
}

.payment_card_data input,
select {
  width: 90%;
  height: 35px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 10px;
}

.payment_card_data select {
  padding: 0;
  height: 40px;
}
.payment_card_data label {
  margin-bottom: 7px;
}
.expirationDate_container {
  display: flex;
  padding-bottom: 40px;
}
.expirationDate_container input {
  width: 88px;
  height: 45px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 10px;
}
.expirationDate_box {
  display: flex;
  flex-direction: column;
}
.payment_cvv {
  display: flex;
  flex-direction: column;
}

.expirationDate_container {
  width: 83%;
  display: flex;
  flex-direction: row;
}
.expirationDate_box {
  display: flex;
  margin-right: 15px;
}
.card_rightContent {
  width: 40%;
}
.payment_address_resume {
  display: flex;
  margin-bottom: 20px;
}

.icon_local img {
  width: 30px;
}
.client_address_info {
  margin-left: 10px;
}
.client_address_info h3 {
  all: unset;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: #000;
}
.client_address_info p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.03em;
  margin-bottom: 3px;
  color: #979797;
}
.btn_finalizarCompra {
  all: unset;
  width: 100%;
  height: 50px;
  background: var(--brand-primary, $brand-primary);
  border-radius: 3px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.1em;
  cursor: pointer;
  transition: 200ms ease-in;
}
.btn_finalizarCompra:hover {
  background-color: #226e48;
}

.btn_boleto {
  justify-content: center;
}
.btn_boleto img {
  margin-right: 10px;
}

.payment_finish_total {
  margin-top: 30px;
  margin-bottom: 30px;
}
.payment_finish_total h3 {
  all: unset;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
}

.payment_finish_total h1 {
  all: unset;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #303932;
  margin-top: 3px;
}
.saveCardBox {
  display: flex;
  width: 130px;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
.saveCardBox label,
input {
  padding: 0;
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #303932;
}

.icon_local-Mobile img {
  width: 50px;
  margin: 25px;
}
.icon_local-Mobile {
  display: none;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}
.empty-cart {
  padding: 20px;

  .empty-cart-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    i {
      font-size: 250px;
      color: #c9c9c9;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    h1 {
      font-size: 32px;
      font-weight: 300;
      margin: 10px 0px 0px 0px;
      text-align: center;
    }

    h2 {
      font-size: 28px;
      font-weight: 200;
      margin: 10px 0px 15px 0px;
      text-align: center;

      a {
        color: var(--brand-primary, $brand-primary);
        cursor: pointer;
      }
    }

    button {
      padding: 10px 30px;
      font-weight: 600;
    }
  }
}

/* RESPONSIVIDADE */
@media (min-width: 1500px) {
  .payment_box {
    max-width: 1500px;
    // margin: 0px;
  }
}

@media (max-width: 1000px) {
  .payment_container_options {
    display: flex;
    flex-direction: column;
  }
  .payment_box_content {
    padding: 20px;
  }
  .payment_rightContent {
    width: 100%;
    border: unset;
  }
  .payment_leftContent {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
  .buttonFinishDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .payment_address_resume {
    margin-top: 30px;
  }
}

@media (min-width: 700px) {
  .icon_local {
    display: block;
  }
  .icon_local-Mobile {
    display: none;
  }
}

@media (max-width: 518px) {
  .payment_box {
    // margin: 10px;
  }
}

@media (max-width: 414px) {
  .payment_box_content {
    padding: 10px;
  }
  .btn_creditCard,
  .btn_boleto {
    // width: 50%;
    height: 40px;
    font-size: 10px;
    padding: 9px;
    margin-top: 20px;
    margin-bottom: 5px;
    margin-right: 10px;
    margin-left: 10px;
  }
}
@media (max-width: 400px) {
  .btn_creditCard {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .payment_box_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .payment_subtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .payment_leftContent {
    width: 100%;
    margin-left: 0;
  }
  .icon_local {
    display: none;
  }
  .icon_local-Mobile {
    display: flex;
  }
  .address_resume-main {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .client_address_info p {
    text-align: center;
  }
  .client_address_info {
    margin-left: unset;
  }
  .payment_address_resume {
    align-items: center;
    justify-content: center;
  }
  .client_address_info h3 {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .empresaResumo {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
  }
  .payment_finish_total {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    border-top: 1px solid #e5e5e5;
  }
  .payment_finish_total h1,
  h3 {
    font-size: 20px;
  }
  .buttonFinishDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
</style>
