<template>
  <div>
    <modal
      :show-modal="showModal"
      class="modal-change-password"
      :titulo="'Criação de usuário'"
      @close="close()"
    >
      <div slot="body">
        <div class="modal-container">
          <div class="cliente">
            <div class="cliente-container">
              <form @submit.prevent="onSubmit">
                <div class="form-group">
                  <div class="dados-pessoais">
                    <div class="form-group col-md-6 col-xs-12">
                      <label>Cnpj</label>
                      <input
                        id="cpf-cpnj"
                        v-model="$v.formResponses.cpf_cnpj.$model"
                        v-validate="'required'"
                        v-mask="['###.###.###-##', '##.###.###/####-##']"
                        type="text"
                        class="form-control"
                        name="cpf"
                        autocomplete="off"
                        placeholder="CNPJ"
                      />
                    </div>
                    <div class="form-group col-md-6 col-xs-12">
                      <label>Email</label>
                      <input
                        id="email"
                        v-model="$v.formResponses.email.$model"
                        v-validate="'required|email'"
                        name="email"
                        type="text"
                        class="form-control"
                        autocomplete="off"
                        maxlength="60"
                        placeholder="Email cadastrado na empresa"
                      />
                    </div>
                    <div class="col-md-6 col-xs-12">
                      <div class="recaptcha-mobile">
                        <vue-recaptcha
                          ref="recaptcha"
                          size="normal"
                          :sitekey="recaptchaKey"
                          @expired="onExpired"
                          @verify="onVerify"
                        ></vue-recaptcha>
                      </div>
                    </div>
                    <div
                      class="footer-cadastro-usuario col-md-12 col-xs-12 margin-bottom"
                    >
                      <button
                        type="submit"
                        :disabled="cadastroLoading"
                        class="btn btn-primary"
                        @click.prevent="saveDadosUser()"
                      >
                        Cadastrar
                        <i
                          v-if="cadastroLoading"
                          class="fa fa-spinner fa-spin"
                        ></i>
                      </button>
                    </div>
                    <div class="form-group checkbox col-md-12 col-xs-12">
                      <div v-if="errors" class="error alert-error" role="alert">
                        O formulário acima possui erros, favor corrigir e enviar
                        novamente.
                      </div>

                      <div v-show="error" class="alert-error" role="alert">
                        {{ msg_retorno }}
                      </div>
                      <div
                        v-show="submit_success"
                        class="alert-success"
                        role="alert"
                      >
                        {{ msg_retorno }}
                      </div>
                    </div>
                    <div class="form-group checkbox col-md-12 col-xs-12">
                      <div class="alert">
                        <strong>Atenção:</strong> Seu cadastro só será efetuado
                        caso possua todos os dados acima cadastrados em nosso
                        banco de dados. Em caso de falha, entre em contato e
                        atualize seus dados. Telefones:
                        <a href="tel://(33) 3329-0000"> (33) 3329-0000</a>,
                        <a href="tel://0800 283 0000">0800 283 0000</a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="footer-modal"></div>
    </modal>
  </div>
</template>
<script src="https://www.google.com/recaptcha/api.js" async defer></script>

<script>
import modal from "@/components/root/modal/Modal";
import bus from "@/utils/events/bus";
import { required, email } from "vuelidate/lib/validators";
import cookie from "vue-cookie";
import { mask } from "vue-the-mask";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "ModalChangePassword",
  directives: { mask },
  components: { modal, VueRecaptcha },

  data() {
    return {
      recaptchaKey: this.$store.getters.siteConfig.app_recaptcha_key_v2,
      formResponses: {
        email: "",
        cpf_cnpj: "",
      },
      cadastroLoading: false,
      verificador: "",
      errors: false,
      error: false,
      msg_retorno: "",
      formVerificado: false,
      submit_success: false,
    };
  },
  validations: {
    formResponses: {
      email: {
        email,
        required,
      },
      cpf_cnpj: {
        required,
      },
    },
  },
  methods: {
    /*
     * Função: Method resposável por emitir o evento de fechar o modal de parametros.
     * Chamada por: Evento '@close' do componente 'Modal'.
     * */

    close() {
      this.$emit("close");
    },

    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    onSubmit() {
      this.$refs.invisibleRecaptcha.execute();
    },
    //VerificaRecaptcha Google
    onExpired() {
      this.formVerificado = false;
    },
    onVerify(response) {
      this.verificador = response;
      this.formVerificado = true;
    },
    async saveDadosUser() {
      this.formTouched = !this.$v.formResponses.$anyDirty;
      this.errors =
        this.$v.formResponses.$anyError || !this.$v.formResponses.$anyDirty;
      this.retornoMensagem = "";
      this.retornoErro = false;
      this.error = false;

      if (this.formVerificado == false) {
        this.msg_retorno = "Prezado, o teste de segurança é obrigatório.";
        this.error = true;
        this.cadastroLoading = false;
      }

      if (this.errors === false && this.formTouched === false) {
        const { data } = await dpcAxios
          .connection()
          .post(process.env.VUE_APP_ECOMMERCE + "ec/auth/precadastro", {
            email: this.formResponses.email,
            documento: this.formResponses.cpf_cnpj,
            verificador: this.verificador,
          });

        if (data.error == 0) {
          this.error = false;
          this.msg_retorno = data.mensagem;
          this.submit_success = true;
          //Fecha o modal após alterar a senha
          setTimeout(() => {
            this.close();
          }, 3000);
        } else {
          this.error = 1;
          this.msg_retorno = data.mensagem;
        }

        this.resetRecaptcha();
        this.cadastroLoading = false;
      }
    },
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/_variables.scss";
.modal-container {
  .texto-riscado {
    color: #1c8c48;
    text-decoration: line-through;
  }

  i {
    color: #1c8c48;
  }

  input {
    padding: 10px 20px;
    margin: 10px 0 0 0;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 14px;
    padding: 5px 15px;
    background: none;
    color: #286d46;

    &:hover,
    &:focus {
      border-color: $brand-primary;
    }

    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(0, 152, 64, 0.45);
    }
  }

  button {
    transition: 0.3s ease;
    background: $brand-primary;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    box-shadow: 3px 10px 20px 0px rgba(0, 152, 64, 0.45);
    color: #fff;
    cursor: pointer;

    &:hover {
      box-shadow: 3px 10px 20px 0px rgba(0, 152, 64, 0.3);
    }
  }

  .error {
    color: #f34c3c;
  }
}
</style>

<style lang="scss">
@import "~@/assets/css/sass/bootstrap/_variables.scss";
.alert {
  padding: 20px;
  background-color: #ff9800;
  color: white;
  margin-bottom: 0;
}

.alert-success {
  padding: 20px;
  background-color: #1c8c48;
  color: white;
  margin-bottom: 0;
}

.alert-error {
  padding: 20px;
  background-color: #bb0000;
  text-align: center;
  color: white !important;
  margin-bottom: 10px;
}

footer {
  clear: both;
}
</style>
