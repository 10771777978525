import Campanha from "./components/main.vue";

export default [
  {
    path: "/campanhas/:campanhaName?",
    name: "campanhaMain",
    component: Campanha,
    meta: {
      requiresAuthentication: true,
    },
    props: (route) => ({ query: route.query.buscaCampanha }),
  },
];
