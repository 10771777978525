export default {
  itens_selecionados: [],
  categoria_selecionada: [],
  categorias: [],
  componente_categorias: [],
  parceiros: [],
  parceirosFiltrados: [],
  marcasFiltradas: [],
  modoVisualizacao: "",
  cartaoSucesso: "",
  cartaoErro: "",
};
