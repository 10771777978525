<template>
  <div class="alertNotFoundCampanha">
    <div class="main">
      <span>Nenhuma oferta exclusiva disponível no momento.</span><br />
    </div>
    <div class="buttonContainer">
      <router-link
        :to="{
          name: 'catalogoRota',
          query: { apenas_ofertas: true, isLoginRequired: true },
        }"
      >
        <button class="btn btn-primary">Ver todas as ofertas</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertNotFoundCampanha",
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";
.alertNotFoundCampanha {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 60px 0;

  .main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 80%;
    font-size: 30px;
    font-weight: 600;
    color: var(--brand-primary, $brand-primary);

    i {
      font-size: 150px;
    }
  }

  .buttonContainer {
    margin: 20px 0;
  }
}
</style>
