<template>
  <div id="middle-top-menu">
    <div class="input-group input-group-search search">
      <input
        id="input-search"
        v-model="busca"
        type="text"
        class="form-control input-search"
        placeholder="Deseje, encontre, realize!"
        autocomplete="off"
        @keyup="paginaCatalogo"
        @focus="exibirPopover()"
      />
      <span
        id="input-search-submit"
        class="input-group-btn"
        @click="closeOverlay()"
      >
        <a @click="buscaTermoDigitado()">
          <button class="btn btn-search" aria-label="Pesquisar" type="button">
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </a>
      </span>
    </div>
    <div
      v-if="$store.getters.isDpcMobileWebView"
      style="margin: 0px 10px 10px -5px"
      class="input-group"
      @click="openBarcodeScanner()"
    >
      <button class="btn btn-md btn-lemon">
        <i class="mdi mdi-barcode-scan"></i>
      </button>
    </div>
    <Popover
      :active="exibir"
      :blackmodal="true"
      :ajuste-comp="true"
      @closeOverlay="closeOverlay()"
    >
      <OpcaoBusca
        :busca="busca"
        :ultimas-buscas="pesquisarecentes"
        :search-type="selected"
        @closeOverlay="closeOverlay()"
      ></OpcaoBusca>
    </Popover>
  </div>
</template>

<script>
import cookie from "vue-cookie";
import Popover from "@/components/popover/Popover";
import OpcaoBusca from "@/components/popover/OpcaoBusca";
import * as lastSearch from "@/services/cookie/lastSearch";

export default {
  name: "MiddleTop",
  components: { Popover, OpcaoBusca },
  data() {
    return {
      selected: localStorage.getItem("searchtype")
        ? localStorage.getItem("searchtype")
        : localStorage.setItem("searchtype", "contain"),
      busca: "",
      exibir: false,
      pesquisarecentes: [],
      fetchedSearchs: [],
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.getIsAuthenticated;
    },
  },
  watch: {
    "$route.query": function (query) {
      if (!!this.busca && !query.busca) {
        this.closeOverlay();
      }
      this.busca = query.busca ? query.busca : "";
    },
    "$store.getters.getIsAuthenticated": function (isAuthenticated) {
      if (isAuthenticated) {
        this.fetchData();
      }
    },
  },
  mounted() {
    this.busca = this.$route.query.busca ? this.$route.query.busca : "";
    this.selected = localStorage.getItem("searchtype")
      ? localStorage.getItem("searchtype")
      : "contain";
  },
  methods: {
    openBarcodeScanner() {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "open:barcodeScanner",
        })
      );
    },
    async fetchData() {
      try {
        const url = `${process.env.VUE_APP_APIB2B}/v1/navigation/search/last`;
        const { data } = await dpcAxios.connection().get(url);

        if (!data.success || data.search.length == 0) {
          this.fetchedSearchs = [];
        } else {
          this.fetchedSearchs = data.search
            .filter((s) => s.query.trim() !== "")
            .map((s) => ({ termo: s.query }));
        }
      } catch (e) {
        this.fetchedSearchs = [];
        console.error(e);
      }
    },
    onChange(event) {
      localStorage.setItem("searchtype", event.target.value);
    },
    paginaCatalogo(event) {
      // SE TECLA PRECIOSANA FOR ENTER, BUSCA O TERMO DIGITADO
      if (event.key == "Enter") {
        document.activeElement.blur();
        this.buscaTermoDigitado();
      } else {
        // SE POPOVER ESTIVER DESATIVADO, ATIVA NOVAMENTE O COMPONENTE
        if (!this.exibir) {
          this.exibir = true;
        }
      }
    },
    exibirPopover() {
      if (this.isAuthenticated && this.fetchedSearchs.length > 0) {
        this.pesquisarecentes = this.fetchedSearchs;
        localStorage.setItem("searchtype", JSON.stringify(this.fetchedSearchs));
      } else {
        // GET AS ULTIMAS BUSCAS REALIZADAS
        let searchCookie = lastSearch.getCookie();
        // SALVO NA VARIAVEL DE PESQUISAS RESENTES
        this.pesquisarecentes =
          searchCookie !== null && searchCookie !== undefined
            ? (searchCookie = Array.from(searchCookie))
            : [];

        this.pesquisarecentes.sort((a, b) => b.timestamp - a.timestamp);
      }

      this.exibir = true;
    },
    closeOverlay() {
      this.exibir = false;
    },
    buscaTermoDigitado() {
      if (this.busca.indexOf(">bfpreviewtoken=") > -1) {
        cookie.set("bfpreviewtoken", this.busca.split("=")[1]);
        window.location.href = "/";
        return;
      }
      if (this.busca.indexOf(">bfpreviewimg=") > -1) {
        cookie.set("bfpreviewimg", this.busca.split("=")[1]);
        window.location.href = "/";
        return;
      }
      if (this.busca == ">clearbfpreview") {
        cookie.delete("bfpreviewtoken");
        cookie.delete("bfpreviewimg");
        window.location.href = "/";
        return;
      }

      // REMOVENDO ESPAÇOS EM BRANCO DO FINAL DA BUSCA
      this.busca = this.busca.trim().toLowerCase();

      if (this.busca == "") {
        this.$router.push({
          name: "catalogoRota",
          query: {
            visualizacao: this.$store.getters.modoVisualizacao
              ? this.$store.getters.modoVisualizacao
              : this.$store.dispatch("setModoVisualizacao", "bloco-1"),
            ordenacao: "ordem-0",
          },
        });
      }

      // CASO NÃO HAJA BUSCA, PASSAMOS UMA ARRAY VAZIA PARA O PARÂMETRO busca. ISSO IRÁ REMOVÊ-LO
      let nbusca = [];
      if (this.busca !== "") {
        this.$router.push({
          name: "catalogoRota",
          query: {
            busca: [],
            visualizacao: this.$store.getters.modoVisualizacao
              ? this.$store.getters.modoVisualizacao
              : this.$store.dispatch("setModoVisualizacao", "bloco-1"),
            ordenacao: "ordem-0",
          },
        });
        nbusca = this.busca;

        const buscaCookie = {
          termo: this.busca,
          timestamp: new Date().getTime(),
        };
        // SALVA O TERMO PESQUISADO NO COOKIE
        lastSearch.saveLastSearch(buscaCookie);
        localStorage.setItem("searchtype", this.selected);

        if (!this.fetchedSearchs?.map((e) => e.termo)?.includes(this.busca)) {
          if (this.fetchedSearchs?.length === 30) {
            this.fetchedSearchs?.shift();
          }
          this.fetchedSearchs?.unshift({
            termo: this.busca,
            searched_at: new Date().getTime(),
          });
        } else {
          // const index = this.fetchedSearchs?.indexOf(x => x.query == this.busca);
          const index = this.fetchedSearchs
            ?.map((e) => e.termo)
            ?.indexOf(this.busca);

          this.fetchedSearchs?.splice(index, 1);
          this.fetchedSearchs?.unshift({
            termo: this.busca,
            searched_at: new Date().getTime(),
          });
        }

        // FAZENDO RETEAMENTO
        this.$router.push({
          name: "catalogoRota",
          query: {
            busca: nbusca,
            visualizacao: this.$store.getters.modoVisualizacao
              ? this.$store.getters.modoVisualizacao
              : this.$store.dispatch("setModoVisualizacao", "bloco-1"),
            ordenacao: "ordem-0",
          },
        });

        this.closeOverlay();
      }
    },
  },
};
</script>

<style lang="scss">
.search {
  #content-popover {
    max-width: 1080px;
  }
}
</style>
<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";

#order {
  border: none !important;
  background-color: #fff !important;
  border-radius: 5px 0px 0px 5px !important;
  padding: 0;
}

#middle-top-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  position: relative;
  z-index: 99;

  #content-popover {
  }
}

.select-search-type {
  border-radius: 5px 0px 0px 5px;
  padding: 10px 2px;
  background-color: #e3e9ed;
  color: var(--brand-primary, $brand-primary);
  border-top: none;
  border-bottom: none;
  border-left: none;
  font-size: 13px;
  /* text-transform: uppercase; */
  text-align-last: center;
  font-weight: 600;
}

.search {
  min-height: 30px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 15px;
  border-radius: 6px;

  button {
    height: 38px;
    background-color: var(--brand-accent, $brand-accent);
    border: unset;
    z-index: 99999;
    border-radius: 0px 5px 5px 0px;

    i {
      color: var(--brand-primary, $brand-primary);
      font-size: 15px;
    }
  }
  button:hover {
    background-color: var(--brand-accent, $brand-accent);
  }

  span {
    z-index: 99999;
  }

  input {
    height: 38px;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: transparent;
    z-index: 99999;
    border-radius: 5px;
  }

  input:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px transparent;
    border-color: transparent;
    z-index: 99999;
  }
}

span {
  z-index: 99999;
}

input {
  height: 100%;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-color: transparent;
  z-index: 99999;
}

input:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px transparent;
  border-color: transparent;
  z-index: 99999;
}

@media (max-width: 991px) {
  .input-group-search {
    margin-bottom: 10px;
  }
  #middle-top-menu {
    align-self: flex-end;
    order: 3;
    width: 100%;
  }
}

@media (max-width: 525px) {
  .search {
    // display: none;
    padding: 0 10px;
    display: flex;
    align-items: center;

    #input-search {
      border-radius: 5px 0px 0px 5px;
      height: 38px;
      vertical-align: middle;
      flex: 1 1 auto;
    }
    .input-group-addon {
      display: none;
    }

    .input-group-btn {
      flex: 1 0 57px;

      .btn-search {
        height: 38px;
        padding: 0px 18px;
        background-color: var(--brand-accent, $brand-accent);
      }
    }
  }
}
</style>
